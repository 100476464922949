import axios from "axios";
// export const baseUrl = process.env.BASE_URL;
//export const baseUrl = "http://api.bidster.me/";
export const baseUrl = process.env.REACT_APP_BASE_URL;

export let token = null;

export function getToken(newToken) {
	token = newToken;
}
export let acceptedLang = "en";

export function updateAcceptedLang(updatedAcceptedLang) {
	acceptedLang = updatedAcceptedLang;
}

export function getResource(path, onSuccess, onFail, reqAuth = true) {
	let requestData = {
		method: "get",
		url: baseUrl + path,
		headers: {
			"Accept-Language": acceptedLang,
		},
	};

	if (reqAuth) {
		requestData.headers = {
			...requestData.headers,
			Authorization: "Bearer " + token,
		};
	}

	axios(requestData).then(
		(res) => {
			onSuccess(res.data);
		},
		(fail) => {
			if (fail.response && fail.response.status === 401) {
				window.location.href = "/logout";
			}
			onFail(fail.response);
		}
	);
}

export function postResource(
	path,
	data,
	onSuccess,
	onFail,
	reqAuth = true,
	multiPart = false
) {
	let requestData = {
		method: "post",
		url: baseUrl + path,
		headers: {
			"Accept-Language": acceptedLang,
		},
		data,
	};

	if (token && reqAuth) {
		requestData.headers = {
			...requestData.headers,
			Authorization: "Bearer " + token,
		};
	}

	if (multiPart) {
		requestData.headers = {
			...requestData.headers,
			"content-type": "multipart/form-data",
		};
	}

	axios(requestData).then(
		(res) => {
			onSuccess(res.data);
		},
		(fail) => {
			if (fail.response && fail.response.status === 401) {
				window.location.href = "/logout";
			}
			onFail(fail.response);
		}
	);
}

export function deleteResource(path, onSuccess, onFail, reqAuth = true) {
	let requestData = {
		method: "delete",
		url: baseUrl + path,
		headers: {
			"Accept-Language": acceptedLang,
		},
	};

	if (token && reqAuth) {
		requestData.headers = {
			...requestData.headers,
			Authorization: "Bearer " + token,
		};
	}

	axios(requestData).then(
		(res) => {
			onSuccess(res.data);
		},
		(fail) => {
			if (fail.response && fail.response.status === 401) {
				window.location.href = "/logout";
			}
			onFail(fail.response);
		}
	);
}

export function patchResource(
	path,
	data,
	onSuccess,
	onFail,
	reqAuth = true,
	multipart = false
) {
	let requestData = {
		method: "patch",
		url: baseUrl + path,
		headers: {
			"Accept-Language": acceptedLang,
		},
		data,
	};

	if (token && reqAuth) {
		requestData.headers = {
			...requestData.headers,
			Authorization: "Bearer " + token,
		};
	}

	if (multipart) {
		requestData.headers = {
			...requestData.headers,
			"content-type": "multipart/form-data",
		};
	}

	axios(requestData).then(
		(res) => {
			onSuccess(res.data);
		},
		(fail) => {
			if (fail.response && fail.response.status === 401) {
				window.location.href = "/logout";
			}
			onFail(fail.response);
		}
	);
}
export function putResource(
	path,
	data,
	onSuccess,
	onFail,
	reqAuth = true,
	multipart = false
) {
	let requestData = {
		method: "put",
		url: baseUrl + path,
		headers: {
			"Accept-Language": acceptedLang,
		},
		data,
	};

	if (token && reqAuth) {
		requestData.headers = {
			...requestData.headers,
			Authorization: "Bearer " + token,
		};
	}

	if (multipart) {
		requestData.headers = {
			...requestData.headers,
			"content-type": "multipart/form-data",
		};
	}

	axios(requestData).then(
		(res) => {
			onSuccess(res.data);
		},
		(fail) => {
			if (fail.response && fail.response.status === 401) {
				window.location.href = "/logout";
			}
			onFail(fail.response);
		}
	);
}
