export const en = {
  kickOffProject: 'Kick-off your project',
  hireYourContractor: 'Hire your contractor',
  service: 'Professional Services',
  howItWorks: 'How it works',
  homeService: 'Home Services',
  blog: 'Blog',
  contactUs: 'Contact Us',
  faqs: 'FAQs',
  signIn: 'Sign In',
  signOut: 'Sign Out',
  SignUp: 'Sign Up',
  signUpSignIn: 'Sign In/Up',
  english: 'English',
  arabic: 'العربية',
  profile: 'Profile',
  searchFor: 'Search for',
  settings: 'Settings',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  password: 'Password',
  phone: 'Phone',
  // singin
  newUser: 'New user ?',
  createAnAccount: 'Create an account',
  alreadyAUser: 'Already have an account?',
  firstName_companyName: 'First Name | Company Name',
  // profileSettings
  profileSettings: 'Profile Settings',
  changePassword: 'Change Password',
  saveChanges: 'Save Changes',
  firstNameCompany: 'First Name / Company Name',
  phoneNumber: 'Phone Number',
  allowNotifications: 'Allow Notifications',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  confirmNewPassword: 'Confirm new password',
  resetPassword: 'Reset Password',
  // validations
  provideFirstName: 'Please, provide first name!',
  provideLastName: 'Please, provide last name!',
  provideValidName: 'Please, provide valid name!',
  provideYourEmail: 'Please, provide your email!',
  provideValidEmail: 'Please, provide valid email!',
  providePhoneNumber: 'Please provide your phone!',
  provideYourPassword: 'Please, provide your password!',
  forgetPassword: 'Forget Password?',
  keepMeSignIn: 'Keep me signed in',
  provideOldPassword: 'Please, provide your old password',
  provideNewPassword: 'Please, provide your new password',
  provideAtLeast6chars: 'Please, provide at least 6 characters!',
  passwordDoesNotMatch: 'Not matched',
  pleaseConfirmPassword: 'Please, confirm your password!',
  bothPasswordsMustMatch: 'Both passwords must match',
  pleaseProvideFirstNameCompanyName: 'Please provide First Name | Company Name',
  pleaseProvideFirstName: 'Please, Provide First Name',
  pleaseProvideValidName: 'Please provide valid name!',
  pleaseProvideAStrongPassword: 'Please provide a strong password!',
  pleaseProvideAtLeast6Characters: 'Please provide at least 6 characters!',
  pleaseProvideYourPhoneNumber: 'Please provide your phone number!',
  iAgreeTo: 'I Agree To',
  projectName: 'Project Name',
  other: 'Other',
  pleaseSpecify: 'Please Specify',
  exactProjectArea: 'Exact Project Area',
  thisFieldIsRequired: 'this field is required',
  exactAreaMustBe: 'Exact area must be within 50 m2',
  projectAdress: 'Project Address',
  termsAndConditions: 'Terms & conditions',
  privacyPolicy: 'Privacy Policy',
  iAgree: 'I Agree',
  // subnav
  portofolio: 'Portfolio',
  projectRoom: 'Project Room',
  serviceTypePreferances: 'Service Type Preferences',
  projectStats: 'Project Stats',
  ratingAndReviews: 'Rating & Reviews',
  projectsRoom: 'Projects Room',
  createProject: 'Create New Project',
  ratePro: 'Rate Pro',
  projectArea: 'Project Area',
  m: 'm',
  projectLocation: 'Project Location *',
  handoverRequirements: 'Handover Requirements',
  projetStatus: 'Project Status',
  paymentPreferences: 'Payment Preferences',
  uploadDrawings: 'Upload Drawings',
  disclaimer: 'Disclaimer',
  extraComment: 'Extra Comments',
  optionalAddionalConsultationServicesProvidedBy:
    'Optional additional consultation services provided by',
  confirmation: 'Confirmation',
  areYouSureYouWantToSubmit: 'Are you sure you want to submit?',
  ifYouSubmitThisProject: "If you submit this project, you can't edit it.",
  pleaseChooseBetweenResidentialOrCommercial:
    'Please Choose between Residential or Commercial!',
  pleaseSelectHandoverRequirement: 'Please, select the handover requirement!',
  pleaseProvideYourExactArea: 'Please, provide  your exact project area.',
  pleaseProvideYourServiceType: 'Please, provide  your service type.',
  pleaseFillAllTheRequiredFields: 'Please, fill all the required fields!',
  pleaseFillProjectNameField: 'Please, fill project name field!',
  pleaseFillProjectStatus: 'Please, select project status!',
  waitTillProjectDrawingsUploaded: 'Wait, till the project drawings uploaded',
  pleaseUploadProjectDrawings: 'Please, upload project drawings!',
  drawingMustBeLessThan: 'Your drawings must be less than',
  mb: 'MB',
  cancel: 'Cancel',
  uploadedSuccessFully: 'uploaded successfully!',
  maxNumberOfCharactersIs: 'max number of characters are ',
  // serviceTypePreferances
  submit: 'Submit',
  edit: 'Edit',
  pleaseFillAllRequiredFields: 'Please, Fill all required fields!',
  typeYourExtraCommentHere: 'Type your extra comment here...',
  projectCreatedSuccessfully: 'Project Created Successfully!',
  thereAreNoProjects: 'There are no projects',
  newProjects: 'New Projects',
  bids: 'Bids',
  shortlisted: 'Shortlisted',
  quotationSubmitted: 'Quotations',
  thereAreNoPorjects: 'There are no projects',
  projectDeletedSuccessfully: 'Project Deleted Successfully',
  areYouSureYouWantToDeleteProject:
    'Are You sure you want to delete this project?',
  delete: 'Delete',
  serviceType: 'Service Type',
  bid: 'Bid',
  message: 'Message',
  pendingClientShortlisting: 'Pending client’s shortlisting.',
  explainingThatProNeedToDownloadOffer: 'Pro needs to download the offer',
  downloadTemplateBelow:
    'Download Template below and then upload it after filling it to submit and send to client.',
  offerTemplate: 'Quotation template',
  dragFileToUpload: 'Drag File to Upload',
  offerUploadedSuccessfully: 'Offer Uploaded Successfully',
  pendingTilClientHiringDecision: 'Pending client’s hiring decision.',
  hired: 'Hired',
  notHired: 'Not Hired',
  letUsKnowIfYouAre: 'Let us know if you are:',
  buyTokens: 'Buy Tokens',
  areYouSureWantBidInProject: 'Are You sure you want to bid in this project?',
  ifYouStartBidding: 'If you start bidding, It will deduct',
  tokens: 'tokens',
  youDontHaveEnoughTokens: 'You don’t have enough tokens,this project needs',
  doYouWantToBuyTokens: 'Do you want to buy tokens to start bidding?',
  uploadedDrawings: 'Download Drawings',
  uploadedOffer: 'Download Offer',
  shortlisting: 'Shortlisting',
  submittedBrief: 'Submitted Brief',
  profileAndQuotations: 'Profiles & Quotations',
  thereAreNoBiddersYet: 'There Are No Bidders Yet',
  selectPackage: 'Select Package',
  pound: 'EGP',
  selectPaymentMethod: 'Select Payment Method',
  proceedWithPayment: 'Proceed With Payment',
  cridet_depateCard: 'Credit / Debit Card',
  paypal: 'Paypal',
  candidate: 'Candidate',
  shortlistedSuccessfully: 'Shortlisted Successfully',
  youNeedToShortlisted3Candidates:
    'You need to shortlist 3 candidates to be able to submit.',
  shortlistYourTop3Candidates: 'Shortlist your top 3 candidates to receive',
  theirQuotationsForYou: 'Their quotations for your',
  project: 'Project',
  hirePro: 'Hire Pro',
  shortlistedCandidatesProfileAndQuotations:
    'Shortlisted Candidate’s Profiles and Quotations',
  buyToken: 'Buy token',
  downloadOffer: 'Download Offer',
  enterProjectName: 'Enter the project name',
  projectAddedSuccessfully: 'Project added successfully',
  pleaseAddProjectName: 'Please, add project name',
  pleaseSelectDate: 'Please, select project date',
  pleaseUploadYourAlbum: 'Please, upload your album',
  projectUpdatedSuccessfully: 'Project updated successfully',
  selectDate: 'Select date',
  pleaseRateTheProAccordingToTheFollowingCriteria:
    'Please rate the pro according to the following criteria, number 1 being “Very poor” and 5 being “Excellent”.',
  noteThatYouCanOnlyRateOnePro:
    '*Note that you can only submit your rating once.',
  noFinalizeProjectsYet:
    'There are currently no finalized projects pending your rating',
  selectAPerson: 'Select A Person',
  projectsThrough: 'Projects through',
  projectThrough: ' Project through',
  thisProfileHasBeenAwarded: 'This profile has been awarded',
  theresNoRatingForYou: 'This pro has no rating yet',
  youDontHaveRating: "You don't have rating yet",
  by: 'By',
  proIsRatedSuccessfully: 'Pro Is Rated Successfully!',
  jan: 'Jan',
  feb: 'Feb',
  mar: 'Mar',
  apr: 'Apr',
  may: 'May',
  jun: 'Jun',
  jul: 'Jul',
  aug: 'Aug',
  sep: 'Sep',
  oct: 'Oct',
  nov: 'Nov',
  dec: 'Dec',
  matched: 'Matched',
  enteredBid: 'EnteredBids',
  shortListed: 'ShortListed',
  awarded: 'Awarded',
  numberOfProjects: 'Number of projects',
  overAllRating: 'Overall Rating',
  over: 'Over',
  performanceAccordingToRatingAndReviews:
    'Performance according to Rating & Review',
  doYouHaveAnySuggesstionForUs: '9. Do you have any suggesstion for us?',
  yes: 'Yes',
  no: 'No',
  ifYesFeedback: 'If yes, please input your suggestions.',
  saveProject: 'Save Project',
  makeAlbumCover: 'Make Album Cover',
  editProfile: 'Edit Profile',
  createNewProject: 'Create New Project',
  passwordChangedSuccessfully: 'Password Changed Successfully!',
  userDataChangedSuccessfully: 'User Data Changed Successfully!',
  pleaseProvideLastName: 'Please, Provide Last Name',
  pleaseProvideYourName: 'Please, Provide Your Name',
  companyProName: 'Company / Pro name',
  writeYourBio: 'Write Your Bio',
  writeYourWebsite: 'Write Your Website',
  provideValidUrl: 'Please, provide valid url!',
  yourAddress: 'Write Your Address',
  ourTeamIsHappy: 'Our team is happy to answer your questions.',
  fillOutTheForm:
    'Fill out the form and we will be in touch as soon as possible.',
  ourWorkingHours: 'Our working hours are Sunday to Thursday 10am-6pm',
  name: 'Name *',
  inTouchEmail: 'Email *',
  yourMessage: 'Your Message *',
  pleaseProvideYourMessage: 'Please, Provide your message!',
  send: 'Send',
  getInTouch: 'Get In Touch',
  utilitiesAndMore: 'Utilities & more',
  haveAnyThingNeedFix:
    'Have anything that needs fixing, setting up, or moving?',
  keepYourPlace:
    'Keep your place in order and browse through our community of professionals, from handymen to moving companies.',
  yourName: 'Client Name',
  changeImage: 'Change Profile Image',
  date: 'Date',
  editProjectTitle: 'Edit Project Title',
  duplicateProject: 'Duplicate Project',
  pleaseAddProjectToYourPortofolio: 'Please add a project in your portfolio',
  pleaseAddAProjectInYou: 'please add a project in your portfolio',
  albums: 'Albums',
  pendingApproval: 'Pending Approval',
  proDontHaveAnyProjects: "Pro don't have any projects in his portfolio yet.",
  pendingFiles: 'Pending Files',
  pendingFile: 'Pending File',
  confirmPassword: 'Confirm Password',
  signUp: 'Sign Up',
  addNewPassword: 'Add Your New Password',
  deleteProject: 'Delete Project',
  yourDoneWithShortlisting: 'You’re done with shortlisting. ',
  yourChosenCandiatesAreNow:
    'Your chosen candidates are now preparing their quotations.',
  proDontHaveProjectsYet: "Pro Doesn't have previous projects yet",
  contact: 'Contact',
  pleaseFillOutYourProfile: 'Please, fill out your profile',
  uploadYourPortofolio: 'Upload Your Portofolio',
  chooseYourWorkPreferences: 'Choose your work preferences',
  pleaseProvideStrongPassword: 'Please, provide strong password',
  disclaimerImageShould:
    'Image should be clear, drawings should contain dimensions and maximum image size is 5MB',
  rejected: 'Rejected',
  sorryCouldntFindResult: 'No result found',
  youAreSearchingFor: 'You are searching for ...',
  noArticlesYet: 'No articles yet.',
  youAreA: 'You are a',
  pleaseChooseYourAccountType: 'Please, Choose your account type',
  minProjectAreaIs: 'note: minimum project area is 50 m²',
  termsOfUse: 'Terms of use',
  // terms of use
  acceptanceOfTheTermsOfUse: 'Acceptance of the Terms of Use',
  acceptanceOfTheTermsOfUseP1:
    'Please read these terms of use (“Terms”) attentively before continuing to register, use or access bidster.me’s (“Bidster.me”, “We”, “Us”, “Our”) website [Bidster.me] (“Website”) , whether you do so as a Website visitor (“Visitor(s)”) or a registered user (“User(s)”) collectively referred as you (“You” and “They”).',
  acceptanceOfTheTermsOfUseP2:
    'By accessing, using or registering on Our Website, You undertake and accept to abide and comply with these Terms as amended from time to time as well as Our privacy policy which You can find through the following link: Bidster.me',
  acceptanceOfTheTermsOfUseP3:
    'The privacy policy is deemed an integral and complimentary part of these Terms.',
  acceptanceOfTheTermsOfUseP4:
    'These Terms form a legally binding agreement between You and Bidster.me.',
  acceptanceOfTheTermsOfUseP5:
    'If You do not wish to agree to or be bound by these Terms and Our privacy policy, You are immediately advised to exit Our Website.',
  acceptanceOfTheTermsOfUseP6:
    'These Terms do not alter, in any way, any other agreements and other terms You are bound by in Your relationship with Us (i.e.,any terms and conditions applicable in Your respect and Our privacy policy).',
  acceptanceOfTheTermsOfUseP7:
    'In the event of any discrepancy between these Terms and any applicable terms and conditions (ie., pros’ terms and conditions or, Property owners’ terms and conditions) the provisions of the relevant terms and conditions shall prevail on these Terms.',
  changeOfTerms: 'Changes of Terms',
  changeOfTermsP1:
    'Bidster.me reserves the right to revise and modify these Terms from time to time in Our sole discretion. All changes made to these Terms shall be effective immediately upon updating them on Our Website. You are thus expected and undertake the full responsibility to check the Website from time to time and verify the “last modified” date set out on the updated version of the Terms. By continuing to access, use and/or register on the Website, You are deemed to have agreed to be bound by Terms including the changes and updates made to them.',
  eligability: 'Eligibility ',
  eligabilityP1: `Our Website is not targeted towards, nor intended for Users under the age of 21, consequently, the use of Our Website is strictly intended for Users who fulfill the age requirements set herein. 
By accessing, using or registering on Our Website, the User warrants to have the legal capacity necessary for entering into binding agreements with Bidster.me (including these Terms) and that all information provided for the purpose of using the Website is correct and legal in accordance with these Terms and the applicable law. 
The User warrants and agrees that Bidster.me shall not be under any obligation to verify the legal capacity or correctness of information supplied by Users whether such information was obtained by way of accessing or using the Website or accessing the services and that this shall remain the sole responsibility of each User.`,
  modificationsToBidster: 'Modifications to Bidster.me’s Platform',
  modificationsToBidsterP1:
    'User consents that Bidster.me reserves the right to withdraw, amend or discontinue, temporarily or permanently the Website or any of its features or portions thereof at its sole discretion and without need to any prior notice. ',
  modificationsToBidsterP2:
    'Bidster.me shall not be held liable for exercising the rights and actions specified herewith, for any reason, for any period of time. ',
  informationAboutYou: 'Information about You',
  informationAboutYouP1:
    'Bidster me may request specific registration information or other data to enable the Users to access the Website or other offered services by Bidster.me (professional services, installments and project management, excluding utilities and more) You hereby warrant, and shall be fully liable ,  to the Company to provide accurate, correct, current and complete information. ',
  informationAboutYouP2:
    'All information Bidster.me collects, stores, uses and shares are governed by Our privacy policy, and You consent to all actions We take with respect to Your information in compliance with Our privacy policy which can be found trough the following link [Bidster.me].',
  informationAboutYouP3:
    'The User’s use of Our Website or the services constitutes the User’s attestation to having read and accepted the privacy policy as well as constitutes the User’s authorization to the benefit of Bidster.me of using the collected information, processing it in accordance with the privacy policy.',
  intellectualPropertyRights: 'Intellectual property rights',
  intellectualPropertyRightsP1:
    'The Website and its entire contents, features, materials and functionality (including but not limited to all information, software, logo, text, displays, graphics, images, videos, audio and any design thereof, collectively “Website’ Materials”) are exclusively owned and controlled by Bidster.me and are thus of its intellectual property protected by the Egyptian Laws and regulations. ',
  intellectualPropertyRightsP2:
    'No User or third party is entitled to use, copy or process the Website’ Materials in any way without obtaining a prior written consent or authorization from Bidster.me ',
  intellectualPropertyRightsP3:
    'Our Website, its content and Materials are protected by copyrights, trademarks, patents, trade secrets. ',
  intellectualPropertyRightsP4:
    'Bidster.me’s Website’ Materials may not be copied, imitated or used, in whole or in part, without the prior written permission of Bidster.me.',
  intellectualPropertyRightsP5:
    'All rights that are not expressly granted to You are reserved by Bidster.me.',
  intellectualPropertyRightsP6:
    'Subject to these Terms, Users are strictly granted by Bidster.me, a personal, non-exclusive, non-transferable, limited and revocable right to legally use the Platforms for personal use. Any use that contradicts the forgoing justifies Bidster.me’s immediate cease of Your Platforms’ usage and/or, access.',
  warrantiesAndDisclaimer: 'Warranties and disclaimers ',
  warrantiesAndDisclaimerP1:
    'You understand that Bidster.me cannot and does not guarantee that files available for downloading from the internet or the Platforms will be free of viruses or other destructive code. You are thus completely responsible for implementing sufficient procedures and checkpoints to satisfy Your particular requirements for anti-virus protection. Your use of Our Platforms, their content and any service of items obtained through Our Platforms is at Your own risk.',
  limitations: 'Limitation of liability',
  limitationsP1:
    'You hereby acknowledge that Bidster.me is a mere intermediary agent that aims to connect Users to various service providers and that is not involved in actual contact between them or any agreement they may contract with one another. We shall thus not be held liable for any failures or defaults arising out of any party’s performance of their respective obligations. Bidster.me does not control directly or indirectly nor shall be held liable for the quality, timing, legality, failure to provide, omissions, accuracy, suitability or any other aspect for which action can be made',
  limitationsP2:
    'We shall not be held liable or provide any warranty or guarantee as to the accuracy, timeliness, completeness or suitability of the information and materials found or offered on the Website for any particular purpose.',
  limitationsP3:
    'You acknowledge that such information and materials may contain inaccuracies or errors and You hereby agree on discharging Bidster me from any  liability that may be due to any such inaccuracies or errors to the fullest extent permitted by law.',
  limitationsP4:
    'Your use of any information or materials contained on the Website is entirely at Your own risk, for which Bidster me shall not in any way be held liable for. It shall be Your own responsibility to ensure that any information available on the Website meets Your specific requirements.',
  linkFromWebisite: 'Links from the Website',
  linkFromWebisiteP1:
    'You acknowledge that in the event where Our Website contain any links that refer Users to other sites and, or Platforms and resources provided by third parties, whether for the purpose of commercial advertisements or not, Bidster.me does not have any control, whether direct or indirect, over the contents of those sites and , or Platforms  or resources.',
  linkFromWebisiteP2:
    'Further, You hereby agree that Bidster me shall not be held liable for any dispute, difference or claim arising from Your referral and use of said sites and, or Platforms. If You do decide to access any of the third parties’ sites and, or Platforms, You do so entirely at Your own risk and acknowledge that all matters pertaining to Your data collection, use, storage or sharing shall thus be governed by the relevant third party’s privacy policy and respective terms. ',
  termination: 'Termination',
  terminationP1:
    'Notwithstanding any of these Terms, Bidster.me reserves the right, without notice and in its sole discretion, to terminate Your right to use the Website and to block or prevent Your future access to and use of the Website. Bidster.me’s failure or delay in taking such actions does not constitute a waiver of its rights to enforce these Terms.',
  serverablity: 'Severability',
  serverablityP1:
    'If any provision of these Terms has been deemed unlawful, void or for any reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity, enforceability of any other provision of these Terms. ',
  language: 'Language',
  languageP1:
    'The Terms were made in both Arabic and English languages. In case of discrepancy, the Arabic version shall prevail. ',
  governingLawAndJurisdiction: 'Governing law and jurisdiction',
  governingLawAndJurisdictionP1:
    'To the extent permitted under applicable law, all matters relating to the Sites of Bidster.me and these Terms, any dispute, difference and/or claim arising thereof shall firstly be settled informally and amicably with Us. In the event where no amicable settlement has been reached within a minimum period of thirty (30) days, any dispute, difference and/or claim arising thereof shall be settled by arbitration in accordance with the arbitration rules of the Cairo Regional Center for International Commercial Arbitration (“CRCICA”). The place of arbitration shall be in the Arab Republic of Egypt. The language of arbitration shall be English and the arbitration shall be conducted by a sole arbitrator.',
  governingLawAndJurisdictionP2:
    'This Agreement and any other agreements You may have with Bidster.me shall be subject to the laws of the Arab Republic of Egypt, in particular the data protection law no.151 of 2020.',
  UtilitiesAndMoreWebsiteDisclaimer:
    'Bidster.me’s Utilities and more Website Disclaimer.',
  UtilitiesAndMoreWebsiteDisclaimerP1:
    'This utilities and more (“Utilities and More ”) page is one by virtue of which Bidster.me acts    within    its    capacity    as    a    mere intermediary  facilitator  between  registered Bidster.me   users   (“Users”)   and/or,   non-registered    website    visitors    (“Visitors”) collectively  referred  to  as  (“Utilities  and More  Users,  You”)  and  on  the  other  hand the   utilities   services   providers   (“Service Providers”).',
  UtilitiesAndMoreWebsiteDisclaimerP2:
    "The Utilities and More page contains several categories ('Category', 'Categories') such as;   deep   cleaning,   pest   control,   moving companies,    furnishing,    garden    services, home    automation    &    security    systems, electricians, carpenters and plumbers. ",
  UtilitiesAndMoreWebsiteDisclaimerP3:
    'In  each  Category,  Bidster.me  shall  link  the contact details and directory on Our platform (“Platform”)   for   each   Service   Provider, which allows the Utilities and More Users to directly and freely decide between competent Service Providers and contact one (1) or more in the aim of inquiring about any utilities  You  wish  to  obtain  and/or,  hire Service Provider(s). ',
  UtilitiesAndMoreWebsiteDisclaimerP4:
    'Kindly  be  advised  that  Bidster.me  provides the  Service  Provider’s  contact  details,  as lastly  updated  and  notified  to  Us  by  the relevant   Service   Provider.   Consequently, Bidster.me  shall not be held liable, whether directly  or  indirectly,  for  the  correctness, accuracy  or  completeness  of  the  Service Provider’s contact details or any matter that relates  to  a  change  in  a  Service  Provider’s contact details that was not notified to Us.',
  UtilitiesAndMoreWebsiteDisclaimerP5:
    'In   that   regard,   Kindly   be   advised   that Bidster.me  shall  not  be  involved  in  any contact, meetings, agreements or any similar act concluded between You and the Service Providers.  In  particular,  Bidster.me  is  not responsible of and does not control, whether directly  or  indirectly,  the  due  amount  and payment  method  You  agree  upon  with  the relevant Service Provider.',
  UtilitiesAndMoreWebsiteDisclaimerP6:
    'Consequently,   Bidster.me   shall   not   be   a party  of  your  relationship  with  the  Service Providers or be held liable for any failures or defaults arising out of Your communications,     agreements     with     the Service Providers. Further, by proceeding to access  and/or,  use  our  Utilities  and  More page,    You    hereby    declare    to    release Bidster.me  from  any  liability  in  connection with  any  utilities’  quality,  timing,  legality, failure   to   provide   omissions,   accuracy, suitability  or  any  other  aspect  for  which action can be made.',
  UtilitiesAndMoreWebsiteDisclaimerP7:
    'Please  be  advised  that  Your  request  and/or, usage of these Utilities cannot be combined with  Bidster.me’s  other  provided  services (Professional Services, Project Management, Installments).  Such  request  and/or,  usage should  thus  be  requested  and  agreed  upon separately  and  governed  independently  of any  other  services  You  may  request  in  all matters which include but isn’t limited to: the due     fee     for     the     requested     service, Bidster.me’s and Your good-self’s obligations,  any  expected  timeframe  agreed upon  for  the  completion  of  the  relevant service.   Further,   every   service   shall   be governed    by    the    relevant    terms    and conditions that You accept in that regard.',
  saveAsDraft: 'Save As Draft',
  deleteProjectDraft: 'Delete this project',
  shortlistedOverBids: 'Shortlisted / Bids',
  biddingPerformance: 'Bidding performance',
  pleaseRateBidster: 'Please rate Bidster the regarding following:',
  rateYourExperience: 'Rate your experience',
  survey: 'Survey',
  waitingForQuotation: 'Waiting for quotation*',
  proIsPreparingQuotaion: 'Pro is preparing the quotation',
  downloadQuotation: 'Download Quotation',
  areYouSureYouWantToReshortlising:
    'Are  you sure that you do not want to re-shortlist?',
  ifYouClickYesThisProjectWillBeClosed:
    'if you click yes, this project will be closed.',
  yesCloseIt: 'Yes, close it',
  "the3CandidatesDidn'tSubmit":
    "The 3 candidates didn't submit their quotation,",
  wouldYouLikeToReshortlisting:
    'would you like to re-shortlist 3 other candidates ?',
  noThanks: 'No, Thanks',
  paidSuccessfullyAndPointsAdded: 'Paid successfully, and points added',
  errorHappendInThePayment: 'Error happend in the payment',
  thankYou: 'Thank You',
  signUpSuccess:
    "Welcome! A verification email has been sent to your inbox. Please take a look, and don't forget to check your junk/spam folder, just in case.",
}
