import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { filter } from "lodash";
import { Radio, Upload, Spin, Row, Col } from "antd";
import { baseUrl } from "services";
import { downloadZip } from "client-zip";
import { toastError, toastSuccess } from "helpers/toasterFun";
import {
	updateProjectStatus,
	getProjectBasedForStatus,
} from "modules/Profiles/network";
import UploadIcon from "assets/icons/Icon material-file-upload.svg";
import BuyTokenModal from "./components/BuyTokenModal/BuyTokenModal";
import { uploadImages } from "./../../../../../../network";
import { getProjectDependOnStatusById } from "modules/Profiles/network";
import CloseIcon from "assets/icons/x.svg";
import ModalComp from "modules/Shared/Modal/ModalComp";
import calendarIcon from "assets/images/calendarLarge.svg";
import coinsIcon from "assets/images/coinsIcon.svg";
import { chageNumberOfNewProjects, changeTokens } from "store/authorization";
import "./ViewProjectsRoomCard.scss";

const ViewProjectsRoomCard = ({ changeCoins }) => {
	const [projectRemainingData, setProjectRemainingData] = useState(null);
	const [projectToView, setProjectToView] = useState(null);
	const [bidModalVisibility, setBidModalVisibility] = useState(false);
	const [buyTokenModalVisibility, setBuyTokenModalVisibility] = useState(false);
	const [addTokenModalVisibility, setAddTokenModalVisibility] = useState(false);
	const [disableSubmitQuotation, setDisableSubmitQuotation] = useState(false);
	const [offerPath, setOfferPath] = useState(null);
	const [isUploading, setIsUploading] = useState(false);
	const [isDataLoading, setIsDataLoading] = useState(true);
	const { authorization } = useSelector((state) => state.authorization);
	const { lang } = useSelector((state) => state.lang);
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const navigate = useNavigate();
	const { id } = useParams();

	function closeViewPRojectsRoomCard() {
		navigate("/profile/project-room");
	}

	useEffect(() => {
		setIsDataLoading(true);
		if (id) {
			getProjectBasedForStatus(
				lang,
				[1, 2, 3, 4],
				(success) => {
					const { data } = success;
					let project = data.filter((item) => item?.projectId === id);
					const {
						projectId,
						coins,
						status,
						remainingDays,
						statusName,
						canSubmit,
					} = project[0];
					setProjectRemainingData({
						projectId,
						coins,
						status,
						remainingDays,
						statusName,
						canSubmit,
					});
				},
				(fail) => {
					if (fail.data) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		}

		getProjectDependOnStatusById(
			{
				lang,
				projectId: id,
			},
			(success) => {
				const { data } = success;
				setProjectToView(data);

				setIsDataLoading(false);
				setProjectRemainingData((prev) => ({
					...prev,
					statusName: data.statusName,
				}));
			},
			(fail) => {
				setIsDataLoading(false);
				if (fail.data) {
					toastError(fail.data.error);
				} else {
					toastError(fail.message);
				}
			}
		);
	}, [lang, id]);

	async function handleDownloadProUploadedOffer() {
		async function getAllPaths() {
			const arr = [];
			let offers = [projectToView?.proOffer];
			await Promise.all(
				offers.map(async (imgPath) => {
					let data = await fetch(baseUrl + imgPath);
					arr.push(data);
					return arr;
				})
			);
			return arr;
		}

		const blob = await downloadZip([...(await getAllPaths())]).blob();
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = projectToView.projectName + "-your-offer.zip";
		link.click();
		link.remove();
	}

	async function handleDownloadOffer() {
		async function getAllPaths() {
			const arr = [];
			let offers = [projectToView.offerPath];
			await Promise.all(
				offers.map(async (imgPath) => {
					let data = await fetch(baseUrl + imgPath);
					arr.push(data);
					return arr;
				})
			);
			return arr;
		}

		const blob = await downloadZip([...(await getAllPaths())]).blob();
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = projectToView.projectName + "-offer.zip";
		link.click();
		link.remove();
	}

	async function handleDownloadDrawings() {
		async function getAllPaths() {
			const arr = [];
			let offers = projectToView.drawingsPath;
			await Promise.all(
				offers.map(async (imgPath) => {
					let data = await fetch(baseUrl + imgPath);
					arr.push(data);
					return arr;
				})
			);
			return arr;
		}

		const blob = await downloadZip([...(await getAllPaths())]).blob();
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = projectToView.projectName + "-drawings.zip";
		link.click();
		link.remove();
	}

	function submitShortListed(status) {
		let newStatus = projectRemainingData?.status + 1;
		let payload = {
			status: newStatus,
		};
		if (status === 3 && offerPath !== null) {
			payload.offerPath = offerPath.offerPath;
		}
		updateProjectStatus(
			projectRemainingData.projectId,
			[payload],
			(success) => {
				toastSuccess(success.message);

				navigate("/profile/project-room");
			},
			(fail) => {
				if (fail.data) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	function handleQuotation(e) {
		if (e.target.value == null) {
			setDisableSubmitQuotation(true);
		} else {
			setDisableSubmitQuotation(false);
		}
	}

	function handleBuyTokens() {}

	function handleProjectStatus(isbid) {
		changeCoins((prev) => !prev);
		updateProjectStatus(
			projectRemainingData.projectId,
			[
				{
					proId: projectToView.userId,
					status: projectRemainingData.status + 1,
				},
			],
			(success) => {
				setBidModalVisibility(false);
				navigate("/profile/project-room");
				toastSuccess(success.message);
				if (isbid === "bid") {
					// projectRemainingData
					let remainingCoins =
						+authorization.coins - +projectRemainingData.coins;
					console.log(
						remainingCoins,
						+authorization.coins,
						+projectRemainingData.coins
					);
					dispatch(
						chageNumberOfNewProjects(authorization.numberOfNewProjects - 1)
					);
					dispatch(changeTokens(remainingCoins));
				}
			},
			(fail) => {
				if (fail.data) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	async function customUploadOffer(options) {
		const { file } = options;
		let imgPath = new FormData();
		imgPath.append("imgPath", file);
		setIsUploading(true);
		uploadImages(
			imgPath,
			(success) => {
				setIsUploading(false);
				const { data } = success;
				setOfferPath({
					offerPath: data[0].path,
					fileName: data[0].originalname,
				});
			},
			(fail) => {
				setIsUploading(false);
				if (fail.data) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	function deleteOfferFile() {
		setOfferPath(null);
	}

	return (
		<div className="projects__room main-section with__spinner">
			{isDataLoading ? (
				<div className="spinner__container">
					<Spin className="spinner" />
				</div>
			) : (
				<div className="project__details">
					<div className="d-flex justify-content-between align-items-center project__details--header">
						<h2 className="project__details-name">
							<div>"{projectToView?.projectName}"</div>
							<small> - {projectRemainingData?.statusName} </small>
						</h2>
						<figure
							className="btn p-0"
							role="button"
							onClick={closeViewPRojectsRoomCard}
						>
							<Link to={"/profile/project-room"}>
								<img src={CloseIcon} alt="Close" />
							</Link>
						</figure>
					</div>
					<div className="project__details-details ">
						<div className="project__details-details--header">
							<h4 className="fw-bold my-3 serviceType">
								{t("serviceType")}: {projectToView?.service}
							</h4>
							<div className="coins-tokens d-flex ailgn-items-base py-3">
								<div className="me-3 d-flex align-items-baseline">
									<h4 className="coins-tokens__text me-3">
										{projectRemainingData?.remainingDays}
									</h4>
									<img src={calendarIcon} alt="remaining Date" />
								</div>
								{projectRemainingData?.status === 1 && (
									<div className="d-flex align-items-baseline">
										<h4 className="coins-tokens__text me-3">
											{projectRemainingData?.coins || 0}
										</h4>
										<img src={coinsIcon} alt="coins" />
									</div>
								)}
							</div>
						</div>
						<h4 className="h2 title fw-bold mt-2">
							{projectToView?.serviceType}
						</h4>
						<h5 className="my-3">{projectToView?.serviceTypeItems}</h5>
						<h5 className="my-3">{projectToView?.otherServiceTypeItems}</h5>

						<div className="porperties d-flex flex-column my-3">
							<div className="project w-100 row justify-content-between">
								{projectToView?.projectAddress && (
									<div className="project-area question col-md-4 my-3">
										<h5 className="question">{t("projectAdress")}</h5>
										<h6 className="answer">{projectToView?.projectAddress}</h6>
									</div>
								)}

								<div className="project-area question col-md-4 my-3">
									<h5 className="question">{t("exactProjectArea")}</h5>
									<h6 className="answer">
										{projectToView?.projectArea}
										<span>
											m<sup>2</sup>
										</span>
									</h6>
								</div>
								{filter(
									projectToView?.serviceQuestions,
									(item) => item?.isLocation
								)[0]?.answer && (
									<div className="project-location question col-md-4 my-3">
										<h5 className="question">{t("projectLocation")}</h5>
										<h6 className="answer">
											{
												filter(
													projectToView?.serviceQuestions,
													(item) => item?.isLocation
												)[0]?.answer
											}
										</h6>
									</div>
								)}
							</div>

							{projectToView?.serviceQuestions?.map((item) => {
								if (
									!item?.isLocation &&
									!item?.isPayment &&
									(item?.answer || item?.theOtherAnswer)
								) {
									return (
										<div className="question my-3">
											<h5 className="question">{item?.question}</h5>
											<ul className="row requirements p-0">
												<li className="col-md-3 my-2">
													<div className="answer">{item?.answer}</div>
												</li>
												<h6 className="answer">{item?.theOtherAnswer}</h6>
											</ul>
										</div>
									);
								} else {
									return null;
								}
							})}

							<div className="question my-3">
								<h5 className="question">{t("handoverRequirements")}</h5>
								<Row gutter={[8, 8]}>
									{projectToView?.handoverrequirements.map((item, _idx) => (
										<Col xs={24} md={8} key={_idx}>
											{item}
										</Col>
									))}
									{projectToView?.otherHandoverRequirementAnswer && (
										<Col xs={24} md={8}>
											{projectToView?.otherHandoverRequirementAnswer}
										</Col>
									)}
								</Row>
							</div>

							<div className="project-status my-3">
								<ul className="row justify-content-between p-0">
									<li className="question col-md-4 col-lg-6 col-xl-4 my-3">
										<h5 className="status question">{t("projetStatus")}</h5>
										<h6 className="answer">{projectToView?.projectStatus}</h6>
									</li>

									{filter(
										projectToView?.serviceQuestions,
										(item) => item.isPayment
									)[0]?.answer && (
										<li className="question col-md-4 col-lg-6 col-xl-4 my-3">
											<h5 className="question">{t("paymentPreferences")}</h5>
											<h6 className="answer">
												{
													filter(
														projectToView?.serviceQuestions,
														(item) => item.isPayment
													)[0]?.answer
												}
											</h6>
										</li>
									)}
									<li className="col-md-4 col-lg-6 col-xl-4 my-3">
										{projectRemainingData?.status <= 3 &&
										projectToView?.drawingsPath?.length ? (
											<button
												className="btn py-2 button"
												onClick={handleDownloadDrawings}
											>
												{t("uploadedDrawings")}
												<i className="fa-solid fa-download download-icon"></i>
											</button>
										) : null}

										{projectRemainingData?.status === 4 &&
										projectToView?.offerPath ? (
											<button
												className="btn py-2 button"
												onClick={handleDownloadProUploadedOffer}
											>
												{t("uploadedOffer")}
												<i className="fa-solid fa-download download-icon"></i>
											</button>
										) : null}
									</li>
								</ul>
							</div>
							{projectToView?.comment && (
								<div
									className={`question project-comments my-3 ${
										projectRemainingData?.status === 3 ? "comment-border" : null
									}`}
								>
									<h5
										className="question"
										style={{
											fontWeight: 700,
										}}
									>
										{t("extraComment")}
									</h5>
									<p className="answer comment">{projectToView.comment}</p>
								</div>
							)}

							{projectRemainingData?.status === 1 && (
								<div className="d-flex justify-content-center align-items-center pb-2">
									<button
										className="btn py-2 px-5 bid-button"
										onClick={() => {
											if (authorization.coins >= projectRemainingData?.coins) {
												setBidModalVisibility(true);
											} else {
												setAddTokenModalVisibility(true);
											}
										}}
										disabled={!projectRemainingData.canSubmit}
									>
										{t("bid")}
									</button>
								</div>
							)}

							{projectRemainingData?.status === 2 && (
								<div className="projectRoom__messages-card">
									{/* <header>
										<h4>{t("message")}:</h4>
									</header> */}
									<ul className="projectRoom__messages-ul">
										<li>{t("pendingClientShortlisting")}</li>
									</ul>
								</div>
							)}

							{projectRemainingData?.status === 3 && (
								<>
									<div className="message">
										{/* <p>
											{t("explainingThatProNeedToDownloadOffer")}
										</p> */}
										<p>{t("downloadTemplateBelow")}</p>
									</div>

									<button
										className="btn py-2 new-project-btn offer-btn my-2"
										onClick={handleDownloadOffer}
									>
										{t("offerTemplate")}
										<i className="fa-solid fa-download download-icon"></i>
									</button>
									<Upload
										customRequest={customUploadOffer}
										showUploadList={false}
										className="upload-area-container"
									>
										<div className="upload-area with__spinner">
											{isUploading && (
												<div className="spinner__container">
													<Spin className="spinner" />
												</div>
											)}
											<img src={UploadIcon} alt="" />
											<h2>{t("dragFileToUpload")}</h2>
										</div>
									</Upload>
									<div className="my-2">
										{offerPath && (
											<div className="show-uploaded-files d-flex align-items-center my-2  with__spinner">
												<h6 className="uploaded-file me-2 main-color">
													{offerPath.fileName}
												</h6>
												<img
													src={CloseIcon}
													alt="Close"
													role="button"
													onClick={deleteOfferFile}
												/>
											</div>
										)}
										<h6 className={offerPath ? "d-none" : "opacity-0"}>
											{t("offerUploadedSuccessfully")}
										</h6>
									</div>
									<div className="d-flex justify-content-center align-items-center pb-2">
										<button
											className="btn py-2 px-5 bid-button"
											onClick={() =>
												submitShortListed(projectRemainingData?.status)
											}
											disabled={!offerPath && !projectRemainingData.canSubmit}
										>
											{t("submit")}
										</button>
									</div>
								</>
							)}

							{projectRemainingData?.status === 4 && (
								<div className="projectRoom__messages-card">
									<header>
										{/* <h4>{t("message")}:</h4> */}
										<p>{t("pendingTilClientHiringDecision")}</p>
									</header>
									<p className="py-1">{t("letUsKnowIfYouAre")}</p>
									<Radio.Group onChange={handleQuotation} defaultValue={5}>
										<Radio value={5} className="projectRoom__messages-radio">
											{t("hired")}
										</Radio>
										<Radio value={null} className="projectRoom__messages-radio">
											{t("notHired")}
										</Radio>
									</Radio.Group>
									<div className="d-flex justify-content-center align-items-center pt-2">
										<button
											className="btn py-2 px-5 bid-button"
											onClick={() => submitShortListed(4)}
											disabled={
												disableSubmitQuotation &&
												!projectRemainingData.canSubmit
											}
										>
											{t("submit")}
										</button>
									</div>
								</div>
							)}

							{/* Modals For Project Room */}

							<>
								<ModalComp
									visible={bidModalVisibility}
									onCancel={() => setBidModalVisibility(false)}
									onConfirm={() => handleProjectStatus("bid")}
									title={t("bid")}
									desc={t("areYouSureWantBidInProject")}
									confirmation={`${t("ifYouStartBidding")} ${
										projectRemainingData?.coins
									} ${t("tokens")}`}
									confirmBtn={t("bid")}
									cancelBtn={t("cancel")}
								/>
								<ModalComp
									visible={addTokenModalVisibility}
									onCancel={() => setAddTokenModalVisibility(false)}
									onConfirm={() => setBuyTokenModalVisibility(true)}
									title={t("bid")}
									desc={`${t("youDontHaveEnoughTokens")} ${
										projectRemainingData?.coins
									} ${t("tokens")}`}
									confirmation={t("doYouWantToBuyTokens")}
									confirmBtn={t("buyToken")}
									cancelBtn={t("cancel")}
								/>
								<BuyTokenModal
									visible={buyTokenModalVisibility}
									onCancel={() => setBuyTokenModalVisibility(false)}
									onConfirm={handleBuyTokens}
								/>
							</>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ViewProjectsRoomCard;
