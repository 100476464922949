import { useState, useEffect } from "react";
import millify from "millify";
import { NavHashLink } from "react-router-hash-link";
import { logoutUser } from "./network";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import {
	numberOfProjectsPerStatus,
	editUserProfileData,
} from "modules/Profiles/network";
import { logout } from "store/authorization";
import { Dropdown, Menu } from "antd";
import Logo from "assets/images/logo.svg";
import SearchIcon from "assets/icons/search-icon.svg";
import localization from "assets/icons/localization.svg";
import avatar from "assets/images/Group 2354.svg";
import ProfileSettingsModal from "./ProfileSettingsModal/ProfileSettingsModal";
import { baseUrl } from "services";
import i18n from "../../../assets/localization/i18n";
import { changeLang } from "store/lang";
import { toastError } from "helpers/toasterFun";
import { navigators } from "modules/Home/home-navigator";
import { chageNumberOfNewProjects } from "store/authorization";
import SearchModal from "./components/SearchModal/SearchModal";
import "./Navbar.scss";

function NavbarComponent() {
	const [profileMenu, setProfileMenu] = useState(false);
	const [settingsModal, setSettingsModal] = useState(false);
	const [resetPass, setResetPass] = useState(false);
	const [openSearchModal, setOpenSearchModal] = useState(false);
	const { authorization } = useSelector((state) => state.authorization);
	const { t } = useTranslation();
	const { lang } = useSelector((state) => state.lang);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (authorization?.role === "Pro") {
			numberOfProjectsPerStatus(
				lang,
				(success) => {
					let data = success.data?.filter((item) =>
						[1, 2, 3, 4].includes(item.status)
					);
					if (data.length) {
						let count = 0;
						data.forEach((item) => {
							count += item.count;
						});
						dispatch(chageNumberOfNewProjects(millify(count)));
					}
				},
				(fail) => {}
			);
		}
	}, [lang, authorization?.role, dispatch]);

	useEffect(() => {
		if (authorization?.token) {
			logoutUser(
				(success) => {
					if (!success?.data?.isActive) {
						dispatch(logout());
						navigate("/");
					}
				},
				(fail) => {
					if (fail?.data?.err_msg) {
						dispatch(logout());
						navigate("/");
					} else {
						toastError();
					}
				}
			);
		}
	}, [dispatch, navigate, authorization?.token]);

	function changeLangAction(language) {
		i18n.changeLanguage(language);
		dispatch(changeLang(language));
	}

	const handleModal = () => {
		setSettingsModal(!settingsModal);
		if (resetPass) {
			setResetPass(!resetPass);
		}
	};

	const handelSignout = () => {
		dispatch(logout());
		navigate("/");
	};

	function changeLangFun(lang) {
		if (authorization?.token) {
			editUserProfileData(
				{ lang },
				(success) => {},
				(fail) => {}
			);
		}

		changeLangAction(lang);
	}

	const languageMenu = (
		<Menu className="localizationDropdown">
			<Menu.Item
				key="en"
				style={{
					textAlign: "cetner",
				}}
				onClick={() => changeLangFun("en")}
				className={i18n.language === "en" && "active"}>
				{t("english")}
			</Menu.Item>
			<Menu.Item
				key="ar"
				style={{
					textAlign: "cetner",
				}}
				className={i18n.language === "ar" && "active"}
				onClick={() => changeLangFun("ar")}>
				{t("arabic")}
			</Menu.Item>
		</Menu>
	);
	const DropdownMenu = (
		<Menu>
			<Menu.Item
				style={{
					textAlign: "center",
				}}
				key="1">
				<Link
					to={`/profile/${
						authorization?.role === "Pro" ? "portofolio" : "projects-room"
					}`}
					className="stretched-link">
					{t("profile")}
				</Link>
			</Menu.Item>
			<Menu.Item
				style={{
					textAlign: "center",
				}}
				key="2"
				onClick={() => setSettingsModal(!settingsModal)}>
				{t("settings")}
			</Menu.Item>
			<Menu.Divider
				style={{ backgroundColor: "#12819c" }}
				className="divider"
			/>
			<Menu.Item
				style={{
					textAlign: "center",
				}}
				key="3"
				onClick={handelSignout}>
				{t("signOut")}
			</Menu.Item>
		</Menu>
	);

	const showAllNavTabs = !(
		window.location.pathname.includes("/profile") ||
		window.location.pathname.includes("/ClientViewProfile")
	);

	if (window.location.pathname.includes("/feedback")) return <></>;

	return (
		<>
			<div
				className={`navbar__container
       ${showAllNavTabs ? "sticky" : ""}`}>
				<Navbar expand="lg">
					<Container fluid className="nav-container">
						<NavHashLink
							style={{ marginInlineEnd: "1rem" }}
							className="navbar-brand nav-logo"
							to="/#main">
							<img src={Logo} alt="Bidster" />
						</NavHashLink>
						<div className="mobile-links">
							{authorization?.token ? (
								<Dropdown
									overlay={DropdownMenu}
									trigger={["click"]}
									placement={lang === "en" ? "bottomRight" : "bottomLeft"}
									overlayClassName="profile-dropdown">
									<div className="profile-img position-relative mx-2">
										<div>
											<figure onClick={() => setProfileMenu(!profileMenu)}>
												<img
													src={
														authorization?.imagePath
															? baseUrl + authorization.imagePath
															: avatar
													}
													onError={(e) => {
														e.target.src = avatar;
														e.target.alt = "Image failed to load";
													}}
													alt="user profile"
													className="user-avatar w-100"
												/>
												{authorization?.numberOfNewProjects ? (
													<div className="navbar-counter">
														{authorization.numberOfNewProjects}
													</div>
												) : null}
											</figure>
										</div>
									</div>
								</Dropdown>
							) : (
								<Link
									to="/signin"
									className="nav-link signin-up__link d-none mobile-signinup">
									{t("signUpSignIn")}
								</Link>
							)}
							{showAllNavTabs && (
								<Navbar.Toggle aria-controls="navbarScroll">
									<div>
										<span className="bar"></span>
										<span className="bar"></span>
										<span className="bar"></span>
									</div>
								</Navbar.Toggle>
							)}
							<Dropdown
								overlay={languageMenu}
								trigger={["click"]}
								placement={lang === "en" ? "bottomRight" : "bottomLeft"}>
								<img
									src={localization}
									alt="localization"
									className="cursor-pointer"
									style={{
										marginInlineStart: ".5rem",
									}}
								/>
							</Dropdown>
						</div>
						<Navbar.Collapse id="navbarScroll" className="links-container">
							<Nav
								className="me-auto my-2 my-lg-0 justify-content-end"
								navbarScroll>
								<div className="form-signinup d-flex align-items-center">
									{showAllNavTabs ? (
										<div className="form">
											<form className="form-inline my-2 my-lg-0 search-form">
												<div className="input-group search-group px-2">
													<div className="input-group-prepend">
														<span
															className="input-group-text px-0"
															id="basic-search">
															<img src={SearchIcon} alt="Search" />
														</span>
													</div>

													<input
														type="search"
														className="form-control search-input"
														value={""}
														placeholder={t("searchFor")}
														onClick={() => setOpenSearchModal(true)}
														aria-label="Search"
														aria-describedby="basic-search"
													/>
												</div>
											</form>
										</div>
									) : null}
									<div className="signin-up d-flex">
										{authorization?.token ? (
											<Dropdown
												overlay={DropdownMenu}
												trigger={["click"]}
												overlayStyle={{
													width: 150,
													textAlign: "center",
												}}
												placement={lang === "en" ? "bottomRight" : "bottomLeft"}
												overlayClassName="profile-dropdown">
												<div className="profile-img position-relative mx-4">
													<div>
														<figure
															onClick={() => setProfileMenu(!profileMenu)}>
															<img
																src={
																	authorization?.imagePath
																		? baseUrl + authorization.imagePath
																		: avatar
																}
																onError={(e) => {
																	e.target.src = avatar;
																	e.target.alt = "Image failed to load";
																}}
																alt="user profile"
																className="user-avatar w-100"
															/>
															{authorization?.numberOfNewProjects ? (
																<div className="navbar-counter">
																	{authorization?.numberOfNewProjects}
																</div>
															) : null}
														</figure>
													</div>
												</div>
											</Dropdown>
										) : (
											<Link className="nav-link signin-up__link" to="/signin">
												{t("signUpSignIn")}
											</Link>
										)}
										<Dropdown
											overlay={languageMenu}
											trigger={["click"]}
											placement={lang === "en" ? "bottomRight" : "bottomLeft"}>
											<img
												src={localization}
												alt="localization"
												className="cursor-pointer ms-2"
												style={{
													marginInlineStart: "0.5rem",
												}}
											/>
										</Dropdown>
									</div>
								</div>
								{showAllNavTabs ? (
									<ul className="navbar-nav mr-auto links">
										<li className="nav-item">
											<NavHashLink
												className="nav-link links-link"
												smooth
												to={`/#${navigators.ourFeatures}`}>
												{t("service")}
											</NavHashLink>
										</li>
										<li className="nav-item">
											<NavHashLink
												className="nav-link links-link"
												smooth
												to={`/#${navigators.homeSerivce}`}>
												{t("utilitiesAndMore")}
											</NavHashLink>
										</li>
										<li className="nav-item">
											<NavHashLink
												className="nav-link links-link"
												smooth
												to={`/#${navigators.howItWorks}`}>
												{t("howItWorks")}
											</NavHashLink>
										</li>

										<li className="nav-item">
											<Link className="nav-link links-link" to="/blog">
												{t("blog")}
											</Link>
										</li>
										<li className="nav-item">
											<NavHashLink
												className="nav-link links-link"
												smooth
												to={`/#${navigators.contactUs}`}
												activestyle={{
													color: "red",
												}}>
												{t("contactUs")}
											</NavHashLink>
										</li>
										<li className="nav-item">
											<NavHashLink
												className="nav-link links-link"
												activeclassname="nav-link-active"
												smooth
												to={`/#${navigators.faq}`}>
												{t("faqs")}
											</NavHashLink>
										</li>
									</ul>
								) : null}
							</Nav>
						</Navbar.Collapse>
					</Container>
				</Navbar>
			</div>
			{settingsModal && (
				<ProfileSettingsModal
					centered={true}
					modalVisiblity={settingsModal}
					onCancel={handleModal}
				/>
			)}
			{openSearchModal && (
				<SearchModal
					visible={openSearchModal}
					onCancel={() => setOpenSearchModal(false)}
				/>
			)}
		</>
	);
}

export default NavbarComponent;
