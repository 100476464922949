import React, { useState } from "react";
import { getUtlDetails } from "modules/Home/network";
import ProCard from "../ProCard/ProCard";
import { useEffect } from "react";
import { Modal } from "antd";
import "./UtilitiesModal.scss";

function UtilitiesModal({ onCancel, visible, utilId }) {
	const [proList, updateProList] = useState([]);

	const getUtilDetailsFunction = () => {
		let data = { utilId };
		getUtlDetails(
			data,
			(success) => {
				updateProList(success.data);
			},
			(fail) => {}
		);
	};
	useEffect(() => {
		if (utilId) {
			getUtilDetailsFunction();
		}
		// eslint-disable-next-line
	}, [utilId]);

	return (
		<Modal
			className="utility-modal py-3"
			visible={visible}
			footer={null}
			onCancel={onCancel}
			bodyStyle={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
			}}
		>
			<div
				style={{
					marginTop: "1.5rem",
					width: "100%",
					maxHeight: "370px",
					paddingInline: "0.25rem",
					overflowY: "auto",
					overflowX: "hidden",
				}}
			>
				{proList.map((pro, index) => {
					return <ProCard key={index} pro={pro} />;
				})}
			</div>
		</Modal>
	);
}

export default UtilitiesModal;
