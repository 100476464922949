import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import "./feedback.scss";
import { Radio } from "antd";
import Logo from "assets/images/logo.svg";
import { Button, Input } from "antd";
import { values } from "lodash";
import { getAllAccountTypes } from "../Authentication/network";
import {
	getAllQuestions,
	postFeedback,
	postFeedbackSuggesstion,
} from "./network";
import { toastError, toastSuccess } from "helpers/toasterFun";
import { isNumber } from "lodash";

const useCustomSearchParams = () => {
	const [search] = useSearchParams();
	const searchAsObject = Object.fromEntries(new URLSearchParams(search));
	return searchAsObject;
};

const Feedback = () => {
	const [answers, setAnswers] = useState({});
	const [suggesstion, setSuggesstion] = useState(null);
	const [questions, setQuestions] = useState([]);
	const [withSuggest, setWithSuggest] = useState(true);
	const [isDisabled, setIsDisabled] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isRequestLoading, setIsRequestLoading] = useState(false);
	const [account, setAccount] = useState(null);

	const { t } = useTranslation();
	const { lang } = useSelector((state) => state.lang);
	const navigate = useNavigate();

	const {
		email,
		accountTypeId: accountId,
		projectId,
	} = useCustomSearchParams();

	const rates = new Array(5);
	rates.fill(null);

	useEffect(() => {
		setIsDisabled(true);
		setIsRequestLoading(true);

		getAllQuestions(
			lang,
			accountId,
			(success) => {
				setIsDisabled(false);
				setIsRequestLoading(false);
				setQuestions(success.data);
			},
			(fail) => {
				setIsRequestLoading(false);
				setIsDisabled(false);
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);

		getAllAccountTypes(
			lang,
			(success) => {
				const { data } = success;
				let { accountType } = data.filter((item) => {
					if (item._id === accountId) return item;
					return null;
				})[0];
				setAccount(accountType);
			},
			(fail) => {
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}, [accountId, lang]);

	function selectAnswer(questionId, value) {
		setAnswers((prev) => {
			answers[questionId] = {
				questionId,
			};
			if (isNumber(value)) answers[questionId].valueAnswer = value;
			else answers[questionId].textAnswer = value;
			return { ...answers, ...prev };
		});
	}

	function sendFeedback() {
		let data = values(answers);
		setIsLoading(true);

		postFeedback(
			{ listOfQA: data, projectId, email },
			(success) => {
				if (withSuggest) {
					postFeedbackSuggesstion(
						{ email, suggestion: suggesstion, projectId },
						(success) => {
							setIsLoading(false);
							navigate("/");
							toastSuccess(success.message);
						},
						(fail) => {
							setIsLoading(false);
							if (fail?.data?.error) {
								toastError(fail.data.error);
							} else {
								toastError();
							}
						}
					);
				} else {
					setIsLoading(false);
					navigate("/");
					toastSuccess(success.message);
				}
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	return (
		<div className="feedback">
			<div className="container">
				<div className="feedback__logo">
					<img src={Logo} alt="" />
				</div>

				<header className="feedback__header box-shadow">
					{account && (
						<h2>
							{lang === "en" ? (
								<>
									{account} {t("survey")}
								</>
							) : (
								<>
									{t("survey")} {account}
								</>
							)}
						</h2>
					)}
				</header>

				<div className="feedback__container box-shadow">
					<h5 className="h5">{t("rateYourExperience")}</h5>
					<div className="feedback__questions">
						{questions.map((question) => (
							<>
								{question.questionType === 2 ? (
									<div className="question">
										<div className="question__header">
											<span />
											{question.question}
										</div>
										<div className="question__answers">
											<ul className="answer">
												<Radio.Group
													className="answer__wrapper"
													onChange={(e) =>
														selectAnswer(question._id, e.target.value)
													}>
													{question.listOfOptions.map((answer) => (
														<Radio value={answer}>{answer}</Radio>
													))}
												</Radio.Group>
											</ul>
										</div>
									</div>
								) : null}
							</>
						))}

						{/* Start-Static */}
						<div className="question__header">
							<span />
							{t("pleaseRateBidster")}
						</div>
						{/* End-Static */}

						{questions.map((question, _idx) => (
							<>
								{question.questionType === 1 ? (
									<div className="question ms-2">
										<div
											key={_idx}
											className="question__header quesiton-header-sm">
											{_idx + 1} . {question.question}
										</div>
										<div className="question__rating justify-content-between">
											<div className="text start-text">
												{question.lowestAnswer}
											</div>
											<ul className="rates">
												{rates.map((rate, _idx) => {
													let value = _idx + 1;

													return (
														<li
															key={value}
															role={"button"}
															className={`rate ${
																answers[question._id]?.valueAnswer === value
																	? "active"
																	: ""
															}`}
															onClick={() => selectAnswer(question._id, value)}>
															<span>{value}</span>
														</li>
													);
												})}
											</ul>
											<div className="text end-text">
												{question.highestAnswer}
											</div>
										</div>
									</div>
								) : null}
							</>
						))}

						{/* Questions */}
						<div className="question ms-3">
							<div className="question__header quesiton-header-sm">
								{t("doYouHaveAnySuggesstionForUs")}
							</div>
							<div className="question__yes-no">
								<Radio.Group
									value={withSuggest}
									onChange={(e) => setWithSuggest(e.target.value)}>
									<Radio value={true}>{t("yes")}</Radio>
									<Radio value={false}>{t("no")}</Radio>
								</Radio.Group>
							</div>
							<Input.TextArea
								value={suggesstion}
								onChange={(e) => setSuggesstion(e.target.value)}
								placeholder={t("ifYesFeedback")}
								className="question__input"
								disabled={!withSuggest}
							/>
						</div>

						{/* Submit */}
						<div className="feedback__submit">
							<Button
								type="primary"
								disabled={isDisabled}
								loading={isLoading}
								onClick={sendFeedback}>
								{t("submit")}
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Feedback;
