import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { numberOfProjectsPerStatus } from "modules/Profiles/network";
import React from "react";
import NavLink from "./components/NavLink";
import "./Subnav.scss";

const Subnav = () => {
	const { authorization } = useSelector((state) => state.authorization);
	const { lang } = useSelector((state) => state.lang);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	useEffect(() => {
		numberOfProjectsPerStatus(
			lang,
			(success) => {
				const projectsNumber = success.data?.filter(
					(item) => item.status === 1
				);
				if (projectsNumber?.length) {
					let { count } = projectsNumber[0];
					dispatch(count);
				}
			},
			(fail) => {}
		);
	}, [lang, dispatch]);

	return (
		<>
			{authorization.role === "Pro" ? (
				<div className="subnav subnav__pro">
					<ul className="subnav__list pro">
						<li className="subnav__item second-step">
							<NavLink to="/profile/portofolio" label={t("portofolio")} />
						</li>
						<li className="subnav__item">
							<NavLink to="/profile/project-room" label={t("projectRoom")} />
							{authorization.numberOfNewProjects ? (
								<sup
									style={{
										color: "red",
										marginInlineStart: "0.125rem",
									}}
								>
									{authorization.numberOfNewProjects}
								</sup>
							) : null}
						</li>
						<li className="subnav__item third-step">
							<NavLink
								to="/profile/service-type-preference"
								label={t("serviceTypePreferances")}
							/>
						</li>
						<li className="subnav__item">
							<NavLink to="/profile/project-stats" label={t("projectStats")} />
						</li>
						<li className="subnav__item">
							<NavLink
								to="/profile/rating-reviews"
								label={t("ratingAndReviews")}
							/>
						</li>
					</ul>
				</div>
			) : (
				<div className="subnav subnav__client">
					<ul className="subnav__list client">
						<li className="subnav__item client">
							<NavLink to="/profile/projects-room" label={t("projectsRoom")} />
						</li>
						<li className="subnav__item client">
							<NavLink
								to="/profile/create-project"
								label={t("createProject")}
							/>
						</li>
						<li className="subnav__item client">
							<NavLink to="/profile/rate-pro" label={t("ratePro")} />
						</li>
					</ul>
				</div>
			)}
		</>
	);
};

export default Subnav;
