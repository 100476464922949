import React from "react";
import { useTranslation } from "react-i18next";
import { Card } from "react-bootstrap";
import { Switch, Radio } from "antd";
import { baseUrl } from "services";

const ProjectImageCard = ({
	provided,
	snapshot,
	item,
	setProjectImgs,
	selectedAsCover,
	// setSelectedAsCover,
	setDeletedImgs,
	changeSelectedAsCover,
}) => {
	const { t } = useTranslation();

	function updateImageDisability(path) {
		setProjectImgs((prevState) => {
			return prevState.map((imgData) => {
				if (imgData.path === path) {
					imgData.isDisabled = !imgData.isDisabled;
				}
				return imgData;
			});
		});
	}

	function removeImageFromPortofolio(path) {
		setProjectImgs((prevState) => {
			let selectedImgDetail = prevState.find((item) => item.path === path);
			if (prevState.length === 1) {
				if (selectedImgDetail?.imageId) {
					selectedImgDetail.isDeleted = true;
					selectedImgDetail.isAdded = false;
					setDeletedImgs((prev) => [...prev, selectedImgDetail]);
				}
				return [];
			} else if (selectedImgDetail?.isCovered) {
				return prevState;
			} else {
				if (selectedImgDetail?.imageId) {
					selectedImgDetail.isDeleted = true;
					selectedImgDetail.isAdded = false;
					setDeletedImgs((prev) => [...prev, selectedImgDetail]);
				}
				let filteredProjectImgs;
				filteredProjectImgs = prevState.filter((item) => item.path !== path);
				if (path === selectedAsCover) {
					changeSelectedAsCover(filteredProjectImgs[0]?.path);
				}
				return filteredProjectImgs;
			}
		});
	}

	const getItemStyled = (isDragging, draggableStyle) => ({
		boxShadow: isDragging ? "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" : "",
		...draggableStyle,
	});

	return (
		<Card
			className="cover border-0"
			ref={provided.innerRef}
			{...provided.draggableProps}
			style={getItemStyled(snapshot.isDragging, provided.draggableProps.style)}
		>
			<Card.Body className="cover__title">
				<div className="position-relative">
					<Card.Img variant="top" src={baseUrl + item.path} alt={item.path} />
					<div className="d-flex justify-content-between align-items-center cover__controls">
						<div {...provided.dragHandleProps}>
							<div className="cover__controls-ham"></div>
							<div className="cover__controls-ham"></div>
						</div>
						<div className="d-flex align-items-center">
							<Switch
								size="small"
								defaultChecked
								className="mx-2 switch"
								checked={!item.isDisabled}
								onChange={() => {
									if (selectedAsCover !== item?.path) {
										updateImageDisability(item?.path);
									}
								}}
							/>
							<i
								role={"button"}
								onClick={() => removeImageFromPortofolio(item.path)}
								className="fa-solid fa-xmark fs-5 cover__controls-close"
							></i>
						</div>
					</div>
				</div>
				<Radio
					value={item.path}
					checked={selectedAsCover === item.path}
					onChange={() => {
						changeSelectedAsCover(item.path);
					}}
				>
					{t("makeAlbumCover")}
				</Radio>
			</Card.Body>
		</Card>
	);
};

export default ProjectImageCard;
