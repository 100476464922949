const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <g id="Icon_" data-name="Icon " transform="translate(5 5)">
        <rect
          id="Area_ICON:feather_x_SIZE:MEDIUM_STATE:DEFAULT_STYLE:STYLE2_"
          data-name="Area [ICON:feather/x][SIZE:MEDIUM][STATE:DEFAULT][STYLE:STYLE2]"
          width="30"
          height="30"
          transform="translate(-5 -5)"
          fill="rgba(253,73,198,0.35)"
          opacity="0"
        />
        <g id="Icon" transform="translate(-1 -1)">
          <line
            id="Line"
            x1="10"
            y2="10"
            transform="translate(6 6)"
            fill="none"
            stroke="#12819c"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.667"
          />
          <line
            id="Line-2"
            data-name="Line"
            x2="10"
            y2="10"
            transform="translate(6 6)"
            fill="none"
            stroke="#12819c"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.667"
          />
        </g>
      </g>
    </svg>
  );
};

export default CloseIcon;
