import {
	getResource,
	patchResource,
	postResource,
	putResource,
	deleteResource,
} from "services";

export function getUserProfileData(lang, onSuccess, onFail) {
	const path = `api/users?lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

export function editUserProfileData(data, onSuccess, onFail) {
	const path = `api/users`;
	patchResource(path, data, onSuccess, onFail);
}

export function uploadProfileImage(data, onSuccess, onFail) {
	const path = `api/users/uploadImg`;
	postResource(path, data, onSuccess, onFail);
}

// service type preferances
export function getAllServices(lang, onSuccess, onFail) {
	const path = `api/v1/services?lang=${lang}`;
	getResource(path, onSuccess, onFail);
}
export function getAllServiceTypes(lang, onSuccess, onFail) {
	const path = `api/v1/serviceType/servicesTypes?lang=${lang}`;
	getResource(path, onSuccess, onFail);
}
export function getAllServicesQuestion(serviceID, lang, onSuccess, onFail) {
	const path = `api/v1/servicesQuestions?serviceID=${serviceID}&lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

// portofolio
export function uploadImages(imgPath, onSuccess, onFail) {
	const path = "api/v1/uploadImages";
	postResource(path, imgPath, onSuccess, onFail);
}

export function createNewProject(data, onSuccess, onFail) {
	const path = "api/v1/project";
	postResource(path, data, onSuccess, onFail);
}

export function uploadProjectDrawings(data, onSuccess, onFail) {
	const path = "api/clientProjects/uploadDrawings";
	postResource(path, data, onSuccess, onFail, true, true);
}

export function getAllProProjects(lang, onSuccess, onFail) {
	const path = `api/v1/project?lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

export function upadateProject(data, onSuccess, onFail) {
	const { id, updatedData } = data;
	const path = `api/v1/project/${id}`;
	putResource(path, updatedData, onSuccess, onFail);
}

export function deleteProject(id, onSuccess, onFail) {
	const path = `api/v1/project/${id}`;
	deleteResource(path, onSuccess, onFail);
}

// Rating Pro -> Client
export function getAllClientProjects(lang, onSuccess, onFail) {
	const path = `api/clientProjects/projects?lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

export function projectsWithShortlistedCands(onSuccess, onFail) {
	const path = "api/clientProjects/projectsWithShortlistedCands";
	getResource(path, onSuccess, onFail);
}

export function getRateQuestions(lang, onSuccess, onFail) {
	const path = `api/rateQuestions?lang=${lang}`;
	getResource(path, onSuccess, onFail);
}
export function getLocationsList(onSuccess, onFail) {
	const path = "api/clientProjects/locations";
	getResource(path, onSuccess, onFail);
}

export function createRatePro(data, onSuccess, onFail) {
	const path = `api/rateQuestions`;
	postResource(path, data, onSuccess, onFail);
}
export function clientRatePro(data, onSuccess, onFail) {
	const path = `api/rates`;
	postResource(path, data, onSuccess, onFail);
}

export function getProByProjectId(id, onSuccess, onFail) {
	const path = `api/clientProjects/projectProfs?id=${id}`;
	getResource(path, onSuccess, onFail);
}

// create pro service type preferances
export function createProServiceTypePreferances(data, onSuccess, onFail) {
	const path = "api/v1/newProService";
	postResource(path, data, onSuccess, onFail);
}
export function updateProServiceTypePreferances(
	proServiceId,
	data,
	onSuccess,
	onFail
) {
	const path = `api/v1/proservices/${proServiceId}`;
	putResource(path, data, onSuccess, onFail);
}

export function getAllProService(lang, onSuccess, onFail) {
	const path = `api/v1/proServices?lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

export function getProjectStatus(lang, serviceId, onSuccess, onFail) {
	const path = `api/projectStatus?serviceId=${serviceId}&lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

export function getProjectBasedForStatus(lang, payload, onSuccess, onFail) {
	const path = `api/v1/getProjectBasedOnStatus?lang=${lang}`;
	const data = {
		projectStatus: payload,
	};
	postResource(path, data, onSuccess, onFail);
}

export function getAllRatesForPro(lang, onSuccess, onFail) {
	const path = `api/rates?lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

export function getDifferntRateAverage(lang, onSuccess, onFail) {
	var path = `api/rates/differentRateAverage?lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

export function getDifferntRateAverageByProId(id, lang, onSuccess, onFail) {
	var path = `api/clientScreens/allRates?id=${id}&lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

export function getprofessionalProjectsAllRates(id, lang, onSuccess, onFail) {
	var path = `api/clientScreens/professionalProjectsAllRates?proId=${id}&lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

export function clientProjects(data, onSuccess, onFail) {
	const path = "api/clientProjects/addProject";
	postResource(path, data, onSuccess, onFail);
}

export function resortProject(projectId, newIndex, onSuccess, onFail) {
	const data = {
		newIndex,
	};
	const path = `api/v1/project?projectId=${projectId}`;
	patchResource(path, data, onSuccess, onFail);
}

export function getClientProfileDetails(data, onSuccess, onFail) {
	const { proId } = data;
	const path = `api/users/profileDataForClient?id=${proId}`;
	getResource(path, onSuccess, onFail);
}

export function getProffesionalProjects(data, onSuccess, onFail) {
	const { proId, lang } = data;
	const path = `api/clientScreens?proId=${proId}&lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

export function getProAvRate(data, onSuccess, onFail) {
	const { proId } = data;
	const path = `api/clientScreens/allRates?id=${proId}`;
	getResource(path, onSuccess, onFail);
}

export function getProjectDependOnStatusById(
	{ projectId, lang },
	onSuccess,
	onFail
) {
	const path = `api/v1/getProjectDependOnStatusById/${projectId}?lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

export function deleteNotifyProject(notifyId, onSuccess, onFail) {
	const path = `api/v1/notifyProject/${notifyId}`;
	deleteResource(path, onSuccess, onFail);
}

export function getShortListingData({ projectId, lang }, onSuccess, onFail) {
	const path = `api/clientProjects/projectDetails?lang=${lang}&projectId=${projectId}`;
	getResource(path, onSuccess, onFail);
}

export function getHandoverRequirements(lang, data, onSuccess, onFail) {
	const { serviceId } = data;
	const path = `api/handoverRequirements?serviceId=${serviceId}&lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

export function getProjectCountForProf(lang, onSuccess, onFail) {
	const path = `api/v1/getPorjctsForProf?lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

export function numberOfProjectsPerStatus(lang, onSuccess, onFail) {
	const path = `api/v1/numberOfProjects?lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

export function updateProjectStatus(projectId, data, onSuccess, onFail) {
	const path = `api/v1/updateStatus/${projectId}`;
	putResource(path, data, onSuccess, onFail);
}

export function getProRatesAndPortoflio(data, onSuccess, onFail) {
	const { proId, projectId } = data;
	const path = `api/clientProjects/proRatesAndPortoflio?proId=${proId}&projectId=${projectId}`;
	getResource(path, onSuccess, onFail);
}

export function getRateShortlistedOverBids(lang, onSuccess, onFail) {
	const path = `api/rates/shortlistedOverBids?lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

export function getRateByDate(lang, onSuccess, onFail) {
	const path = `api/v1/proRatesStats?lang=${lang}`;

	getResource(path, onSuccess, onFail);
}

export function getShortListedCandidates(data, onSuccess, onFail) {
	const { projectId } = data;
	const path = `api/clientProjects/projectProfs?id=${projectId}`;
	getResource(path, onSuccess, onFail);
}

export function getProfileAndQuotationCandidates(data, onSuccess, onFail) {
	const { projectId } = data;
	const path = `api/clientProjects/Quotations?id=${projectId}`;
	getResource(path, onSuccess, onFail);
}

export function getOptionalServices(lang, onSuccess, onFail) {
	const path = `api/optionalServices?lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

export function getDownloadedOfferPath(
	{ proId, projectId },
	onSuccess,
	onFail
) {
	const path = `api/clientScreens/fileOffer?proId=${proId}&projectId=${projectId}`;
	getResource(path, onSuccess, onFail);
}

export function getMaxFileSize(lang, serviceId, onSuccess, onFail) {
	const path = `api/drawingsRestrictions?serviceId=${serviceId}&lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

export function getPargraphQuestions(lang, serviceId, onSuccess, onFail) {
	const path = `api/paragraphQuestions?serviceId=${serviceId}&lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

export function changeProjectIndex({ projectId, newIndex }, onSuccess, onFail) {
	const path = `api/v1/project?projectId=${projectId}`;
	const data = { newIndex };
	patchResource(path, data, onSuccess, onFail);
}

export function getDraftProject(onSuccess, onFail) {
	const path = `api/drafts`;
	getResource(path, onSuccess, onFail);
}

export function addProjectToDraft(data, onSuccess, onFail) {
	const path = `api/drafts`;
	postResource(path, data, onSuccess, onFail);
}

export function deleteProjectDraft(onSuccess, onFail) {
	const path = `api/drafts`;
	deleteResource(path, onSuccess, onFail);
}

export function reshortlisting({ answer, projectId }, onSuccess, onFail) {
	const path = `api/v1/reshortlist`;
	let data = { answer, projectId };
	postResource(path, data, onSuccess, onFail);
}
