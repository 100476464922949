import { useState, useEffect } from "react";
import { Modal, Button, Spin } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import CloseIcon from "assets/icons/jsx-icons/CloseIcon";
import Logo from "assets/images/logo.svg";
import { getTermsAndConditions } from "../network";
import "./TermsAndConditions.scss";
import { useSelector } from "react-redux";
import { toastError } from "helpers/toasterFun";
import { useTranslation } from "react-i18next";

const TermaAndConditions = ({
  showTermsModal,
  onCancel,
  setAgreeTermsAndConditions,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const { lang } = useSelector((state) => state.lang);
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    getTermsAndConditions(
      lang,
      (success) => {
        setIsLoading(false);
        setData(success.data.termsAndConditions);
      },
      (fail) => {
        if (fail.data.error) {
          toastError(fail?.data?.error);
        } else {
          toastError();
        }
      }
    );
  }, [lang]);

  function acceptTermsAction() {
    setAgreeTermsAndConditions(true);
    onCancel();
  }

  return (
    <Modal
      visible={showTermsModal}
      onCancel={onCancel}
      centered
      width={620}
      footer={null}
      closeIcon={<CloseIcon />}
    >
      <section className="terms terms__section">
        <header className="auth__head">
          <figure className="auth__logo">
            <img src={Logo} alt="bidster-logo" />
          </figure>
          <h3 className="auth__title">{t("termsAndConditions")}</h3>
        </header>
        <Scrollbars
          className="terms__content with__spinner"
          style={{ height: 250 }}
          renderThumbVertical={(props, style) => (
            <div {...props} className="terms__scrollbar" style={{ ...style }} />
          )}
        >
          {isLoading ? (
            <div className="spinner__container">
              <Spin className="spinner" />
            </div>
          ) : (
            <div
              className="item mx-3"
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              {data}
            </div>
          )}
        </Scrollbars>

        <div className="divider bg-black"></div>
        <div className="primary-bid-btn ">
          <Button
            disabled={isLoading}
            type="primary"
            className="btn"
            onClick={acceptTermsAction}
          >
            {t("iAgree")}
          </Button>
        </div>
      </section>
    </Modal>
  );
};

export default TermaAndConditions;
