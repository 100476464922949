import CalenderIcon from "assets/icons/calender.svg";
import TokensIcon from "assets/icons/tokens.svg";
import DeleteIcon from "assets/icons/delete.svg";
import "./ProjectsRoomCard.scss";

import { useNavigate } from "react-router-dom";

const ProjectsRoomCard = ({ project, handleDeleteProject }) => {
	const navigate = useNavigate();
	return (
		<div className="project__room-filters">
			<div>
				<div className="d-flex align-items-center justify-content-between">
					<h4
						className="mb-0 fw-bold project-title"
						onClick={() => {
							navigate(`/profile/project-room/${project.projectId}`, {
								state: {
									project,
								},
							});
						}}
					>
						{project.projectName}
						{project.status === 1 && (
							<sup className="astrect ms-1 text-danger">*</sup>
						)}
					</h4>
					<figure
						className="delete"
						role={"button"}
						onClick={() => handleDeleteProject(project.notifyId)}
					>
						<img src={DeleteIcon} alt="Delete" />
					</figure>
				</div>
				<small className="fw-normal project__room-status">
					{project.statusName}
				</small>
				<div className="d-flex align-items-center project__info">
					<div className="d-flex align-items-center me-1">
						<span className="me-2 fw-bold date-text">
							{project.remainingDays || 0}
						</span>
						<figure className="me-2">
							<img src={CalenderIcon} alt="Days" />
						</figure>
					</div>
					<div className="d-flex align-items-center">
						<span className="me-2 fw-bold coins-text">{project.coins}</span>
						<figure className="me-2 ">
							<img src={TokensIcon} alt="Tokens" />
						</figure>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProjectsRoomCard;
