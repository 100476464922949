import React from "react";
import { Modal } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import "modules/Authentication/components/TermsAndConditions/TermsAndConditions.scss";
import { useTranslation } from "react-i18next";
import "./TermsToUse.scss";
import { useSelector } from "react-redux";

const GestTermsAndConditionsModal = ({ visible, onCancel }) => {
	const { t } = useTranslation();
	const { lang } = useSelector((state) => state.lang);

	function handleOnCancel() {
		onCancel();
	}

	return (
		<Modal
			visible={visible}
			onCancel={handleOnCancel}
			footer={null}
			centered
			className="guest-popup">
			<section className="touse">
				<header className="touse__header">
					<h3>{t("termsOfUse")}</h3>
				</header>
				<Scrollbars
					className="touse__content with__spinner"
					style={{ height: 250 }}
					dir={"rtl"}
					renderThumbVertical={(props, style) => (
						<div
							{...props}
							className="touse__scrollbar"
							style={{ ...style, padding: "1rem" }}
						/>
					)}>
					{/*  acceptanceOfTheTermsOfUse  */}
					<div className="touse__title">{t("acceptanceOfTheTermsOfUse")}</div>
					<div className="touse__item">{t("acceptanceOfTheTermsOfUseP1")}</div>
					<div className="touse__item">{t("acceptanceOfTheTermsOfUseP2")}</div>
					<div className="touse__item">{t("acceptanceOfTheTermsOfUseP3")}</div>
					<div className="touse__item">{t("acceptanceOfTheTermsOfUseP4")}</div>
					<div className="touse__item">{t("acceptanceOfTheTermsOfUseP5")}</div>
					<div className="touse__item">{t("acceptanceOfTheTermsOfUseP6")}</div>
					<div className="touse__item">{t("acceptanceOfTheTermsOfUseP7")}</div>
					{/*  acceptanceOfTheTermsOfUse  */}
					<div className="touse__title">{t("changeOfTerms")}</div>
					<div className="touse__item">{t("changeOfTermsP1")}</div>
					{/*  acceptanceOfTheTermsOfUse  */}
					<div className="touse__title">{t("eligability")}</div>
					<div className="touse__item">{t("eligabilityP1")}</div>
					<div className="touse__title">{t("modificationsToBidster")}</div>
					<div className="touse__item">{t("modificationsToBidsterP1")}</div>
					<div className="touse__item">{t("modificationsToBidsterP2")}</div>
					<div className="touse__title">{t("informationAboutYou")}</div>
					<div className="touse__item">{t("informationAboutYouP1")}</div>
					<div className="touse__item">{t("informationAboutYouP2")}</div>
					<div className="touse__item">{t("informationAboutYouP3")}</div>
					<div className="touse__title">{t("intellectualPropertyRights")}</div>
					<div className="touse__item">{t("intellectualPropertyRightsP1")}</div>
					<div className="touse__item">{t("intellectualPropertyRightsP2")}</div>
					<div className="touse__item">{t("intellectualPropertyRightsP3")}</div>
					<div className="touse__item">{t("intellectualPropertyRightsP4")}</div>
					<div className="touse__item">{t("intellectualPropertyRightsP5")}</div>
					<div className="touse__item">{t("intellectualPropertyRightsP6")}</div>
					<div className="touse__title">{t("warrantiesAndDisclaimer")}</div>
					<div className="touse__item">{t("warrantiesAndDisclaimerP1")}</div>
					<div className="touse__title">{t("limitations")}</div>
					<div className="touse__item">{t("limitationsP1")}</div>
					<div className="touse__item">{t("limitationsP2")}</div>
					<div className="touse__item">{t("limitationsP3")}</div>
					<div className="touse__item">{t("limitationsP4")}</div>
					<div className="touse__title">{t("linkFromWebisite")}</div>
					<div className="touse__item">{t("linkFromWebisiteP1")}</div>
					<div className="touse__item">{t("linkFromWebisiteP2")}</div>
					<div className="touse__title">{t("termination")}</div>
					<div className="touse__item">{t("terminationP1")}</div>
					<div className="touse__title">{t("serverablity")}</div>
					<div className="touse__item">{t("serverablityP1")}</div>
					<div className="touse__title">{t("language")}</div>
					<div className="touse__item">{t("languageP1")}</div>
					<div className="touse__title">{t("governingLawAndJurisdiction")}</div>
					<div className="touse__item">
						{t("governingLawAndJurisdictionP1")}
					</div>
					<div className="terms__item">
						{t("governingLawAndJurisdictionP2")}
					</div>
					<div className="touse__title">
						{t("UtilitiesAndMoreWebsiteDisclaimer")}
					</div>
					<div className="terms__item my-2">
						{t("UtilitiesAndMoreWebsiteDisclaimerP1")}
					</div>{" "}
					<div className="terms__item my-2">
						{t("UtilitiesAndMoreWebsiteDisclaimerP2")}
					</div>{" "}
					<div className="terms__item my-2">
						{t("UtilitiesAndMoreWebsiteDisclaimerP3")}
					</div>{" "}
					<div className="terms__item my-2">
						{t("UtilitiesAndMoreWebsiteDisclaimerP4")}
					</div>{" "}
					<div className="terms__item my-2">
						{t("UtilitiesAndMoreWebsiteDisclaimerP5")}
					</div>{" "}
					<div className="terms__item my-2">
						{t("UtilitiesAndMoreWebsiteDisclaimerP6")}
					</div>
					<div className="terms__item my-2">
						{t("UtilitiesAndMoreWebsiteDisclaimerP7")}
					</div>
				</Scrollbars>
				{/* <div className="divider bg-black"></div> */}
				{/* <div className="primary-bid-btn ">
					<Button
						disabled={isLoading}
						type="primary"
						className="btn"
						onClick={acceptTermsAction}
					>
						{t("iAgree")}
					</Button>
				</div> */}
			</section>
		</Modal>
	);
};

export default GestTermsAndConditionsModal;
