import { createSlice } from "@reduxjs/toolkit";

export const localSlice = createSlice({
	name: "routes",
	initialState: {
		pathname: null,
		role: null,
	},
	reducers: {
		addRoute: (state, action) => {
			state.pathname = action.payload.pathname;
			state.role = action.payload.role;
		},
		removeRoute: (state, action) => {
			state.pathname = null;
			state.role = null;
		},
	},
});

const { reducer, actions } = localSlice;
export const { addRoute, removeRoute } = actions;
export default reducer;
