import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Row, Col, Spin } from "antd";
import {
	Chart as ChartJS,
	ArcElement,
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { Bar, Doughnut, Line } from "react-chartjs-2";
import {
	getProjectCountForProf,
	getRateByDate,
	getRateShortlistedOverBids,
	getDifferntRateAverage,
} from "modules/Profiles/network";
import { toastError } from "helpers/toasterFun";
import { orderBy } from "lodash";
import "./ProjectStatus.scss";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	ArcElement
);

const ProjectStatus = () => {
	const [numberOfProjectsData, setNumberOfProjectsData] = useState([]);
	const [overallRating, setOverallRating] = useState([]);
	const [overallRatingLabels, setOverallRatingLabels] = useState([]);
	const [shortlistedOverBids, setShortlistedOverBids] = useState(null);
	const [performanceQuestions, setPerformanceQuestions] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const { lang } = useSelector((state) => state.lang);
	const { t } = useTranslation();

	const labels = [
		t("matched"),
		t("enteredBid"),
		t("shortListed"),
		t("awarded"),
	];

	const options = {
		// responsive: true,
		maintainAspectRatio: false,
		scales: {
			yAxes: {
				grid: { display: false },
				title: {
					display: true,
					text: t("numberOfProjects"),
					color: "#12819c",
					font: {
						size: 16,
					},
				},
			},
			xAxes: {
				grid: { display: false },
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			title: {
				display: false,
			},
		},
	};

	const arcOptions = {
		plugins: {
			id: "custom plugin",
			legend: {
				display: false,
			},
			tooltips: {
				enabled: false,
			},
		},
	};

	const lineOption = {
		// responsive: true,
		maintainAspectRatio: false,
		scales: {
			yAxes: {
				min: 0,
				max: 5,
				grid: {
					display: false,
				},
				beginAtZero: false,
				title: {
					display: true,
					text: t("overAllRating"),
					color: "#12819c",
					font: {
						size: 16,
					},
				},
			},
			xAxes: {
				beginAtZero: true,
				grid: {
					display: false,
				},

				title: {
					display: true,
					text: t("date"),
					color: "#12819c",
					font: {
						size: 16,
						color: "#12819c",
					},
				},
			},
		},

		plugins: {
			legend: {
				position: "top",
				display: false,
			},
			title: {
				display: false,
			},
			LinearScale: false,
		},
	};

	const data = {
		labels,
		datasets: [
			{
				label: t("numberOfProjects"),
				data: orderBy(numberOfProjectsData, "status", "asc").map(
					(item) => item.count
				),
				backgroundColor: ["#12819C", "#2693AD", "#609FAF", "#609FAF"],
				barThickness: 35,
			},
		],
	};

	const monthsData = {
		labels: [null, ...overallRatingLabels, null],
		datasets: [
			{
				label: t("overAllRating"),
				data: [null, ...overallRating, null],
				tension: 0.35,
				borderColor: "#5FB4C9",
				pointBorderColor: "#12819C",
				pointBackgroundColor: "#12819C",
			},
		],
	};

	const arcData = {
		labels: ["", ""],
		datasets: [
			{
				label: t("shortlisted") + t("over") + t("bids"),
				data: shortlistedOverBids,
				backgroundColor: ["#12819c", "rgba(250, 250, 250)"],
				weight: 0.5,
				cutout: "90%",
			},
		],
		cutoutPercentage: 90,
		tooltip: { enabled: false },
	};

	useEffect(() => {
		setIsLoading(true);
		getProjectCountForProf(
			lang,
			(success) => {
				setNumberOfProjectsData(success.data);
				setIsLoading(false);
			},
			(fail) => {
				if (fail.data) toastError(fail.data.error);
				else toastError();
			}
		);

		getRateShortlistedOverBids(
			lang,
			(success) => {
				let value = success.data;
				setShortlistedOverBids([value, 100 - value]);
			},
			(fail) => {
				if (fail.data) toastError(fail.data.error);
				else toastError();
			}
		);

		getRateByDate(
			lang,
			(success) => {
				setOverallRating(success.data.map((item) => item.averageRating));
				setOverallRatingLabels(success.data.map((item) => item.createdAt));
			},
			(fail) => {
				if (fail.data) toastError(fail.data.error);
				else toastError();
			}
		);

		getDifferntRateAverage(
			lang,
			(success) => {
				if (success.isSuccess) {
					setPerformanceQuestions(success.data);
				}
			},
			(fail) => {
				if (fail?.data?.error) toastError(fail.data.error);
				else toastError();
			}
		);
	}, [lang]);

	return (
		<section className="project-status main-section with__spinner">
			{isLoading ? (
				<div className="spinner__container">
					<Spin className="spinner" />
				</div>
			) : (
				<Row gutter={[16, 16]}>
					<Col xs={24} md={24}>
						<div
							className="box-shadow mx-1"
							style={{
								minHeight: 320,
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Bar
								style={{
									marginBlock: "0.5rem",
									paddingInline: "0.25rem",
								}}
								options={options}
								data={data}
								dir={lang === "ar" ? "rtl" : "ltr"}
							/>
						</div>
					</Col>
					<Col xs={24} md={12}>
						<div className="box-shadow mx-1  h-100 p-4">
							<div className="chart__bars">
								<header className="chart__bars-header">
									<h4>{t("performanceAccordingToRatingAndReviews")}</h4>
								</header>

								<Row gutter={32} className="bars">
									{performanceQuestions?.map((question) => (
										<Col xs={6} className="bar-details" key={question._id}>
											<div className="bar">
												<span
													style={{
														height: (question?.average * 100) / 5 + "%",
													}}
												></span>
											</div>
											<div className="title">{question?.name}</div>
										</Col>
									))}
								</Row>
							</div>
						</div>
					</Col>
					<Col xs={24} md={12}>
						<div className="box-shadow shortlistedOverBids mx-1 p-4">
							<header className="shortlistedOverBids__header">
								<h6>{t("biddingPerformance")}</h6>
							</header>
							{shortlistedOverBids ? (
								<div className="shortlistedOverBids__text">
									{shortlistedOverBids[0]}%
								</div>
							) : null}
							<Doughnut
								options={arcOptions}
								data={arcData}
								dir={lang === "ar" ? "rtl" : "ltr"}
								lang={lang}
							/>
							<div className="shortlistedOverBids__label">
								<span className="label" />
								<div>{t("shortlistedOverBids")}</div>
							</div>
						</div>
					</Col>
					<Col xs={24} md={24}>
						<div
							className="box-shadow mx-1"
							style={{
								minHeight: 320,
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<Line
								style={{
									marginInline: "0.25rem",
									marginBlock: "0.25rem",
								}}
								options={lineOption}
								data={monthsData}
								dir={lang === "ar" ? "rtl" : "ltr"}
								lang={lang}
							/>
						</div>
					</Col>
				</Row>
			)}
		</section>
	);
};

export default ProjectStatus;
