import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Divider, Spin, Button } from "antd";
import { getAllProService } from "modules/Profiles/network";
import { map } from "lodash";
import { toastError } from "helpers/toasterFun";
import editIcon from "assets/icons/edit-white.svg";
import { useTranslation } from "react-i18next";
import "./ViewServiceTypePreferances.scss";

const ViewServiceTypePreferances = ({
	setHasServiceType,
	setServicesToEdit,
}) => {
	const [data, setData] = useState(null);
	const [isDataLoading, setIsDataLoading] = useState(true);
	const { t } = useTranslation();
	const { lang } = useSelector((state) => state.lang);

	useEffect(() => {
		getAllProService(
			lang,
			(success) => {
				setIsDataLoading(false);
				const [proService] = success.data;
				setData(proService);
				setServicesToEdit({
					_id: proService.proServiceId,
					serviceID: proService.serviceId,
					serviceTypeID: proService.serviceTypeId,
					serviceTypeItemsID: map(proService.serviceTypeItems, "id"),
					serviceTypeOther: proService?.serviceTypeOther,
					serviceQA: proService.serviceQA.map((item) => ({
						questionId: item.questionId || null,
						answerId: map(item.answers, "answerId") || null,
						theOtherAnswer: item.theOtherAnswer || null,
					})),
				});
			},
			(fail) => {
				setIsDataLoading(false);
				if (fail.data) {
					toastError(fail.data.message);
				} else {
					toastError();
				}
			}
		);
	}, [setServicesToEdit, lang]);

	return (
		<section className="viewServiceType__section p-4 main-section with__spinner">
			{isDataLoading ? (
				<div className="spinner__container">
					<Spin className="spinner" />
				</div>
			) : (
				<>
					<Button
						type="primary"
						className="edit-btn d-flex"
						onClick={() => setHasServiceType(false)}
					>
						<span className="me-2">{t("edit")}</span>
						<img src={editIcon} alt="edit-icon" />
					</Button>
					<div className="viewServiceType__specialization">{data?.service}</div>
					<div className="viewServiceType__type">
						<header className="viewServiceType__header">
							<h4>{data?.serviceType}</h4>
						</header>
						<Row>
							{data?.serviceTypeItems.map((item, _idx) => (
								<Col md={6} xs={24} key={_idx}>
									<p className="viewServiceType__type-item">{item.name}</p>
								</Col>
							))}
							<Col md={6} xs={24}>
								<div>{data?.serviceTypeOther}</div>
							</Col>
						</Row>
					</div>

					{data?.serviceQA.length
						? data?.serviceQA.map((serviceQA, _idx) => {
								if (serviceQA.answers?.length || serviceQA.theOtherAnswer)
									return (
										<div className="viewServiceType__item" key={_idx}>
											<header className="viewServiceType__header">
												<h4>{serviceQA.question}</h4>
											</header>
											<div className="viewServiceType__type">
												<Row gutter={[8, 8]}>
													{serviceQA.answers.map((item, _idx) => (
														<Col xs={24} md={6} key={_idx}>
															<p>{item.answer}</p>
														</Col>
													))}
													{serviceQA.theOtherAnswer ? (
														<Col xs={24} md={6}>
															<p>{serviceQA.theOtherAnswer}</p>
														</Col>
													) : null}
												</Row>
											</div>
										</div>
									);
								else return null;
						  })
						: null}
				</>
			)}
		</section>
	);
};

export default ViewServiceTypePreferances;
