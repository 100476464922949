import { getResource } from "services";

export function getBanner(onSuccess, onFail) {
	const path = "api/banners/getBanner";
	getResource(path, onSuccess, onFail);
}
export function getHomeServiceBanner(onSuccess, onFail) {
	const path = "api/homeServiceBanners/getHomeServiceBanner";
	getResource(path, onSuccess, onFail);
}
export function getUtlsAndMore(onSuccess, onFail) {
	const path = "api/utilities/getUtilities";
	getResource(path, onSuccess, onFail);
}

export function getHowItWorks(onSuccess, onFail) {
	const path = "api/homeHowItWorks/gethomeHowItWorks";
	getResource(path, onSuccess, onFail, true, true);
}

export function getQAndA(onSuccess, onFail) {
	const path = "api/FAQs/getFAQ";
	getResource(path, onSuccess, onFail);
}
export function getUtlDetails(data, onSuccess, onFail) {
	const { utilId } = data;
	const path = `api/utilDetails/getUtilDetails?utilId=${utilId}`;
	getResource(path, onSuccess, onFail);
}
