export const navigators = {
	main: "main",
	ourFeatures: "professional-services",
	homeSerivce: "utilities-more",
	howItWorks: "how-it-works",
	inTouch: "in-touch",
	faq: "faq",
	contactUs: "contact-us",
	services: "services",
};
