import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
	Row,
	Col,
	DatePicker,
	Form,
	Input,
	Radio,
	Space,
	Upload,
	Button,
	Spin,
} from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Card } from "react-bootstrap";
import moment from "moment";
import UploadIcon from "assets/icons/upload-project.svg";
import ProjectImageCard from "./components/ProjectImageCard";
import {
	getAllServices,
	uploadImages,
	createNewProject,
	upadateProject,
} from "modules/Profiles/network";
import { find } from "lodash";
import "./NewProject.scss";
import { toastError, toastSuccess } from "helpers/toasterFun";

const NewProject = ({
	projectToEdit,
	setAddProject,
	setProjects,
	setGetAllProjects,
}) => {
	const [serviceTypeData, setServiceTypeData] = useState(null);
	const [projectImgs, setProjectImgs] = useState(
		projectToEdit?.imageDetails || []
	);

	const [serviceType, setServiceType] = useState(
		projectToEdit?.serviceID || null
	);
	const [selectedAsCover, setSelectedAsCover] = useState(null);
	const [projectName, setProjectName] = useState(
		projectToEdit?.projectName || null
	);
	const [projectDate, setProjectDate] = useState(
		projectToEdit?.date ? moment(projectToEdit?.date, "YYYY-MM-DD") : null
	);
	const [deletedImgs, setDeletedImgs] = useState([]);
	const draggableAreaHeightRef = useRef(null);
	const uploadImgCompHeightRef = useRef(null);
	const [draggableSpaceHeight, setDraggableSpaceHeight] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedDisclaimer, setSelectedDisclaimer] = useState(null);
	const [isUploadingFile, setIsUploadingFile] = useState(false);

	const { lang } = useSelector((state) => state.lang);
	const { t } = useTranslation();

	useEffect(() => {
		if (serviceType && serviceTypeData?.length) {
			const { disclaimer } = serviceTypeData?.find(
				(item) => item._id === serviceType
			);
			setSelectedDisclaimer(disclaimer);
		}
	}, [serviceType, serviceTypeData]);

	useEffect(() => {
		if (projectToEdit) {
			projectToEdit?.imageDetails.forEach((img) => {
				img.path = img.imagePath;
			});
			setSelectedAsCover(
				find(projectToEdit?.imageDetails, (img) => img.isCovered)?.path
			);

			setProjectImgs(projectToEdit?.imageDetails);
		}
	}, [projectToEdit]);

	useEffect(() => {
		getAllServices(
			lang,
			(success) => {
				const { data, isSuccess } = success;
				if (isSuccess) {
					setServiceTypeData(data);
					if (!projectToEdit) {
						setServiceType(data[0]._id);
					}
				}
			},
			(fail) => {}
		);
	}, [projectToEdit, lang]);

	// set height of the draggable component
	useEffect(() => {
		if (projectImgs?.length) {
			setDraggableSpaceHeight(
				uploadImgCompHeightRef.current.clientHeight -
					draggableAreaHeightRef.current.clientHeight
			);
		} else {
			setDraggableSpaceHeight(null);
		}
	}, [projectImgs]);

	function changeSelectedCover(selectedCoverId) {
		const selectedImg = projectImgs.find(
			(item) => item.path === selectedCoverId
		);

		if (!selectedImg?.isDisabled) {
			setSelectedAsCover(selectedCoverId);
			projectImgs.map((imgData) => {
				if (imgData.path === selectedCoverId) {
					imgData.isCovered = true;
				} else {
					imgData.isCovered = false;
				}
				return imgData;
			});
		}
	}

	// upload portofolio image
	function uploadPortofolioImage(file) {
		let imgPath = new FormData();
		imgPath.append("imgPath", file);
		let fileSize = file.size / 1024 / 1024;
		if (fileSize > 5) {
			toastError(t("drawingMustBeLessThan") + 5 + t("mb"));
			return;
		}

		setIsUploadingFile(true);
		uploadImages(
			imgPath,
			(success) => {
				const { data } = success;
				setIsUploadingFile(false);
				data.map((item) => {
					item.isDisabled = false;
					item.isCovered = false;
					item.isAdded = true;
					return item;
				});
				// toastSuccess("image uploaded");
				setProjectImgs((prevState) => {
					if (prevState?.length === 0) {
						// add new elements to project=> make first element as cover
						data[0].isCovered = true;
						setSelectedAsCover(data[0].path);
					}
					return [...prevState, ...data];
				});
			},
			(fail) => {
				setIsUploadingFile(false);
			}
		);
	}

	function changeDate(date) {
		if (date) {
			setProjectDate(moment(date, "YYYY-MM-DD"));
		} else {
			setProjectDate(null);
		}
	}

	// create new project
	function saveProjectData() {
		let imageDetails = [];

		projectImgs.map((img, _idx) => {
			let { isCovered, isDisabled, path } = img;
			imageDetails.push({
				order: _idx,
				isCovered,
				isDisabled,
				imagePath: path,
			});
			return null;
		});
		let data = {
			projectName,
			date: projectDate,
			serviceID: serviceType,
			imageDetails,
			isDisabled: false,
		};

		if (!projectName?.length) {
			toastError(t("pleaseAddProjectName"));
			return;
		} else if (!projectDate) {
			toastError(t("pleaseSelectDate"));
			return;
		} else if (!projectImgs.length) {
			toastError(t("pleaseUploadYourAlbum"));
			return;
		}

		setIsLoading(true);
		createNewProject(
			data,
			(success) => {
				setIsLoading(false);
				const { isSuccess } = success;
				if (isSuccess) {
					setGetAllProjects((prev) => !prev);
					setAddProject(false);
					toastSuccess(t("projectAddedSuccessfully"));
				} else {
					toastError(success.error);
				}
			},
			(fail) => {
				setIsLoading(false);
				if (fail.data) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	function updateProject() {
		let images = [];
		projectImgs.map((img, _idx) => {
			let { isCovered, isDisabled, path, isAdded, imageId } = img;
			images.push({
				order: _idx,
				isCovered,
				imagePath: path,
				...(isDisabled !== undefined && { isDisabled }),
				...(isAdded && { isAdded }),
				...(imageId !== undefined && { imageId }),
			});
			return null;
		});

		if (!projectName?.length) {
			toastError(t("pleaseAddProjectName"));
			return;
		} else if (!projectDate) {
			toastError(t("pleaseSelectDate"));
			return;
		} else if (!projectImgs.length) {
			toastError(t("pleaseUploadYourAlbum"));
			return;
		}

		if (projectToEdit) {
			const { _id } = projectToEdit;
			const payload = {
				id: _id,
				updatedData: {
					projectName,
					date: projectDate,
					serviceID: serviceType,
					imageDetails: [...images, ...deletedImgs],
				},
			};

			setIsLoading(true);
			upadateProject(
				payload,
				(success) => {
					setIsLoading(false);
					toastSuccess(t("projectUpdatedSuccessfully"));
					setGetAllProjects((prev) => !prev);
					setAddProject(false);
				},
				(fail) => {
					setIsLoading(false);
					if (fail.data) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		}
	}

	function reorder(list, startIndex, endIndex) {
		let newArr = JSON.parse(JSON.stringify(list));
		const [removed] = newArr.splice(startIndex, 1);
		newArr.splice(endIndex, 0, removed);

		setProjectImgs((prev) => {
			return [...newArr];
		});
	}

	function dragEnd(result) {
		// dropped outside the list
		if (!result.destination) {
			return;
		}

		reorder(projectImgs, result.source.index, result.destination.index);
	}

	function disabledDate(currentDate) {
		return currentDate && currentDate.valueOf() > Date.now();
	}

	return (
		<main className="new-project with__spinner">
			{isUploadingFile && (
				<div
					className="spinner__container"
					style={{
						zIndex: "9999",
					}}
				>
					<Spin className="spinner" />
				</div>
			)}
			<Form>
				<Space direction="vertical" size={12}>
					<Form.Item
						className="form__item project-name"
						name="ProjectName"
						style={{
							width: 400,
						}}
						initialValue={projectName}
						colon={false}
					>
						<Input
							bordered={false}
							value={projectName}
							id="projectName"
							placeholder={t("enterProjectName")}
							className="form-underline"
							onChange={(e) => setProjectName(e.target.value)}
						/>
					</Form.Item>
					<div className="d-flex new-project--date">
						{projectToEdit?.date ? (
							<DatePicker
								placeholder={t("selectDate")}
								disabled
								allowClear
								value={projectDate}
								onChange={changeDate}
								disabledDate={(current) => disabledDate(current)}
								onKeyDown={(e) => {
									e.preventDefault();
								}}
							/>
						) : (
							<DatePicker
								allowClear
								placeholder={t("selectDate")}
								onChange={changeDate}
								disabledDate={(current) => disabledDate(current)}
								onKeyDown={(e) => {
									e.preventDefault();
								}}
							/>
						)}
					</div>
				</Space>
				<h4 className="mt-4 main-color">{t("serviceType")}</h4>
				<ul className="new-project__service--type my-3">
					{serviceTypeData?.length && (
						<Radio.Group
							className="new-project__Radios w-100"
							value={serviceType}
							onChange={(e) => {
								setServiceType(e.target.value);
							}}
						>
							<Row gutter={[16, 16]} className="w-100">
								{serviceTypeData.map((item, index) => (
									<Col xs={24} md={6}>
										<Radio
											key={index}
											value={item._id}
											style={{ fontSize: "1rem" }}
										>
											{item.name}
										</Radio>
									</Col>
								))}
							</Row>
						</Radio.Group>
					)}
				</ul>
				<div className="new-project__covers">
					<p className="my-4 new-project--watermarks">
						{t("disclaimerImageShould")}
					</p>
					<div className="covers-container" ref={draggableAreaHeightRef}>
						<div className="draggable areas">
							{/* Start Draggable && Droppable Context */}
							<DragDropContext onDragEnd={dragEnd}>
								<Droppable droppableId="droppable-container">
									{(provided, _) => (
										<div ref={provided.innerRef} {...provided.droppableProps}>
											<div
												className="projectImages"
												style={{
													height: draggableSpaceHeight
														? draggableSpaceHeight
														: "auto",
												}}
											>
												<Row>
													{projectImgs?.length
														? projectImgs?.map((item, index) => {
																return (
																	<Col xs={24} md={12} key={index}>
																		<div className="item" key={index}>
																			<Draggable
																				draggableId={"item" + index}
																				index={index}
																			>
																				{(provided, snapshot) => (
																					<>
																						<ProjectImageCard
																							provided={provided}
																							snapshot={snapshot}
																							ref={provided.innerRef}
																							{...provided.draggableProps}
																							item={item}
																							setProjectImgs={setProjectImgs}
																							selectedAsCover={selectedAsCover}
																							changeSelectedAsCover={
																								changeSelectedCover
																							}
																							setDeletedImgs={setDeletedImgs}
																						/>
																						{provided.placeholder}
																					</>
																				)}
																			</Draggable>
																		</div>
																	</Col>
																);
														  })
														: null}
												</Row>
											</div>
											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</DragDropContext>
							{/* End Draggable && Droppable Context */}
						</div>
						<div ref={uploadImgCompHeightRef}>
							{/* Start Add Project Upload Image */}
							<Row>
								<div className="col-md-6">
									<div className="add__project">
										<Upload
											multiple
											showUploadList={false}
											action={uploadPortofolioImage}
											accept="image/*"
											customRequest={() => {
												return;
											}}
										>
											<Card className="border-0  position-relative">
												<Card.Img
													variant="top"
													src={require(`assets/images/add-project-bg.png`)}
													alt="Cover"
												/>
												<img
													src={UploadIcon}
													alt="Upload project"
													className="add__project-uplaod"
												/>
											</Card>
										</Upload>
									</div>
								</div>
							</Row>
							{/* Start Add Project Upload Image */}
						</div>
					</div>
				</div>
				<div className="d-flex justify-content-center add__projectbutton">
					{projectToEdit ? (
						<Button
							htmlType="submit"
							onClick={(projectToEdit) => updateProject(projectToEdit._id)}
							loading={isLoading}
							style={{
								height: 50,
								marginTop: "1rem",
							}}
						>
							{t("saveProject")}
						</Button>
					) : (
						<Button
							htmlType="submit"
							className="btn my-4"
							onClick={saveProjectData}
							style={{
								height: 50,
								marginTop: "1rem",
							}}
							loading={isLoading}
						>
							{t("saveProject")}
						</Button>
					)}
				</div>
			</Form>
		</main>
	);
};

export default NewProject;
