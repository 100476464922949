import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Switch, Dropdown, Spin } from "antd";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ShowProProjects from "../ShowProProjects/ShowProProjects";
import NewProject from "modules/Profiles/components/Content/components/InnerContent/components/NewProject/NewProject";
import MetroLines from "assets/icons/metro-lines.svg";
import AddCircle from "assets/icons/add-circle-outline.svg";
import GroupIcon from "assets/icons/group-icon.svg";
import SettingsIcon from "assets/icons/settings-icon.svg";
import CloseIcon from "assets/icons/x.svg";
import {
	createNewProject,
	upadateProject,
	resortProject,
	getAllProProjects,
} from "modules/Profiles/network";
import { toastError, toastSuccess } from "helpers/toasterFun";

import "../../InnerContent.scss";

const Portofolio = () => {
	const [projects, setProjects] = useState([]);
	const [addProject, setAddProject] = useState(false);
	const [settingsModal, setSettingsModal] = useState(false);
	const [settingsMenu, setSettingsMenu] = useState(false);
	const [projectToEdit, setProjectToEdit] = useState(null);
	// eslint-disable-next-line
	const [editMode, setEditMode] = useState(null);
	const [editprojectId, setEditProjectId] = useState(null);
	const [projectNameToEdit, setProjectNameToEdit] = useState(null);
	const [renderAllProjects, setRenderAllProjects] = useState(false);
	const [draggableSpaceHeight, setDraggableSpaceHeight] = useState(null);
	const [isDataLoading, setIsDataLoading] = useState(true);

	const albumRef = useRef();
	const { t } = useTranslation();
	const { lang } = useSelector((state) => state.lang);

	// set height of the draggable component
	useEffect(() => {
		if (projects.length) {
			setDraggableSpaceHeight(projects.length * 32);
		} else {
			setDraggableSpaceHeight(null);
		}
	}, [projects.length]);

	useEffect(() => {
		setIsDataLoading(true);
		getAllProProjects(
			lang,
			(success) => {
				setIsDataLoading(false);
				setProjects(success.data);
			},
			(fail) => {
				setIsDataLoading(false);
				if (fail.data) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}, [renderAllProjects, lang]);

	// submit project title
	function submitProjectTitle(e) {
		e.preventDefault();
		const payload = {
			id: editprojectId,
			updatedData: {
				projectName: projectNameToEdit,
			},
		};

		upadateProject(
			payload,
			(success) => {
				toastSuccess(success.message);
				setEditProjectId(null);
				setProjects((prevProjects) => {
					prevProjects.map((project) => {
						if (project._id === editprojectId) {
							project.projectName = projectNameToEdit;
						}
						return project;
					});

					return [...prevProjects];
				});
			},
			(fail) => {}
		);
	}

	// duplicate project
	function duplicateProject(project) {
		createNewProject(
			{
				...project,
				projectName: project.projectName + " - copy",
			},
			(success) => {
				const { data } = success;
				setProjects((prev) => [...prev, ...data]);
			},
			(fail) => {
				if (fail?.data) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	// add project
	const handleAddProject = (editMode = false, project = null) => {
		setAddProject(!addProject);
		setEditMode(editMode);
		setProjectToEdit(project);
		setSettingsModal(false);
	};

	// resorting portofolio
	function reorder(list, startIndex, endIndex) {
		const [removed] = list.splice(startIndex, 1);
		list.splice(endIndex, 0, removed);

		resortProject(
			removed._id,
			endIndex + 1,
			(success) => {
				if (success.isSuccess) setProjects((prevState) => [...list]);
				else toastError(success.error);
			},
			(fail) => {
				if (fail.data) toastError(fail.data.error);
				else toastError();
			}
		);
	}

	function dragEnd(result) {
		if (!result.destination) {
			return;
		}
		reorder(projects, result.source.index, result.destination.index);
	}

	function handleDisabledProject(projectId, disabled) {
		setProjects((prevState) => {
			prevState.map((project) => {
				if (project._id === projectId) {
					project.isDisabled = !disabled;
				}
				return project;
			});
			return [...prevState];
		});

		const payload = {
			id: projectId,
			updatedData: {
				isDisabled: !disabled,
			},
		};

		upadateProject(
			payload,
			(success) => {
				const { isSuccess } = success;
				if (isSuccess) {
					toastSuccess(success.message);
				}
			},
			(fail) => {
				if (fail.data) {
					toastError();
				} else {
					toastError();
				}
			}
		);
	}

	const menu = (project) => (
		<div
			style={{
				paddingInline: "1rem",
			}}
			className="settings-menu"
		>
			<ul>
				<li
					className="edit-title"
					style={{
						paddingBlock: ".25rem",
					}}
					onClick={() => {
						setEditProjectId(project._id);
						setProjectNameToEdit(project.projectName);
					}}
				>
					{t("editProjectTitle")}
				</li>
				<li
					style={{
						paddingBlock: ".25rem",
					}}
					className="dup-page"
					onClick={() => duplicateProject(project)}
				>
					{t("duplicateProject")}
				</li>
			</ul>
		</div>
	);

	const getItemStyled = (isDragging, draggableStyle) => ({
		boxShadow: isDragging
			? "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"
			: "",
		background: "white",
		borderRadius: "3px",
		...draggableStyle,
	});

	return (
		<section className="main-section content with__spinner">
			{isDataLoading ? (
				<div className="spinner__container">
					<Spin className="spinner" />
				</div>
			) : (
				<>
					<div className="position-relative navigation">
						{!addProject ? (
							<div className="d-flex justify-content-between align-items-center controls">
								<figure>
									<img
										src={MetroLines}
										alt="Album"
										className="btn p-0 rounded-0"
										onClick={() => setSettingsModal(!settingsModal)}
									/>
								</figure>
								<figure>
									<img
										src={AddCircle}
										alt="Add"
										className="btn"
										onClick={handleAddProject}
									/>
								</figure>
							</div>
						) : (
							<div className="d-flex justify-content-end align-items-center controls">
								<div role="button" onClick={handleAddProject}>
									<img src={CloseIcon} alt="" />
								</div>
							</div>
						)}

						{!addProject && settingsModal && (
							<div className="settings-modal">
								{/* Start Album header */}
								<div className="d-flex justify-content-between align-items-center border-bottom settings-modal__header">
									<h4>{t("albums")}</h4>
									<span>
										<i
											className="fa-solid fa-xmark album-close"
											onClick={() => setSettingsModal(!settingsModal)}
										></i>
									</span>
								</div>
								{/* End Album header */}
								{/* Start Portofolio Album Dropdown */}
								{projects?.length ? (
									<div ref={albumRef}>
										<DragDropContext onDragEnd={dragEnd}>
											<Droppable droppableId="droppable-album">
												{(provided, _) => (
													<div
														{...provided.droppableProps}
														ref={provided.innerRef}
														className="album-items"
														style={{
															height: draggableSpaceHeight
																? draggableSpaceHeight
																: "auto",
														}}
													>
														{projects.map((project, i) => (
															<Draggable
																key={project._id}
																draggableId={project._id}
																index={i}
															>
																{(provided, _) => (
																	<div
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		className="d-flex align-items-center"
																		style={getItemStyled(
																			_.isDragging,
																			provided.draggableProps.style
																		)}
																	>
																		<div className="d-flex justify-content-between align-items-center align-self-start py-1 settings-container flex-1">
																			<div
																				id="item"
																				className="d-flex align-items-center justify-content-between flex-1"
																			>
																				<div className="d-flex align-items-center flex-1">
																					<figure
																						style={{
																							marginInlineEnd: "0.5rem",
																						}}
																					>
																						<img
																							src={GroupIcon}
																							alt="Settings"
																							{...provided.dragHandleProps}
																							style={{
																								marginBottom: 0,
																							}}
																						/>
																					</figure>
																					{editprojectId === project._id ? (
																						<form
																							className="edit-project-title"
																							onSubmit={(e) => {
																								e.preventDefault();
																								if (
																									!projectNameToEdit?.length
																								) {
																									toastError(
																										t(
																											"pleaseFillProjectNameField"
																										)
																									);
																									return;
																								} else {
																									submitProjectTitle(e);
																								}
																							}}
																						>
																							<input
																								className="w-100"
																								type="text"
																								value={projectNameToEdit}
																								onChange={(e) =>
																									setProjectNameToEdit(
																										e.target.value
																									)
																								}
																							/>
																							<button
																								type="submit"
																								className="hidden"
																							></button>
																						</form>
																					) : (
																						<h5
																							style={{
																								marginBottom: "0",
																							}}
																						>
																							{project.projectName}
																						</h5>
																					)}
																				</div>
																				<div>
																					<Dropdown
																						overlay={() => menu(project)}
																						placement={"bottomCenter"}
																						overlayStyle={{
																							background: "white",
																						}}
																					>
																						<figure
																							role="button"
																							className="me-2 btn p-0 rounded-0"
																						>
																							<img
																								src={SettingsIcon}
																								alt="Settings"
																								className="mb-0 w-75"
																								onClick={() =>
																									setSettingsMenu(!settingsMenu)
																								}
																							/>
																						</figure>
																					</Dropdown>
																					<Switch
																						size="small"
																						className="switch"
																						checked={!project.isDisabled}
																						onChange={() =>
																							handleDisabledProject(
																								project._id,
																								project.isDisabled
																							)
																						}
																					/>
																				</div>
																			</div>
																		</div>
																		{provided.placeholder}
																	</div>
																)}
															</Draggable>
														))}
													</div>
												)}
											</Droppable>
										</DragDropContext>
									</div>
								) : (
									<div className="d-flex justify-content-center align-items-center py-5 text-empty">
										{t("pleaseAddProjectToYourPortofolio")}
									</div>
								)}
								{/* End Portofolio Album Dropdown */}
							</div>
						)}
					</div>
					{addProject ? (
						<NewProject
							projectToEdit={projectToEdit}
							handleAddProject={handleAddProject}
							setProjects={setProjects}
							setAddProject={setAddProject}
							setGetAllProjects={setRenderAllProjects}
						/>
					) : projects?.length ? (
						<div className="portofolio position-relative">
							<div className="portofolio__project">
								<ShowProProjects
									projects={projects}
									setProjects={setProjects}
									handleAddProject={handleAddProject}
									isRenderAllProjectsSwitcher={setRenderAllProjects}
								/>
							</div>
						</div>
					) : (
						<div
							className="d-flex justify-content-center align-items-center text-empty h-100"
							style={{
								minHeight: "400px",
							}}
						>
							{t("pleaseAddProjectToYourPortofolio")}
						</div>
					)}
				</>
			)}
		</section>
	);
};

export default Portofolio;
