import { postResource } from "services";

export function forgetPassword({ email, accountType }, onSuccess, onFail) {
	const path = `api/users/forgotpassword`;
	const data = {
		email,
		accountType,
	};
	postResource(path, data, onSuccess, onFail);
}

export function resetPassword(
	{ email, accountType, forgetPasswordOTP, OTP, password },
	onSuccess,
	onFail
) {
	const path = `api/users/resetPassword`;
	const data = {
		email,
		accountType,
		forgetPasswordOTP,
		OTP,
		password,
	};
	postResource(path, data, onSuccess, onFail);
}
