import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route, Navigate, useSearchParams } from "react-router-dom";
import SignIn from "modules/Authentication/Signin/Signin";
import SignUp from "modules/Authentication/Signup/Signup";
import Home from "modules/Home/Home";
import NotFound from "modules/Shared/NotFound/NotFound";
import Profile from "modules/Profiles/Profile";
import { Blog } from "modules/Blog/Blog";
import { updateAcceptedLang, getToken } from "services";
import { useLocation } from "react-router-dom";
import ClientViewProfile from "modules/ClientViewProfile/ClientViewProfile";
import ArticleDetails from "modules/Blog/ArticleDetails/ArticleDetails";
import Feedback from "modules/Feedback/Feedback";
import Logout from "modules/Logout/Logout";
import ForgetPassword from "modules/Authentication/Signin/components/ForgetPassword/ForgetPassword";
import ResetPassword from "modules/Authentication/Signin/components/ResetPassword/ResetPassword";
import { addRoute } from "store/routesStore";
import GuestCreateNewProject from "modules/GuestCreateNewProject";
const PageRoutes = () => {
	const { authorization } = useSelector((state) => state.authorization);
	const { lang } = useSelector((state) => state.lang);
	const location = useLocation();
	const token = authorization?.token;
	const dispatch = useDispatch();
	const [searchParams] = useSearchParams();

	getToken(token ? token : null);
	updateAcceptedLang(lang);

	useEffect(() => {
		updateAcceptedLang(lang);
	}, [lang]);

	useEffect(() => {
		const { pathname } = location;
		const role = searchParams.get("role");
		if (location?.pathname && !token && role) {
			dispatch(addRoute({ pathname, role }));
		}
	}, [location, token, dispatch, authorization, searchParams]);

	return (
		<Routes>
			<Route path="/" element={<Home />} />
			<Route
				path="signin"
				element={token ? <Navigate to="/" replace /> : <SignIn />}
			/>
			<Route
				path="signup"
				element={token ? <Navigate to="/" replace /> : <SignUp />}
			/>
			<Route path="blog" element={<Blog />} />
			<Route path="guest-project" element={<GuestCreateNewProject />} />
			<Route path="ClientViewProfile" element={<ClientViewProfile />} />
			<Route path="article-details" element={<ArticleDetails />} />
			<Route
				path="profile/*"
				element={token ? <Profile /> : <Navigate to="/signin" replace />}
			/>
			<Route
				path="reset-password"
				element={token ? <Navigate to="/" replace /> : <ForgetPassword />}
			/>
			<Route
				path="forget-password"
				element={token ? <Navigate to="/" replace /> : <ResetPassword />}
			/>
			<Route path="feedback" element={<Feedback />} />
			<Route path="logout" element={<Logout />} />
			<Route path="*" element={<NotFound />} />
		</Routes>
	);
};

export default PageRoutes;
