import { useState, useEffect } from 'react'
import { Form, Input, Button, Row, Col } from 'antd'
import { contactUs } from './network'
import { toastError, toastSuccess } from 'helpers/toasterFun'
import { useTranslation } from 'react-i18next'
import './InTouch.scss'

const InTouch = ({ id }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [isPlaying, setIsPlaying] = useState(true)
  useEffect(() => {
    return () => {
      setIsPlaying(false)
    }
  }, [])

  function onFinish(values) {
    setIsLoading(true)
    contactUs(
      values,
      (success) => {
        setIsLoading(false)
        toastSuccess(success.message)
        form.resetFields()
      },
      (fail) => {
        setIsLoading(false)
        toastError()
      }
    )
  }

  return (
    isPlaying && (
      <section id={id} className='home__section home__intouch'>
        <div className='container'>
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={10} md={7}>
              <header className='home__header'>
                <h2 className='text-uppercase'>{t('getInTouch')}</h2>
              </header>
              <p className='lead intouch__pgh'>
                {t('ourTeamIsHappy')}
                <br />
                {t('fillOutTheForm')}
                <br />
                {t('email')} : Bidster@bidster.me
                <br />
                {t('phoneNumber')} : 01286905327
                <br />
                {t('ourWorkingHours')}
              </p>
            </Col>
            <Col xs={24} sm={14} md={17}>
              <Form className='intouch__form' onFinish={onFinish} form={form}>
                <Form.Item
                  className='intouch__form--item'
                  name='name'
                  rules={[
                    {
                      required: 'true',
                      message: t('pleaseProvideYourName'),
                    },
                  ]}
                >
                  <Input
                    id='name'
                    placeholder={t('name')}
                    className='intouch__form--input'
                  />
                </Form.Item>
                <Form.Item
                  className='intouch__form--item'
                  name='email'
                  rules={[
                    {
                      required: 'true',
                      message: t('provideYourEmail'),
                    },
                    {
                      type: 'email',
                      message: t('provideValidEmail'),
                    },
                  ]}
                >
                  <Input
                    id='email'
                    placeholder={t('inTouchEmail')}
                    className='intouch__form--input'
                  />
                </Form.Item>
                <Form.Item
                  className='intouch__form--item'
                  name='message'
                  rules={[
                    {
                      required: 'true',
                      message: t('pleaseProvideYourMessage'),
                    },
                  ]}
                >
                  <Input.TextArea
                    name='message'
                    className='intouch__form--input'
                    placeholder={t('yourMessage')}
                    autoSize={{ minRows: 5, maxRows: 7 }}
                  />
                </Form.Item>
                <div className='primary-bid-btn d-flex justify-content-end'>
                  <Form.Item className='signin__submit'>
                    <Button
                      type='primary'
                      htmlType='submit'
                      loading={isLoading}
                    >
                      {t('send')}
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </section>
    )
  )
}

export default InTouch
