import { createSlice } from "@reduxjs/toolkit";
import en from "assets/languages/en.json";
import ar from "assets/languages/ar.json";
export const localSlice = createSlice({
	name: "language",
	initialState: {
		currentLocal: en,
		currentLanguageId: "en",
	},

	reducers: {
		changeLanguage: (state, action) => {
			state.currentLocal = action.payload === "ar" ? en : ar;
			state.currentLanguageId = action.payload;
		},
	},
});

const { reducer, actions } = localSlice;
export const { changeLanguage } = actions;
export default reducer;
