import { Link } from "react-router-dom";
import Logo from "assets/images/logo.svg";
import { downloadZip } from "client-zip";
import { Row, Col } from "antd";
import "./SubmittedBrief.scss";
import { baseUrl } from "services";
import { useTranslation } from "react-i18next";

const SubmittedBrief = ({ submittedBriefData }) => {
  const { t } = useTranslation();

  async function handleDownloadDrawings() {
    async function getAllPaths() {
      const arr = [];
      await Promise.all(
        submittedBriefData.drawingsPath.map(async (imgPath) => {
          let data = await fetch(baseUrl + imgPath);
          arr.push(data);
          return arr;
        })
      );
      return arr;
    }
    const blob = await downloadZip([...(await getAllPaths())]).blob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "drawings.zip";
    link.click();
    link.remove();
  }

  return (
    <div className="submitted">
      <h2>
        {t("serviceType")}: {submittedBriefData.service}
      </h2>
      <h2 className="h1 title mt-4">{submittedBriefData.serviceType}</h2>
      <h5 className="my-3">
        {submittedBriefData.serviceTypeItems.length
          ? submittedBriefData.serviceTypeItems[0]
          : submittedBriefData.otherServiceTypeItems}
      </h5>
      <div className="porperties d-flex flex-column mt-3 mb-2">
        <div className="project w-100 row justify-content-between">
          <div className="project-area col-md-4 mt-3 mb-2">
            <h5 className="title">{t("exactProjectArea")}</h5>
            <h6>
              {submittedBriefData.projectArea}
              <span className="measure">
                m<sup>2</sup>
              </span>
            </h6>
          </div>
          {submittedBriefData.projectAddress && (
            <div className="project-address col-md-4 mt-3 mb-2">
              <h5 className="title">{t("projectAdress")}</h5>
              <h6>{submittedBriefData.projectAddress}</h6>
            </div>
          )}
        </div>

        {submittedBriefData.handoverrequirements?.length ||
        submittedBriefData.otherHandoverRequirementAnswer ? (
          <div className="handover mt-3 mb-2">
            <h5 className="title">{t("handoverRequirements")}</h5>
            <Row gutter={[8, 16]}>
              {submittedBriefData.handoverrequirements?.map((item, i) => (
                <Col xs={24} md={6} key={i}>
                  <h6>{item}</h6>
                </Col>
              ))}
              {submittedBriefData?.otherHandoverRequirementAnswer && (
                <Col>
                  <h6>{submittedBriefData.otherHandoverRequirementAnswer}</h6>
                </Col>
              )}
            </Row>
          </div>
        ) : null}

        {submittedBriefData.serviceQuestions.map((question, _) => {
          if (!question.answer && !question.theOtherAnswer) {
            return null;
          }
          // question
          return (
            <div className="project-location col-md-4 mt-3 mb-2" key={_}>
              <h5 className="title">{question.question}</h5>
              <h6>
                {question.answer ? question.answer : question.theOtherAnswer}
              </h6>
            </div>
          );
        })}

        <div className="project-status mt-3 mb-2">
          <ul className="row justify-content-between p-0">
            <li className="col-md-4 col-lg-6 col-xl-4 mt-3 mb-2">
              <h5 className="status title">{t("projectStats")}</h5>
              <h6>{submittedBriefData.projectStatus}</h6>
            </li>
            {submittedBriefData.drawingsPath.length ? (
              <li className="col-md-4 col-lg-6 col-xl-4 mt-3 mb-2">
                <button
                  className="btn py-2 button"
                  onClick={handleDownloadDrawings}
                >
                  {t("uploadedDrawings")}
                  <i className="fa-solid fa-download download-icon"></i>
                </button>
              </li>
            ) : (
              <></>
            )}
          </ul>
        </div>

        {submittedBriefData.comment ? (
          <div className="project-comments mt-3 mb-2">
            <h5 className="comments-title title">{t("extraComment")}</h5>
            <p>{submittedBriefData.comment}</p>
          </div>
        ) : null}
        {submittedBriefData.optionalServices?.length ? (
          <div className="optional mt-3 mb-2">
            <h2 className="title d-flex align-items-center py-2">
              <span className="title-desc">
                <span className="mx-2">
                  {t("optionalAddionalConsultationServicesProvidedBy")}
                </span>
                <Link to="/">
                  <img src={Logo} alt="Bidster" />
                </Link>
              </span>
            </h2>
            <ul className="p-0">
              {submittedBriefData.optionalServices.map((item, i) => (
                <li key={i}>
                  <h6>{item}</h6>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default SubmittedBrief;
