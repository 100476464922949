import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { baseUrl } from "services";
import { toastError, toastSuccess } from "helpers/toasterFun";
import {
	getProfileAndQuotationCandidates,
	updateProjectStatus,
	reshortlisting,
} from "modules/Profiles/network";
import avatar from "assets/images/Group 2354.svg";
import SharedRating from "modules/Shared/SharedRating/SharedRating";
import "./ProfileAndQoutItem.scss";
import checkedIcon from "assets/icons/check.svg";
import CloseReShortlistingModal from "modules/Shared/Modal/ModalComp";

const Profile = ({
	activeProject,
	projectStatus,
	setActiveProject,
	setActiveProjectRoomProjectTab,
	makeReshortlistingChange,
}) => {
	const [shortlisted, updateShortListed] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [isClosedReshortlistingModalOpen, setIsClosedReshortlistingModalOpen] =
		useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		setIsLoading(true);
		let data = {
			projectId: activeProject,
		};
		getProfileAndQuotationCandidates(
			data,
			(success) => {
				setIsLoading(false);
				updateShortListed(success.data);
			},
			(fail) => {
				setIsLoading(false);
				toastError();
			}
		);
	}, [activeProject]);

	const hirePro = (proId) => {
		let HiredList = [{ proId, status: 5 }];
		updateProjectStatus(
			activeProject,
			HiredList,
			(success) => {
				updateShortListed((prevState) => {
					let shortlisted = prevState;
					prevState.filter((item) => {
						item.buttonDisabled = true;
						if (item.proId === proId) {
							item.isHired = true;
						}
						return item;
					});
					return [...shortlisted];
				});
				toastSuccess(success.message);
			},
			(fail) => {
				if (fail.data) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	};

	function onConfirmCloseReshortlisting() {
		reshortlistingAction(false);
		setActiveProject(null);
	}

	function makeReshortlisting() {
		reshortlistingAction(true);
	}

	function onCancelReshortlisting() {
		setIsClosedReshortlistingModalOpen(false);
	}

	function reshortlistingAction(isYes) {
		const payload = { answer: isYes, projectId: activeProject };

		setActiveProjectRoomProjectTab("shortlisting");
		makeReshortlistingChange();
		reshortlisting(
			payload,
			(success) => {
				setActiveProjectRoomProjectTab("shortlisting");
				makeReshortlistingChange(true);
			},
			(fail) => {
				if (fail.data) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	let ReshortlistingQuot = () => (
		<div className="reQuotation">
			<p className="reQuotation__p">
				{t("the3CandidatesDidn'tSubmit")}
				<br />
				{t("wouldYouLikeToReshortlisting")}
			</p>
			<div className="buttons reQuotation__btns">
				<Button
					style={{
						width: 131,
					}}
					className="cancel"
					onClick={() => setIsClosedReshortlistingModalOpen(true)}
				>
					{t("noThanks")}
				</Button>
				<Button
					style={{
						width: 131,
					}}
					type="primary"
					className="confirm"
					onClick={makeReshortlisting}
				>
					{t("yes")}
				</Button>
			</div>
		</div>
	);
	return (
		<>
			{isLoading ? (
				<></>
			) : (
				<>
					{/* projectStatus === 8 */}
					{projectStatus === 8 ? (
						ReshortlistingQuot()
					) : (
						<>
							{!isLoading && shortlisted?.length ? (
								<div className="pro-profile">
									<h4 className="my-2">
										{t("shortlistedCandidatesProfileAndQuotations")}
									</h4>
									<ul className="p-0 row shortlisted">
										{shortlisted.map((short, _idx) => (
											<li className={`mb-3`} key={short.proId}>
												<div className="pro__profile row align-items-center">
													<div className="pro__profile--img col-3">
														<figure>
															<Link
																to={`/ClientViewProfile?ProId=${short.proId}&ProjectId=${activeProject}`}
															>
																<img
																	src={
																		short.proImg
																			? baseUrl + short.proImg
																			: avatar
																	}
																	onError={(e) => {
																		e.target.src = avatar;
																		e.target.alt = "Image failed to load";
																	}}
																	alt="User Profile"
																	className="w-100"
																/>
															</Link>
														</figure>
													</div>
													<div className="pro__profile--details col-6">
														<Link
															to={`/ClientViewProfile?ProId=${short.proId}&ProjectId=${activeProject}`}
															state={{
																data: short,
															}}
														>
															<h4 className="mb-1">{short.proName}</h4>
														</Link>

														<SharedRating value={short.avgRate} readOnly />
														<p
															style={{
																textOverflow: "ellipsis",
																wordWrap: "break-word",
																overflow: "hidden",
																maxHeight: "3.6em",
																lineHeight: "1.8em",
															}}
														>
															{short.bio}
														</p>
													</div>
													<div className="pro-profile__button d-flex justify-content-center col-3">
														{short.isHired ? (
															<Button
																type={"primary"}
																style={{
																	width: 131,
																	height: 40,
																	background: "#5fb4c9",
																	color: "white",
																	borderColor: "#5fb4c9",
																}}
																disabled
															>
																<span>{t("hired")}</span>
																<img
																	style={{ marginInlineStart: "0.25rem" }}
																	src={checkedIcon}
																	alt={"hired"}
																/>
															</Button>
														) : (
															<div>
																<Button
																	type={"primary"}
																	style={{
																		width: 131,
																		height: 40,
																	}}
																	onClick={() => {
																		hirePro(short.proId);
																	}}
																	disabled={
																		short?.buttonDisabled || short?.status === 3
																	}
																>
																	{t("hirePro")}
																</Button>
																{short?.status === 3 && (
																	<div
																		style={{
																			fontSize: "0.75rem",
																			color: "gray",
																			marginTop: "0.5rem",
																		}}
																	>
																		{t("waitingForQuotation")}
																	</div>
																)}
															</div>
														)}
													</div>
												</div>
											</li>
										))}
									</ul>
								</div>
							) : (
								<div
									style={{
										display: "flex",
										minHeight: "400px",
										alignItems: "center",
										justifyContent: "center",
									}}
								>
									<div className="text-empty text-center">
										<div>{t("shortlistYourTop3Candidates")}</div>
										<div>{t("theirQuotationsForYou")}</div>
										<div>{t("project")}.</div>
									</div>
								</div>
							)}
						</>
					)}
				</>
			)}

			{isClosedReshortlistingModalOpen && (
				<CloseReShortlistingModal
					visible={isClosedReshortlistingModalOpen}
					onCancel={onCancelReshortlisting}
					onConfirm={onConfirmCloseReshortlisting}
					title={t("confirmation")}
					desc={t("areYouSureYouWantToReshortlising")}
					subDesc={t("ifYouClickYesThisProjectWillBeClosed")}
					cancelBtn={t("cancel")}
					confirmBtn={t("yesCloseIt")}
				/>
			)}
		</>
	);
};

export default Profile;
