import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import moment from "moment";
import Masonry from "react-masonry-css";
import { baseUrl } from "services";
import { useSpring, animated } from "react-spring";
import { getProffesionalProjects } from "modules/Profiles/network";
import ShowProjectModal from "./ShowProjectModal/ShowProjectModal";
import { toastError } from "helpers/toasterFun";

const breakpointColumnsObj = {
	default: 2,
	1100: 2,
	420: 1,
};

const ClientShowProProjects = ({ selectedProId }) => {
	const [selectedProject, setSelectedProject] = useState(null);
	const [isProjectModalOpened, setIsProjectModalOpened] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [projectsToView, setProjectsToView] = useState(false);
	const { t } = useTranslation();
	const { lang } = useSelector((state) => state.lang);

	useEffect(() => {
		setIsLoading(true);
		if (selectedProId) {
			getProffesionalProjects(
				{ proId: selectedProId, lang },
				(success) => {
					setIsLoading(false);
					setProjectsToView(success.data);
				},
				(fail) => {
					setIsLoading(false);
					if (fail?.data?.error) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		}
	}, [selectedProId, lang]);

	const animatedProps = useSpring({
		from: { opacity: 0, marginInlineStart: -50 },
		to: { opacity: 1, marginInlineStart: 0 },
	});

	function selectProject(project) {
		setSelectedProject(project);
		setIsProjectModalOpened(true);
	}

	return (
		<div
			className={`portofolio__project with__spinner ${
				projectsToView?.length ? "" : "with-empty-string"
			}`}
		>
			{!isLoading && (
				<>
					{projectsToView?.length ? (
						<Masonry
							breakpointCols={breakpointColumnsObj}
							className="my-masonry-grid"
							columnClassName="my-masonry-grid_column"
							dir={lang === "en" ? "ltr" : "rtl"}
						>
							{projectsToView.map((project, i) =>
								!project.isDisabled ? (
									<animated.div style={animatedProps} key={i}>
										<Card className="border-0 project__card">
											<Card.Img
												variant="top"
												src={
													baseUrl +
													project.imageDetails.filter((img) => img.isCovered)[0]
														?.imagePath
												}
												onClick={(e) => selectProject(project)}
												role="button"
												className="box-shadow mb-2"
											/>
											<Card.Body className="p-0">
												<Card.Text className="d-flex justify-content-between align-items-center p-0">
													<div
														className="date mb-2"
														style={{
															color: "#3d3d3d",
															fontSize: "0.85rem",
														}}
													>
														<span>
															{project?.serviceName ||
																project?.serviceID?.name ||
																project?.service}
														</span>{" "}
														|{" "}
														<span>
															{moment(project.createdAt).format("DD-MM-YYYY")}
														</span>
													</div>
												</Card.Text>

												<Card.Title
													className="title"
													role="button"
													onClick={(e) => selectProject(project)}
												>
													{project.projectName}
												</Card.Title>
											</Card.Body>
										</Card>
									</animated.div>
								) : null
							)}
						</Masonry>
					) : (
						<div className="empty-portofolio-text text-center">
							{t("proDontHaveAnyProjects")}
						</div>
					)}
				</>
			)}

			{isProjectModalOpened && (
				<ShowProjectModal
					visible={isProjectModalOpened}
					onCancel={() => setIsProjectModalOpened(false)}
					project={selectedProject}
					isClient={true}
				/>
			)}
		</div>
	);
};

export default ClientShowProProjects;
