import { createSlice } from "@reduxjs/toolkit";

export const authorizationSlice = createSlice({
	name: "language",
	initialState: {
		lang: "en",
		dir: "ltr",
	},
	reducers: {
		changeLang: (state, action) => {
			state.lang = action.payload;
			state.dir = action.payload === "ar" ? "rtl" : "ltr";
		},
	},
});

const { reducer, actions } = authorizationSlice;
export const { changeLang } = actions;
export default reducer;
