import { useEffect, useState } from "react";
import { getHowItWorks } from "modules/Home/network";
import { baseUrl } from "services";
import { useTranslation } from "react-i18next";

import "./HowItWorks.scss";

const HowItWorks = ({ id, isMobile }) => {
	const [media, updateMedia] = useState(null);
	const { i18n, t } = useTranslation();
	const [isPlaying, setIsPlaying] = useState(true);

	useEffect(() => {
		return () => {
			setIsPlaying(false);
		};
	}, []);

	useEffect(() => {
		getHowItWorks(
			(success) => {
				updateMedia(success.data[0].video);
			},
			(fail) => {}
		);
	}, []);
	const urlReplacement = (oldURL) => encodeURI(oldURL)?.replace(/%5C/g, "/");

	return (
		isPlaying && (
			<section id={id} className="home__section home__how-it-work">
				<header className="home__header text-center mb-2">
					<h2 className="text-uppercase">{t("howItWorks")}</h2>
				</header>
				<div className="home__how-it-work__video">
					{media && (
						<video autoPlay={!isMobile} muted loop controls key={i18n.language}>
							<source
								src={
									urlReplacement(baseUrl + media[i18n.language]) + "#t=0.001"
								}
							/>
						</video>
					)}
				</div>
			</section>
		)
	);
};

export default HowItWorks;
