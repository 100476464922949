import {
	createStore,
	combineReducers,
	configureStore,
	applyMiddleware,
} from "@reduxjs/toolkit";
import logger from "redux-logger";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// reducers
import authorizationReducer from "./authorization";
import currentLanguageReducer from "./currentLang";
import langSlice from "./lang";
import routesStore from "./routesStore";

const persistConfig = {
	key: "bidster",
	storage,
	version: 0,
	timeout: 0,
};

// storage: AsyncStorage,

const RootReducer = combineReducers({
	authorization: authorizationReducer,
	currentLanguage: currentLanguageReducer,
	lang: langSlice,
	routes: routesStore,
});

export const store = createStore(RootReducer, applyMiddleware(logger));
const persistedReducer = persistReducer(persistConfig, RootReducer);

export default configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
});
