import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { baseUrl } from "services";
import userAvatar from "assets/images/Group 2354.svg";

import "./ProCard.scss";

function ProCard({ pro }) {
	const { t } = useTranslation();
	return (
		<div className="d-flex proCard justify-content-between align-items-center flex-1">
			<div className="d-flex  align-items-center flex-1">
				<img
					className="avatar mx-2"
					style={{
						objectFit: "cover",
					}}
					src={baseUrl + pro.image}
					onError={(e) => {
						e.target.src = userAvatar;
						e.target.alt = "Image failed to load";
					}}
					alt="avatar"
				/>
				<div>
					<div className="main-color fw-700 f-21">{pro.name}</div>
					<>
						{pro.socialLinks?.map((link) => {
							return <span className="me-2">{link}</span>;
						})}
					</>
					<div>
						<div>{pro.contacts}</div>
						<div>{pro.anotherContact}</div>
					</div>
					<div>{pro.website}</div>
					<div>{pro.email}</div>
				</div>
			</div>
			<div>
				<Button className="btn-main">
					<a href={`tel:${pro.contacts}`}>{t("contact")}</a>
				</Button>
			</div>
		</div>
	);
}

export default ProCard;
