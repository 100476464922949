import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Spin } from "antd";
import {
	getProByProjectId,
	projectsWithShortlistedCands,
} from "../../../../../../network";
import RatingCard from "./RatingCard";
import { toastError } from "./../../../../../../../../helpers/toasterFun";
import "./RatePro.scss";

const RatePro = () => {
	const [projects, setProjects] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const { t } = useTranslation();

	const ReloadAllClientProjects = () => {
		projectsWithShortlistedCands(
			(success) => {
				const { isSuccess } = success;
				const projects = success.data;
				if (isSuccess) {
					projects.map((project) => {
						getProByProjectId(
							project.projectId,
							(success) => {
								setIsLoading(false);
								const { isSuccess } = success;
								const pros = success.data;
								if (isSuccess) {
									setProjects((prevPro) => [
										...prevPro,
										{
											projectId: project.projectId,
											projectName: project.projectName,
											data: pros,
										},
									]);
								}
							},
							(fail) => {
								setIsLoading(false);
								toastError();
							}
						);
						return project;
					});
					setIsLoading(false);
				}
			},
			(fail) => {
				setIsLoading(false);
				toastError();
			}
		);
	};

	useEffect(() => {
		ReloadAllClientProjects();
	}, []);

	return (
		<section className="ratepro-section main-section with__spinner">
			{isLoading ? (
				<div className="spinner__container">
					<Spin className="spinner" />
				</div>
			) : (
				<>
					{!isLoading && projects.length ? (
						<>
							<header className="ratepro__client-header">
								<p>{t("pleaseRateTheProAccordingToTheFollowingCriteria")}</p>
								<p>{t("noteThatYouCanOnlyRateOnePro")}</p>
							</header>
							<ul className="ratepro__projects">
								{projects?.map((item, i) => (
									<RatingCard
										projectToRate={item}
										key={i}
										// ReloadAllClientProjects={ReloadAllClientProjects}
										setProjectsToRate={setProjects}
									/>
								))}
							</ul>
						</>
					) : null}
					{!isLoading && !projects.length ? (
						<>
							<header className="ratepro__header">
								<p className="mb-2">{t("noFinalizeProjectsYet")}</p>
							</header>
						</>
					) : null}
				</>
			)}
		</section>
	);
};

export default RatePro;
