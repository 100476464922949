import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { Row, Col } from "antd";
import {
	Routes,
	Route,
	Navigate,
	useLocation,
	useNavigate,
} from "react-router-dom";
import Subnav from "./../Shared/Subnav/Subnav";
import Sidebar from "./components/Sidebar/Sidebar";
import CreateNewProject from "./components/Content/components/InnerContent/components/CreateNewProject/CreateNewProject";
import RatePro from "./components/Content/components/InnerContent/components/RatePro/RatePro";
import RatingAndReviews from "./components/Content/components/InnerContent/components/RatingAndReviews/RatingAndReviews";
import ProjectStats from "./components/Content/components/InnerContent/components/ProjectStatus/ProjectStatus";
import ProjectsRoom from "./components/Content/components/InnerContent/components/ProjectsRoom/ProjectsRoom";
import ProProjectsRoom from "./components/Content/components/InnerContent/components/ProjectsRoom/ProProjectsRoom/ProProjectsRoom";
import ServiceTypePreferances from "./components/Content/components/InnerContent/components/ServiceTypePreferances/ServiceTypePreferances";
import Portofolio from "./components/Content/components/InnerContent/components/Portofolio/Portofolio";
import ViewProjectsRoomCard from "./components/Content/components/InnerContent/components/ViewProjectsRoomCard/ViewProjectsRoomCard";
import JoyRide from "react-joyride";

import "./Profile.scss";
import { removeRoute } from "store/routesStore";

const Profile = () => {
	const [serviceName, setServiceName] = useState(null);
	const [isChangeToken, setIschangeToken] = useState(false);
	const { authorization } = useSelector((state) => state.authorization);
	const userRole = authorization.role;
	const location = useLocation();
	const { t } = useTranslation();
	const { pathname, role } = useSelector((state) => state.routes);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (
			pathname?.length &&
			role?.toLowerCase() === authorization?.role?.toLowerCase()
		) {
			navigate(pathname);
			dispatch(removeRoute());
		}
	}, [pathname, dispatch, navigate, authorization, role]);

	const steps = [
		{
			target: ".first-step",
			content: t("pleaseFillOutYourProfile"),
		},
		{
			target: ".second-step",
			content: t("uploadYourPortofolio"),
		},
		{
			target: ".third-step",
			content: t("chooseYourWorkPreferences"),
		},
	];

	return (
		<div className="profile-page d-flex">
			{location?.state?.loginTimes === 1 && (
				<JoyRide
					continuous
					steps={steps}
					hideCloseButton={true}
					hideBackButton={false}
					showProgress={true}
					scrollToFirstStep={true}
					disableOverlayClose={true}
					styles={{
						options: {
							arrowColor: "#3D3D3D",
							backgroundColor: "#3D3D3D",
							overlayColor: "rgba(0,0,0,0.3)",
							primaryColor: "#12819c",
							textColor: "white",
						},
					}}
				/>
			)}

			<Container className="d-flex flex-column profile-page__container">
				<Row gutter={[32]} className="mt-4 flex-1">
					<Col xs={24} lg={6}>
						<Sidebar service={serviceName} isChangeToken={isChangeToken} />
					</Col>

					<Col
						xs={24}
						lg={18}
						style={{
							overflow: "hidden",
						}}
						className="profile-page__main-content">
						<Subnav />
						<Routes>
							<Route
								path="portofolio"
								element={
									userRole === "Pro" ? <Portofolio /> : <Navigate to="/" />
								}
							/>
							<Route
								path="project-room"
								element={
									userRole === "Pro" ? <ProProjectsRoom /> : <Navigate to="/" />
								}
							/>
							<Route
								path="project-room/:id"
								element={
									userRole === "Pro" ? (
										<ViewProjectsRoomCard changeCoins={setIschangeToken} />
									) : (
										<Navigate to="/" />
									)
								}
							/>
							<Route
								path="service-type-preference"
								element={
									userRole === "Pro" ? (
										<ServiceTypePreferances setSerivceName={setServiceName} />
									) : (
										<Navigate to="/" />
									)
								}
							/>
							<Route
								path="project-stats"
								element={
									userRole === "Pro" ? <ProjectStats /> : <Navigate to="/" />
								}
							/>
							<Route
								path="rating-reviews"
								element={
									userRole === "Pro" ? (
										<RatingAndReviews />
									) : (
										<Navigate to="/" />
									)
								}
							/>
							{/* client routes */}

							<Route
								path="projects-room"
								element={
									userRole === "Property owner" ? (
										<ProjectsRoom />
									) : (
										<Navigate to="/" />
									)
								}
							/>
							<Route
								path="create-project"
								element={
									userRole === "Property owner" ? (
										<CreateNewProject />
									) : (
										<Navigate to="/" />
									)
								}
							/>
							<Route
								path="rate-pro"
								element={
									userRole === "Property owner" ? (
										<RatePro />
									) : (
										<Navigate to="/" />
									)
								}
							/>
						</Routes>
					</Col>
				</Row>
			</Container>
		</div>
	);
};
export default Profile;
