import { getResource, postResource } from "services";

export function getAllQuestions(lang, accountId, onSuccess, onFail) {
  const path = `api/feedbackQuestions?accountTypeId=${accountId}&lang=${lang}`;
  getResource(path, onSuccess, onFail);
}

// {
//     "listOfQA":[{"questionId":"639b102fcc5e037a2d3f6674","valueAnswer":5},{"questionId":"639b104dcc5e037a2d3f6677","valueAnswer":3}
//     ,{"questionId":"639b107ecc5e037a2d3f667a","valueAnswer":2},{"questionId":"639b10a7cc5e037a2d3f667d","valueAnswer":4}
//     ,{"questionId":"639b10cccc5e037a2d3f6680","valueAnswer":2},{"questionId":"639b10dccc5e037a2d3f6683","valueAnswer":1}
//     ,{"questionId":"639b1107cc5e037a2d3f6686","valueAnswer":2},{"questionId":"639b1143cc5e037a2d3f6689","valueAnswer":1}
//     ,{"questionId":"639b11afcc5e037a2d3f668c","textAnswer":"Stylist"},{"questionId":"639b11cccc5e037a2d3f668f","textAnswer":"Freelancer"}],
//     "projectId":"638c8158fe89394e65368e33",
//     "email":"pro1@pro1.com"
// }

export function postFeedback(
  { listOfQA, projectId, email },
  onSuccess,
  onFail
) {
  const path = "api/feedbackAnswers";
  const data = { listOfQA, projectId, email };
  postResource(path, data, onSuccess, onFail);
}

export function postFeedbackSuggesstion(
  { email, suggestion, projectId },
  onSuccess,
  onFail
) {
  const path = "api/suggestions";
  const data = { email, suggestion, projectId };
  postResource(path, data, onSuccess, onFail);
}
