import { useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { Modal, Input, Spin } from "antd";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { searchInLandingPage } from "../network";
import { toastError } from "helpers/toasterFun";
import { useTranslation } from "react-i18next";
import Navigator from "./Navigator";
import "./SearchModal.scss";

const SearchModal = ({ visible, onCancel }) => {
	const [searchTxt, setSearchTxt] = useState(null);
	const [results, setResults] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const { lang } = useSelector((state) => state.lang);
	const { t } = useTranslation();
	const inputRef = useRef();

	useEffect(() => {
		inputRef.current.focus();
	}, []);

	function handleOnKeyUp(e) {
		if (e.target.value.length) {
			setIsLoading(true);
			searchInLandingPage(
				lang,
				e.target.value,
				(success) => {
					setIsLoading(false);
					setResults(success.data);
				},
				(fail) => {
					setIsLoading(false);
					if (fail.data) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		} else {
			setResults([]);
			setIsLoading(false);
		}
	}

	return (
		<Modal
			visible={visible}
			onCancel={onCancel}
			centered
			footer={null}
			bodyStyle={{
				minHeight: "80vh",
			}}
			className="search-modal"
		>
			<div
				className="d-flex"
				style={{
					display: "flex",
					justifyContent: "center",
				}}
			>
				<Input
					prefix={<SearchOutlined />}
					bordered={false}
					onInput={(e) => handleOnKeyUp(e)}
					ref={inputRef}
					className="input"
					placeholder={t("youAreSearchingFor")}
					value={searchTxt}
					onChange={(e) => setSearchTxt(e.target.value)}
				/>
			</div>

			<div className="result with__spinner">
				{isLoading ? (
					<div className="spinner__container">
						<Spin className="spinner" />
					</div>
				) : null}
				{!isLoading && !results.length && searchTxt?.length ? (
					<div className="result__no-data">
						<CloseCircleOutlined
							style={{
								color: "#c1c1c1",
								fontSize: "5rem",
							}}
						/>
						<p>{t("sorryCouldntFindResult")}</p>
					</div>
				) : null}
				{results.length > 0 ? (
					<>
						{results.map(({ category, searchResult }, idx) => (
							<div className="result__cat" key={idx}>
								<h5 className="category-title">{category}</h5>
								{searchResult.map(({ title, _id, route, question }, idx) => (
									<div className="result__item" key={idx}>
										<div>
											<span>
												<Navigator
													title={title || question}
													category={category}
													_id={_id}
													route={route}
													onCancel={onCancel}
												/>
											</span>
										</div>
									</div>
								))}
							</div>
						))}
					</>
				) : null}
			</div>
		</Modal>
	);
};

export default SearchModal;
