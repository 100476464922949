import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Card } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import Masonry from 'react-masonry-css'
import { baseUrl } from 'services'
import remove from 'assets/icons/delete.svg'
import DeleteProjectModal from 'modules/Shared/Modal/ModalComp'
import { deleteProject } from 'modules/Profiles/network'
import { useSpring, animated } from 'react-spring'
import { toastError } from 'helpers/toasterFun'
import ShowProjectModal from './ShowProjectModal/ShowProjectModal'

import './ShowProProjects.scss'

const ShowProProjects = ({ projects, setProjects, handleAddProject }) => {
  const [removeProjectModalVisible, setRemoveProjectModalVisible] =
    useState(false)
  const [removeProjectId, setRemoveProjectId] = useState(null)
  const [selectedProject, setSelectedProject] = useState(null)
  const [isProjectModalOpened, setIsProjectModalOpened] = useState(false)

  const { t } = useTranslation()
  const { authorization } = useSelector((state) => state.authorization)
  const { lang } = useSelector((state) => state.lang)
  const breakpointColumnsObj = {
    default: 3,
    1100: 3,
    420: 1,
  }

  const location = useLocation()

  const animatedProps = useSpring({
    from: { opacity: 0, marginInlineStart: -50 },
    to: { opacity: 1, marginInlineStart: 0 },
  })

  function confirmDeleteProject() {
    let remainProjects = projects.filter(
      (project) => project._id !== removeProjectId
    )

    deleteProject(
      removeProjectId,
      (success) => {
        const { isSuccess } = success
        if (isSuccess) {
          setProjects(remainProjects)
          setRemoveProjectModalVisible(false)
          setRemoveProjectId(null)
        }
      },
      (fail) => {
        toastError(fail.error.message)
      }
    )
  }

  const showInUser = !(
    location.pathname.includes('/ClientViewProfile') ||
    authorization.role.includes('Property')
  )

  function selectProject(project) {
    setSelectedProject(project)
    setIsProjectModalOpened(true)
  }

  return (
    <div className='portofolio__project'>
      {projects?.length ? (
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className='my-masonry-grid'
          columnClassName='my-masonry-grid_column'
          dir={lang === 'en' ? 'ltr' : 'rtl'}
        >
          {projects.map((project, i) =>
            !project.isDisabled ? (
              <animated.div style={animatedProps} key={i}>
                <Card className='border-0 project__card'>
                  {!project.isReviewed && showInUser && !project?.isApproved ? (
                    <div className='pending-files box-shadow'>
                      {project?.pendingImgsCount}{' '}
                      {10 >= project?.pendingImgsCount &&
                      project?.pendingImgsCount > 2
                        ? t('pendingFiles')
                        : t('pendingFile')}
                    </div>
                  ) : null}
                  <Card.Img
                    variant='top'
                    src={
                      baseUrl +
                      (project.imageDetails.length === 1
                        ? project.imageDetails[0].imagePath
                        : project.imageDetails.filter((img) => img.isCovered)[0]
                            ?.imagePath)
                    }
                    onClick={(e) => selectProject(project)}
                    role='button'
                    className='box-shadow mb-2'
                    style={{ objectFit: 'cover' }}
                  />
                  <Card.Body className='p-0'>
                    <Card.Text className='d-flex justify-content-between align-items-center p-0 mb-1'>
                      <div className='date'>
                        <span>
                          {project?.serviceName ||
                            project?.serviceID?.name ||
                            project?.service}
                        </span>{' '}
                        |{' '}
                        <span>
                          {moment(project.createdAt).format('DD-MM-YYYY')}
                        </span>
                      </div>
                      {showInUser ? (
                        <small className='d-flex justify-content-between align-items-center'>
                          <span
                            className='mx-1 edit'
                            role='button'
                            onClick={(e) => handleAddProject(true, project)}
                          >
                            {t('edit')}
                          </span>{' '}
                          |{' '}
                          <img
                            src={remove}
                            alt='Remove'
                            role='button'
                            className='mx-1 remove'
                            onClick={() => {
                              setRemoveProjectId(project._id)
                              setRemoveProjectModalVisible(true)
                            }}
                          />
                        </small>
                      ) : null}
                    </Card.Text>
                    <Card.Title
                      className='title'
                      role='button'
                      onClick={(e) => selectProject(project)}
                    >
                      {project.projectName}
                    </Card.Title>
                  </Card.Body>
                </Card>
              </animated.div>
            ) : null
          )}
        </Masonry>
      ) : (
        <div className='empty-portofolio-text text-center text-empty'>
          {t('pleaseAddProjectToYourPortofolio')}
        </div>
      )}

      {removeProjectModalVisible && (
        <DeleteProjectModal
          visible={removeProjectModalVisible}
          onCancel={() =>
            setRemoveProjectModalVisible(!removeProjectModalVisible)
          }
          onConfirm={confirmDeleteProject}
          title={t('delete')}
          confirmation={t('areYouSureYouWantToDeleteProject')}
          confirmBtn={t('delete')}
          cancelBtn={t('cancel')}
        />
      )}
      {isProjectModalOpened && (
        <ShowProjectModal
          visible={isProjectModalOpened}
          onCancel={() => setIsProjectModalOpened(false)}
          project={selectedProject}
        />
      )}
    </div>
  )
}

export default ShowProProjects
