import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Input, Space, Checkbox, Radio, Button, Spin } from "antd";
import { signIn, getAllAccountTypes } from "../network";
import { getToken } from "services";
import { login } from "store/authorization";
import { useNavigate } from "react-router-dom";
import { toastError } from "helpers/toasterFun";
import { changeloginTimes } from "store/authorization";
import closeIcon from "assets/icons/x.svg";

import "../authorization.scss";
import { useSelector } from "react-redux";

const PropertyOwnerID = '625be6db995eb93c8f7bfdbf';

const Signin = ({ setCurrentView, guestProject, setOpenModal }) => {
	const [accountTypes, setAccountTypes] = useState(null);
	const [selectedAccountType, setSelectedAccountType] = useState(null);
	const [loading, setLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(true);
	const [isKeepMeLoggedIn, setIsKeepMeLoggedIn] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { lang } = useSelector((state) => state.lang);

	const redirectData = JSON.parse(localStorage.getItem("redirectData"));

	useEffect(() => {
		setIsDisabled(true);
		getAllAccountTypes(
			lang,
			(success) => {
				const { data } = success;
				setIsDisabled(false);
        if (setCurrentView) setAccountTypes(data?.filter(d => d._id === PropertyOwnerID));
				else setAccountTypes(data);
        if (setCurrentView) setSelectedAccountType(PropertyOwnerID);
				else setSelectedAccountType(data[0]._id);
			},
			(fail) => {
				setIsDisabled(false);
			}
		);
	}, [setCurrentView, lang]);

	function submitSignin(values) {
		const { email, password } = values;
		if (email && password && selectedAccountType) {
			setLoading(true);
			const data = {
				email: email.trim(),
				password: password.trim(),
				accountType: selectedAccountType,
				lang,
				isKeepMeLoggedIn: isKeepMeLoggedIn,
        guestProject: guestProject,
			};

			signIn(
				data,
				lang,
				({ isSuccess, data }) => {
					setLoading(false);
					if (isSuccess) {
						dispatch(login(data));
						dispatch(changeloginTimes(data.loginTimes));
						getToken(data.token);

						if (
							redirectData &&
							"Home%20Owner" === redirectData.search?.toLowerCase()
						) {
							localStorage.removeItem("redirectData");
							navigate(redirectData.pathname, {
								state: {
									loginTimes: data.loginTimes,
								},
							});
						} else {
							if (data.role === "Pro") {
								navigate("/profile/portofolio", {
									state: {
										loginTimes: data.loginTimes,
									},
								});
							} else {
								navigate("/profile/projects-room");
							}
						}
					}
				},
				(fail) => {
					setLoading(false);
					if (fail?.data.message) {
						toastError(fail.data.message);
					} else if (fail?.data.error) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		}
	}

	// work-arround to unfreeze the input field
	function unfreezeInput(e) {
		// eslint-disable-next-line
		e.target.value = e.target.value;
	}

	return (
		<main className="auth with__spinner">
			{isDisabled && (
				<div className="spinner__container">
					<Spin className="spinner" />
				</div>
			)}
			<section className="auth__section">
        {setCurrentView&&setOpenModal ?
          <Button onClick={() => setOpenModal(false)} style={{border:"none", display:"contents"}}>
            <img className="close-popup" src={closeIcon} alt="Close-SignIn" />
          </Button>:
					<Link to="/">
					  <img className="close-popup" src={closeIcon} alt="Close-SignIn" />
				  </Link>
        }
				<header className="auth__head">
					<h3 className="auth__title">{t("signIn")}</h3>
				</header>
				<div className="auth__form-content">
					<div className="new-user">
						<div className="new-user__text">{t("newUser")}</div>
						<div className="new-user__create">
              {setCurrentView ?
              <Button className="auth__btn__anchor" onClick={() => setCurrentView("signup")}>{t("createAnAccount")}</Button>
              :
							<Link to="/signup">{t("createAnAccount")}</Link>
              }
						</div>
					</div>
					<div className="auth__user-type d-flex">
						<Radio.Group
							value={selectedAccountType}
							onChange={(e) => setSelectedAccountType(e.target.value)}
						>
							{accountTypes?.length &&
								accountTypes?.map((item) => (
									<Radio value={item._id} key={item._id}>
										{item.accountType}
									</Radio>
								))}
						</Radio.Group>
					</div>
					<Form className="form" onFinish={submitSignin} key={lang}>
						<Space className="w-100" size="small" direction="vertical">
							<Form.Item
								className="form__item"
								label={t("email")}
								colon={true}
								name="email"
								rules={[
									{
										required: true,
										message: t("provideYourEmail"),
									},
									{
										type: "email",
										message: t("provideValidEmail"),
									},
								]}
							>
								<Input
									style={{
										direction: "ltr",
									}}
									id="email"
									placeholder={`${
										selectedAccountType === "625be6e5995eb93c8f7bfdc1"
											? "Pro"
											: "Client"
									}@email.com`}
									className="form-underline"
									type="text"
									bordered={false}
									dir={"ltr"}
									onClick={(event) => unfreezeInput(event)}
								/>
							</Form.Item>
							<Form.Item
								className="form__item"
								label={t("password")}
								name="password"
								rules={[
									{
										required: true,
										message: t("provideYourPassword"),
									},
								]}
							>
								<Input.Password
									bordered={false}
									id="password"
									placeholder="*********"
									className="form-underline"
									type="password"
									onClick={(event) => unfreezeInput(event)}
								/>
							</Form.Item>
							<Link className="form__forget" to="/reset-password">
								<span>{t("forgetPassword")}</span>
							</Link>
							<div className="form__keep">
								<Checkbox
									className="form__checkbox--checked"
									id="keep-signin"
									// checked
									onChange={(e) => setIsKeepMeLoggedIn(e.target.checked)}
								/>
								<label htmlFor="keep-signin">{t("keepMeSignIn")}</label>
							</div>
							<div className="primary-bid-btn d-flex justify-content-center">
								<Form.Item>
									<Button
										type={"primary"}
										loading={loading}
										className="btn auth-btn"
										onClick={submitSignin}
										htmlType="submit"
									>
										{t("signIn")}
									</Button>
								</Form.Item>
							</div>
						</Space>
					</Form>
				</div>
			</section>
		</main>
	);
};

export default Signin;
