import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Form, Button, Input, Radio, Spin } from "antd";
import { forgetPassword } from "../network";
import { getAllAccountTypes } from "modules/Authentication/network";
import { toastError, toastSuccess } from "helpers/toasterFun";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [accounts, setAccounts] = useState([]);
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { lang } = useSelector((state) => state.lang);

	useEffect(() => {
		setIsDataLoading(true);
		getAllAccountTypes(
			lang,
			(success) => {
				setIsDataLoading(false);
				setAccounts(success.data);
			},
			(fail) => {
				setIsDataLoading(false);
				if (fail.data) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}, [lang]);
	function onFinish(value) {
		const { email, accountType } = value;
		setIsLoading(true);
		forgetPassword(
			{ email, accountType },
			(success) => {
				setIsLoading(false);
				toastSuccess(success.message);
				navigate("/signin");
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.message) {
					toastError(fail.data.message);
				} else if (fail?.data) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	return (
		<div className="forget ">
			<div
				className="forget__modal box-shadow with__spinner"
				style={{ height: 310 }}
			>
				{isDataLoading && (
					<div className="spinner__container">
						<Spin className="spinner" />
					</div>
				)}
				<header className="forget__header">
					<h2
						style={{
							marginTop: "1rem",
						}}
					>
						{t("forgetPassword")}
					</h2>
				</header>
				<Form key={lang} onFinish={onFinish}>
					<Form.Item
						name="accountType"
						label={t("youAreA")}
						rules={[
							{ required: true, message: t("pleaseChooseYourAccountType") },
						]}
					>
						<Radio.Group>
							{accounts.length
								? accounts.map(({ accountType, _id }, idx) => (
										<Radio value={_id} key={_id}>
											{accountType}
										</Radio>
								  ))
								: null}
						</Radio.Group>
					</Form.Item>

					<Form.Item
						name="email"
						label={t("email")}
						className="input-item"
						rules={[
							{
								required: true,
								message: t("provideYourEmail"),
							},
							{
								type: "email",
								message: t("provideValidEmail"),
							},
						]}
					>
						<Input
							type="email"
							placeholder={"Example@email.com"}
							bordered={false}
							dir="ltr"
						/>
					</Form.Item>
					<Form.Item className="forget__btn-contianer">
						<Button
							type="primary"
							style={{
								width: 141,
								height: 45,
							}}
							loading={isLoading}
							htmlType="submit"
							className="forget__btn"
						>
							{t("resetPassword")}
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
};

export default ForgetPassword;
