import { toast } from "react-toastify";

export const toastTime = 2000;
const toastParams = {
  rtl: true,
  autoClose: toastTime,
};

export function toastSuccess(message) {
  toastParams.toastId = message;
  toast.success(message, toastParams);
}

export function toastError(message = null) {
  toastParams.toastId = message;
  if (null) {
    toast.error("An Error Occured", toastParams);
  } else {
    toast.error(message, toastParams);
  }
}
