import { getResource } from "services";

export function getTermsAndConditions(lang, onSuccess, onFail) {
  const path = `api/termsAndConditions?lang=${lang}`;
  getResource(path, onSuccess, onFail);
}

export function getPrivacyPolicy(lang, onSuccess, onFail) {
  const path = `api/privacyPolicy?lang=${lang}`;
  getResource(path, onSuccess, onFail);
}
