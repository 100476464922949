import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Radio, Row, Col, Input, Form, Select, Button, Spin } from "antd";
import { Checkbox } from "antd";
import {
	getAllServices,
	getAllServicesQuestion,
	getAllServiceTypes,
	uploadProjectDrawings,
	getProjectStatus,
	clientProjects,
	getHandoverRequirements,
	getOptionalServices,
	getMaxFileSize,
	getPargraphQuestions,
	getDraftProject,
	addProjectToDraft,
	deleteProjectDraft as deleteProjectFromDraft,
} from "modules/Profiles/network";

import { Link } from "react-router-dom";
import { toastError, toastSuccess, toastTime } from "helpers/toasterFun";
import { isArray } from "lodash";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import Logo from "assets/images/logo.svg";
import ModalComp from "modules/Shared/Modal/ModalComp";
import FileUploadImg from "assets/icons/file-upload.svg";

import "react-toastify/dist/ReactToastify.css";
import "./CreateNewProject.scss";
import { useSelector } from "react-redux";
import AuthModal from "modules/GuestCreateNewProject/authmodal";

const CreateNewProject = () => {
	const [projectName, setProjectName] = useState("");
	const [projectArea, setProjectArea] = useState(null);
	const [locationList, updateLocationList] = useState(null);
	const [projectStatusList, updateProjectStatusList] = useState([]);
	const [uploadedDrawings, updateUploadedDrawings] = useState([]);
	const [uploadDrawingsLoading, setUploadDrawingsLoading] = useState(false);
	const [uploadedDrawingsName, setUploadedDrawingsName] = useState(null);
	const [services, setServices] = useState(null);
	const [serviceTypes, setServiceTypes] = useState([]);
	const [confirmModalState, toggleConfirmModalState] = useState(false);
	const [selectedProjectStatusId, updateSelectedProjectStatusId] =
		useState(null);
	const [Disclamer, updateDisclaimer] = useState([]);
	const [handoverReqOptions, updateHandoverReqOptions] = useState([]);
	const [serviceTypesId, setServiceTypesId] = useState(null);
	const [serviceTypeItems, setServiceTypeItems] = useState([]);
	const [selectedServiceTypeItems, updateSelectedServiceTypeItems] =
		useState(null);
	const [servicesQuestions, setServicesQuestions] = useState([]);
	const [servicesQuestionsRequired, setServicesQuestionsRequired] = useState(
		[]
	);
	const [selectedServiceID, setselectedServiceID] = useState(null);
	const [extraComment, updateExtraComment] = useState("");
	const [questionsAnswersArr, updateQuestionsAnswersArr] = useState([]);
	const [paymentList, updatePaymentList] = useState(null);
	const [errorState, updateErrorState] = useState(false);
	const [handOverCheckedVals, updateHandOverCheckedVals] = useState([]);
	const [otherServiceTypeItems, updateOtherServiceTypeItems] = useState(null);
	const [handOverOtherAnswer, setHandOverOtherAnswer] = useState("");
	const [optionalServices, setOptionalServices] = useState([]);
	const [selecctedOptionalServices, setSelecctedOptionalServices] = useState(
		[]
	);
	const [disableSubmit, setDisableSubmit] = useState(false);
	const [timeOutToUse, setTimeOutToUse] = useState(null);
	const [maxFileSize, setMaxFileSize] = useState(null);
	const [isDataLoading, setIsDataLoading] = useState(true);
	const [fileSize, setFileSize] = useState(null);
	const [paragraphQuestions, setParagraphQuestions] = useState({});
	const [paragraphAnswers, setParagraphAnswers] = useState({});
	const [submittedData, setSubmittedData] = useState({});
	const [isDisabled, setIsDisabled] = useState(false);
	const [haveGetTheDrafts, setHaveGetTheDrafts] = useState(false);
	const [isTakeActionOnServiceType, setIsTakeActionOnServiceType] =
		useState(false);
	const [isTakeActionOnService, setIsTakeActionOnService] = useState(false);
	const [selectedLocation, setSelectedLocation] = useState(null);
	const [selectedPaymentPreferances, setSelectedPaymentPreferances] =
		useState(null);
	const [selectedProjectStatus, setSelectedProjectStatus] = useState(null);
	useState(null);
	const [hasDraft, setHasDraft] = useState(false);
	const [hasParagraphAnswers, setHasParagraphAnswers] = useState(true);
	const [hasServiceQuestionAnswers, setHasServiceQuestionAnswers] =
		useState(true);

	const navigate = useNavigate();
	const { t } = useTranslation();
	const { Option } = Select;
	const { lang } = useSelector((state) => state.lang);
  const isGuest = !useSelector((state) => state.authorization)?.authorization?.token;
	useEffect(() => {
		getDraftProject(
			(success) => {
				setHaveGetTheDrafts(true);
				if (success.data) {
					setHasDraft(true);
					const {
						projectName,
						serviceId,
						serviceTypeId,
						serviceTypeItems,
						otherServiceTypeItems,
						paragraphAnswers,
						comment,
						projectArea,
						drawingsPath,
						serviceQuestions,
						otherHandoverRequirementAnswer,
						handoverrequirements,
						optionalServices,
						projectStatusId,
					} = success.data;

					setProjectName(projectName);
					updateExtraComment(comment);
					setselectedServiceID(serviceId);
					if (serviceTypeItems?.length) {
						updateSelectedServiceTypeItems(serviceTypeItems[0]["_id"]);
					}
					if (otherServiceTypeItems?.length) {
						updateSelectedServiceTypeItems((prev) => "other");
						updateOtherServiceTypeItems(otherServiceTypeItems);
					}
					// Service Types
					setServiceTypesId(serviceTypeId);

					if (paragraphAnswers?.length) {
						paragraphAnswers.map((item) => {
							setParagraphAnswers((prev) => ({
								...prev,
								[item.questionId]: {
									questionId: item.questionId,
									answer: item.answer,
									isRequired: item.isRequired,
								},
							}));
							return null;
						});
					} else {
						setHasParagraphAnswers(false);
					}
					// Paragraph Answers

					// Project Area
					setProjectArea(projectArea);

					// Uploaded Drawings
					if (drawingsPath?.length) {
						let splitPath = drawingsPath[0].split("_");
						let fileName;
						if (splitPath.length > 2) {
							splitPath.shift();
							fileName = splitPath.join("_");
							setUploadedDrawingsName(fileName);
						} else {
							fileName = splitPath[1];
							setUploadedDrawingsName(fileName);
						}
						updateUploadedDrawings(drawingsPath);
					}

					// Service Questions
					let questionsAnswersArrVar = [];
					if (serviceQuestions.length) {
						serviceQuestions.forEach((question) => {
							let allData = {
								questionId: question.questionId,
								answerId: question.answerId || null,
							};
							if (
								question?.hasOtherAnswer &&
								!question.isLocation &&
								!question.isPayment
							) {
								allData.theOtherAnswer = question?.theOtherAnswer || "";
								if (question?.theOtherAnswer?.length) {
									allData.answerKey = "other";
								}
							}
							questionsAnswersArrVar.push(allData);
						});
						let draftedProjectLocation = serviceQuestions.find(
							(question) => question.isLocation
						).answerId;
						let draftedPaymentPreferances = serviceQuestions.find(
							(question) => question.isPayment
						).answerId;

						setSelectedLocation(draftedProjectLocation);
						setSelectedPaymentPreferances(draftedPaymentPreferances);
						setSelectedProjectStatus(projectStatusId);

						updateQuestionsAnswersArr(questionsAnswersArrVar);
					} else {
						setHasServiceQuestionAnswers(false);
					}
					// Handover Requirements
					if (handoverrequirements?.length) {
						let handoverValues = handoverrequirements?.map((item) => item._id);
						if (otherHandoverRequirementAnswer?.length) {
							handoverValues.push("other");
							setHandOverOtherAnswer(otherHandoverRequirementAnswer);
						}

						updateHandOverCheckedVals(handoverValues);
					}

					//Optional Services
					if (optionalServices?.length) {
						let draftedOptionalServices = optionalServices.map(
							(item) => item._id
						);
						setSelecctedOptionalServices(draftedOptionalServices);
					}
				} else {
					setHasDraft(false);
					setHasParagraphAnswers(false);
					setHasServiceQuestionAnswers(false);
				}
			},
			(fail) => {
				if (fail.data) {
					toastError(fail.data?.error);
				}
				toastError();
			}
		);
	}, [lang]);

	useEffect(() => {
		if (maxFileSize) setFileSize(maxFileSize.ImageSize);
	}, [maxFileSize]);

	useEffect(() => {
		return () => clearTimeout(timeOutToUse);
	}, [timeOutToUse]);

	// reset selected service type items
	useEffect(() => {
		if (isTakeActionOnService) {
			setSelectedLocation(null);
			setSelectedPaymentPreferances(null);
			setSelectedProjectStatus(null);
			updateSelectedProjectStatusId(null);
		}
	}, [serviceTypesId, isTakeActionOnService]);

	useEffect(() => {
		if (isTakeActionOnServiceType) {
			updateSelectedServiceTypeItems(null);
		}
	}, [isTakeActionOnServiceType]);
	// get max file size
	useEffect(() => {
		if (selectedServiceID) {
			getMaxFileSize(
				lang,
				selectedServiceID,
				(success) => {
					setMaxFileSize(success.data);
				},
				(fail) => {
					if (fail?.data?.error) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		}
	}, [selectedServiceID, lang]);

	// get All Service
	useEffect(() => {
		if (haveGetTheDrafts) {
			getAllServices(
				lang,
				(success) => {
					const { isSuccess } = success;
					if (isSuccess) {
						setServices(success.data);

						let selectedServiceId;
						setselectedServiceID((prev) => {
							selectedServiceId = prev || success.data[0]._id;
							return selectedServiceId;
						});

						if (selectedServiceId) {
							let selectedDisclaimer = success.data.filter(
								(item) => item._id === selectedServiceId
							)[0].disclaimer;
							updateDisclaimer(selectedDisclaimer.split("-"));
						}
					}
				},
				(fail) => {}
			);
		}
	}, [lang, haveGetTheDrafts]);

	// get optional services
	useEffect(() => {
		getOptionalServices(
			lang,
			(success) => {
				setOptionalServices(success.data);
			},
			(fail) => {}
		);
	}, [lang]);

	// get handover requriements
	useEffect(() => {
		if (selectedServiceID) {
			let data = { serviceId: selectedServiceID };
			getHandoverRequirements(
				lang,
				data,
				(success) => {
					updateHandoverReqOptions(success.data);
					// TODO: look at me
					if (isTakeActionOnService) {
						updateHandOverCheckedVals((prev) => []);
						setHandOverOtherAnswer(null);
					}
				},
				(fail) => {}
			);
			getProjectStatus(
				lang,
				selectedServiceID,
				(success) => {
					updateProjectStatusList(success.data);
				},
				(fail) => {}
			);
		}
	}, [selectedServiceID, isTakeActionOnService, lang]);

	// set service type items
	useEffect(() => {
		if (serviceTypesId && serviceTypes.length && haveGetTheDrafts) {
			const serviceTypeItemss = serviceTypes.filter(
				(serviceType) => serviceType.serviceTypeId === serviceTypesId
			)[0].serviceTypeItems;
			setServiceTypeItems(serviceTypeItemss);
		}
	}, [serviceTypesId, serviceTypes, haveGetTheDrafts]);

	// get All Service Types
	useEffect(() => {
		if (selectedServiceID) {
			getAllServiceTypes(
				lang,
				(success) => {
					setServiceTypes(success.data);
					let selectedServiceTypeItems;
					setServiceTypesId((prev) => {
						selectedServiceTypeItems = prev;
						return prev;
					});
					if (selectedServiceTypeItems?.length) {
						let servcieTypeItems = success.data.filter(
							(serviceType) =>
								serviceType.serviceTypeId === selectedServiceTypeItems
						)[0].serviceTypeItems;
						setServiceTypeItems(servcieTypeItems);
					} else {
						setServiceTypeItems(success.data[0].serviceTypeItems);
					}
				},
				(fail) => {}
			);
		}
	}, [selectedServiceID, lang]);

	// getAllQuestions
	useEffect(() => {
		if (selectedServiceID) {
			getAllServicesQuestion(
				selectedServiceID,
				lang,
				(success) => {
					setIsDataLoading(false);
					let paymentList = success.data.filter((data) => data.isPayment);
					const locationList = success.data.filter((data) => data.isLocation);
					let questionsList = success.data.filter(
						(data) => !data.isLocation && !data.isPayment
					);
					updatePaymentList(paymentList[0]);
					updateLocationList(locationList[0]);
					setServicesQuestions(questionsList);
					setServicesQuestionsRequired(
						questionsList.filter((item) => item.isRequired)
					);

					if (isTakeActionOnService || !hasServiceQuestionAnswers) {
						let questionsAnswersArrVar = [];
						updateQuestionsAnswersArr([]);
						console.log("he comes here");
						success.data.forEach((data) => {
							let allData = {
								questionId: data.serviceQuestionId,
								answerId: null,
							};
							if (data.hasOtherAnswer && !data.isLocation && !data.isPayment) {
								allData.theOtherAnswer = "";
							}
							questionsAnswersArrVar.push(allData);
						});
						updateQuestionsAnswersArr(questionsAnswersArrVar);
					}
				},
				(fail) => {
					setIsDataLoading(false);
				}
			);
		}
	}, [
		selectedServiceID,
		lang,
		isTakeActionOnService,
		hasServiceQuestionAnswers,
	]);

	// getParagraphQuestions
	useEffect(() => {
		if (selectedServiceID) {
			setParagraphAnswers({});
			getPargraphQuestions(
				lang,
				selectedServiceID,
				(success) => {
					setParagraphQuestions(success.data);
					success.data.forEach((question) => {
						if (isTakeActionOnService || !hasParagraphAnswers) {
							setParagraphAnswers((prev) => ({
								...prev,
								[question._id]: {
									questionId: question._id,
									answer: null,
									isRequired: question.isRequired,
								},
							}));
						}
					});
				},
				(fail) => {
					if (fail?.data?.error) {
						toastError(fail?.data?.error);
					} else toastError();
				}
			);
		}
	}, [selectedServiceID, lang, isTakeActionOnService, hasParagraphAnswers]);

	const handleChangeProjectStatus = (value) => {
		updateSelectedProjectStatusId(value);
	};

	const changeSelectedServiceId = (e) => {
		setIsTakeActionOnService(true);
		setselectedServiceID(e.target.value);
		const disclaimer = services.filter(
			(service) => service._id === e.target.value
		)[0].disclaimer;
		updateDisclaimer(disclaimer);
		setServiceTypesId(null);
		setServiceTypeItems([]);
	};

	const handleChangeServiceType = (e) => {
		setIsTakeActionOnServiceType(true);
		setServiceTypesId(e.target.value);
	};

	const handleChangeServiceItemId = (e) => {
		updateSelectedServiceTypeItems(e.target.value);
	};

	const handleUploadDoc = (e) => {
		let filesData = new FormData();
		const drawingDocuments = [...e.target.files];
		const exceedMaxLimit = drawingDocuments.every(
			(file) => file.size >= fileSize * 1024 * 1024
		);

		if (exceedMaxLimit) {
			toastError(t("drawingMustBeLessThan") + fileSize + t("mb"));
			return;
		}

		drawingDocuments.forEach((file, index) => {
			filesData.append(`drawings`, file);
		});

		setUploadDrawingsLoading(true);
		uploadProjectDrawings(
			filesData,
			(success) => {
				if (success.isSuccess) {
					toastSuccess(t("uploadedSuccessFully"));
					let pathArr = [];
					success.data.forEach((data) => {
						pathArr.push(data.path);
					});
					updateUploadedDrawings(pathArr);
					setUploadedDrawingsName(e.target.files[0].name);
					setUploadDrawingsLoading(false);
				}
			},
			(fail) => {
				toastError();
				setUploadDrawingsLoading(false);
			}
		);
	};

	const handleChangeQuestionAnswer = (questionId, answerId) => {
		let questionsAnswersArrVar = JSON.parse(
			JSON.stringify(questionsAnswersArr)
		);
		const indexOfQuestion = questionsAnswersArrVar.findIndex(
			(data) => data.questionId === questionId
		);

		if (answerId === "other") {
			delete questionsAnswersArrVar[indexOfQuestion].answerId;
			questionsAnswersArrVar[indexOfQuestion].theOtherAnswer = "";
			questionsAnswersArrVar[indexOfQuestion].answerKey = "other";
		} else {
			questionsAnswersArrVar[indexOfQuestion].answerId = answerId;
			delete questionsAnswersArrVar[indexOfQuestion].answerKey;
			delete questionsAnswersArrVar[indexOfQuestion].theOtherAnswer;
		}
		console.log("questionAnswersArrVar: ", questionsAnswersArrVar);
		updateQuestionsAnswersArr(questionsAnswersArrVar);
	};

	const handleSubmit = () => {
		console.log(
			"hi there",
			selectedLocation,
			selectedPaymentPreferances,
			selectedProjectStatus
		);
		if (!projectName) {
			toastError(t("pleaseFillProjectNameField"));
			return;
		}

		if (!serviceTypesId) {
			toastError(t("pleaseChooseBetweenResidentialOrCommercial"));
			return;
		}

		if (
			(selectedServiceTypeItems === "other" && !otherServiceTypeItems) ||
			!selectedServiceTypeItems
		) {
			toastError(t("pleaseProvideYourServiceType"));
			return;
		}

		let isAnswerRequiredQuestions = true;

		servicesQuestionsRequired.forEach(({ serviceQuestionId }) => {
			let question = questionsAnswersArr.find(
				(answer) => serviceQuestionId === answer.questionId
			);

			if (!question?.answerId && !question?.theOtherAnswer) {
				isAnswerRequiredQuestions = false;
				return;
			}
		});

		if (!isAnswerRequiredQuestions) {
			toastError(t("pleaseFillAllTheRequiredFields"));
			return;
		}

		const hasAllRequiredParagraphAnswered = Object.keys(paragraphAnswers).every(
			(key) => {
				if (
					(paragraphAnswers[key].isRequired && paragraphAnswers[key].answer) ||
					!paragraphAnswers[key].isRequired
				) {
					return true;
				} else {
					return false;
				}
			}
		);

		if (!projectArea) {
			toastError(t("pleaseProvideYourExactArea"));
			return;
		}

		if (!hasAllRequiredParagraphAnswered) {
			toastError(t("pleaseFillAllTheRequiredFields"));
			return;
		}

		if (
			!selectedLocation ||
			!selectedPaymentPreferances ||
			!selectedProjectStatus
		) {
			toastError(t("pleaseFillAllTheRequiredFields"));
			return;
		}

		if (!uploadedDrawingsName) {
			toastError(t("pleaseUploadProjectDrawings"));
			return;
		}

		if (uploadDrawingsLoading) {
			toastError(t("waitTillProjectDrawingsUploaded"));
			return;
		}

		let data = JSON.parse(
			JSON.stringify({
				name: projectName,
				serviceId: selectedServiceID,
				serviceTypeId: serviceTypesId,
				serviceTypeItemsId: selectedServiceTypeItems,
				projectStatusId: selectedProjectStatusId || selectedProjectStatus,
				drawingsPath: uploadedDrawings,
				serviceQuestions: questionsAnswersArr,
				comment: extraComment,
				projectArea: parseInt(projectArea),
				handoverRequirementIds: handOverCheckedVals,
				optionalServices: selecctedOptionalServices,
				paragraphAnswers: Object.values(paragraphAnswers),
			})
		);
		// paragraphAnswers

		if (handOverCheckedVals.includes("other") && handOverOtherAnswer?.length) {
			data.handoverRequirementIds = data.handoverRequirementIds.filter(
				(item) => item !== "other"
			);
			data.otherHandoverRequirementAnswer = handOverOtherAnswer;
		}

		if (handOverCheckedVals.includes("other") && !handOverOtherAnswer?.length) {
			data.handoverRequirementIds = data.handoverRequirementIds.filter(
				(item) => item !== "other"
			);
			delete data.otherHandoverRequirementAnswer;
		}

		if (
			!data.handoverRequirementIds?.length &&
			!data?.otherHandoverRequirementAnswer?.length &&
			handoverReqOptions.length
		) {
			toastError(t("pleaseSelectHandoverRequirement"));
			return;
		}

		if (!data.projectStatusId) {
			toastError(t("pleaseFillProjectStatus"));
			return;
		}

		if (selectedServiceTypeItems === "other") {
			data.otherServiceTypeItems = otherServiceTypeItems;
			delete data.serviceTypeItemsId;
		}

		setSubmittedData(data);
		updateErrorState(false);
		toggleConfirmModalState(true);
	};

	const createProjectDraft = () => {
		let data = JSON.parse(
			JSON.stringify({
				name: projectName,
				serviceId: selectedServiceID,
				serviceTypeId: serviceTypesId,
				serviceTypeItemsId: selectedServiceTypeItems,
				projectStatusId: selectedProjectStatusId,
				drawingsPath: uploadedDrawings,
				serviceQuestions: questionsAnswersArr,
				comment: extraComment,
				projectArea: parseInt(projectArea),
				handoverRequirementIds: handOverCheckedVals,
				optionalServices: selecctedOptionalServices,
				paragraphAnswers: Object.values(paragraphAnswers),
			})
		);

		// paragraphAnswers
		if (handOverCheckedVals.includes("other") && handOverOtherAnswer?.length) {
			data.handoverRequirementIds = data.handoverRequirementIds.filter(
				(item) => item !== "other"
			);
			data.otherHandoverRequirementAnswer = handOverOtherAnswer;
		}

		if (handOverCheckedVals.includes("other") && !handOverOtherAnswer?.length) {
			data.handoverRequirementIds = data.handoverRequirementIds.filter(
				(item) => item !== "other"
			);
			delete data.otherHandoverRequirementAnswer;
		}

		if (selectedServiceTypeItems === "other") {
			data.otherServiceTypeItems = otherServiceTypeItems;
			delete data.serviceTypeItemsId;
		}

		addProjectToDraft(
			data,
			(success) => {
				const { message } = success;
				toastSuccess(message);
				setHasDraft(true);
			},
			(fail) => {
				toastError();
				setDisableSubmit(false);
			}
		);
	};

	function createClientProject() {
		setDisableSubmit(true);
    if (!isGuest) {
      clientProjects(
        submittedData,
        (success) => {
          const { isSuccess, data } = success;
          toastSuccess(t("projectCreatedSuccessfully"));
          if (isSuccess) {
            setDisableSubmit(false);
            setIsDisabled(true);
            setTimeOutToUse(
              setTimeout(() => {
                navigate("/profile/projects-room", {
                  state: {
                    porjectId: data.projectId,
                  },
                });
              }, toastTime)
            );
          }
        },
        (fail) => {
          toastError();
          setDisableSubmit(false);
        }
      );
    }
	}

	const onHandOverChange = (checkedValues) => {
		updateHandOverCheckedVals(checkedValues);
	};

	function onChangeParagraphItems(e, id) {
		setParagraphAnswers((prev) => {
			prev[id].answer = e.target.value;

			return { ...prev };
		});
	}

	function deleteProjectDraft() {
		deleteProjectFromDraft(
			(success) => {
				toastSuccess(success.message);
				resetAllData();
			},
			(fail) => {
				toastError();
			}
		);
	}

	function resetAllData() {
		setProjectName("");
		updateExtraComment("");
		// [services] and [service types]
		setselectedServiceID(services[0]._id);
		setServiceTypesId(serviceTypes[0].serviceTypeId);
		// drawings name
		setUploadedDrawingsName(null);
		updateUploadedDrawings([]);
		// handover
		setHandOverOtherAnswer("");
		updateHandOverCheckedVals([]);
		// optionalservices
		setSelecctedOptionalServices([]);
		setIsTakeActionOnService(true);
		setIsTakeActionOnServiceType(true);
		setHasDraft(false);
		// payment , location and status
		setSelectedLocation(null);
		setSelectedPaymentPreferances(null);
		setSelectedProjectStatus(null);
		setProjectArea(null);
	}

	function getQuestionAnswer(answers, questionID) {
		let answerItem = answers.filter(
			(answer) => answer.questionId === questionID
		)[0];
		if (answerItem?.theOtherAnswer?.length) {
			return "other";
		}
		return answerItem?.answerId;
	}

	return (
		<section className="createProject__section main-section with__spinner">
			{isDataLoading ? (
				<div className="spinner__container">
					<Spin className="spinner" />
				</div>
			) : (
				<>
					{hasDraft ? (
						<Button
							type="text"
							className=" createProject__draft-delete"
							onClick={deleteProjectDraft}
						>
							{t("deleteProjectDraft")}
						</Button>
					) : null}

					<div>
						{/* Project Name */}
						<div className=" mb-4 createProject__name">
							<label className="me-1">
								<span className="main-color">{t("projectName")}:</span>
								<span
									className={
										errorState
											? "ms-2 text-danger main-color"
											: "mx-2 main-color"
									}
									style={{
										color: "red",
									}}
								>
									*
								</span>
							</label>
							<Input
								className="underline-input"
								placeholder={t("projectName")}
								value={projectName}
								onChange={(e) => {
									setProjectName(e.target.value);
								}}
							/>
						</div>

						{/* Serivces */}
						<div className="createProject__specialization  space-item">
							{services && selectedServiceID && (
								<Radio.Group
									value={selectedServiceID}
									onChange={changeSelectedServiceId}
									style={{
										width: "100%",
									}}
									key={lang}
								>
									<Row>
										{services.map((service, serviceIndex) => (
											<Col xs={24} md={6} key={serviceIndex}>
												<Radio className="item" value={service._id}>
													{service.name}
												</Radio>
											</Col>
										))}
									</Row>
								</Radio.Group>
							)}
						</div>

						{/* Service Type */}
						<div className="createProject__type space-item">
							{services && (
								<Radio.Group
									name="radiogroup"
									onChange={handleChangeServiceType}
									value={serviceTypesId}
									key={lang}
								>
									<Row gutter={[32, 32]}>
										{serviceTypes.map((type, _) => {
											return (
												<Col xs={12} key={_}>
													<Radio value={type.serviceTypeId}>
														{type.serviceType}
													</Radio>
												</Col>
											);
										})}
									</Row>
								</Radio.Group>
							)}
						</div>

						{/* Service Type Items */}
						<div className="createProject-group space-item">
							<div className="createProject-group__checkbox-group">
								{setHaveGetTheDrafts &&
									services &&
									serviceTypesId &&
									serviceTypeItems && (
										<Radio.Group
											value={selectedServiceTypeItems || null}
											onChange={handleChangeServiceItemId}
											className="mb-3 w-100"
											name="radiogroup"
											key={lang}
										>
											{selectedServiceTypeItems}
											<Row gutter={[8, 8]}>
												{serviceTypeItems.map((item, _) => {
													return (
														<Col xs={24} sm={12} md={6} key={_}>
															<Radio
																className="item"
																value={item.serviceTypeItemId}
															>
																{item.serviceTypeItem}
															</Radio>
														</Col>
													);
												})}
												<div className="d-flex">
													<Radio
														className="item"
														value={"other"}
														defaultChecked={1}
													>
														{t("other")}:{" "}
													</Radio>
													<Input
														disabled={selectedServiceTypeItems !== "other"}
														className="other-input"
														placeholder={t("pleaseSpecify")}
														bordered={false}
														value={otherServiceTypeItems}
														onChange={(e) => {
															updateOtherServiceTypeItems(e.target.value);
														}}
													/>
												</div>
											</Row>
										</Radio.Group>
									)}
							</div>
						</div>

						{/* Start Service Questions */}
						{setHaveGetTheDrafts && servicesQuestions?.length ? (
							<div className="space-item">
								{servicesQuestions.map((servicesQuestion, index) => {
									let answer;
									if (!isTakeActionOnService) {
										answer = getQuestionAnswer(
											questionsAnswersArr,
											servicesQuestion.serviceQuestionId
										);
									} else answer = null;

									return (
										<div className="createProject-group mb-4" key={index}>
											<header className="createProject-group__header">
												<h5 className="createProject-group__header">
													{servicesQuestion.serviceQuestion}{" "}
													{servicesQuestion?.isRequired && (
														<span
															style={{
																color: "red",
															}}
														>
															*
														</span>
													)}
												</h5>
											</header>
											<div className="createProject-group__checkbox-group">
												<Radio.Group
													className="mb-3 w-100"
													name="radiogroup"
													defaultValue={answer}
													onChange={(e) =>
														handleChangeQuestionAnswer(
															servicesQuestion.serviceQuestionId,
															e.target.value
														)
													}
													key={isTakeActionOnService || lang}
												>
													<Row gutter={[0, 16]}>
														{servicesQuestion?.servicesAnswers?.length
															? servicesQuestion?.servicesAnswers.map(
																	(question, _) => (
																		<Col xs={24} sm={12} md={6} key={_}>
																			<Radio
																				className="item"
																				value={question.answerId}
																				id={servicesQuestion.serviceQuestionId}
																			>
																				{question.answer}
																			</Radio>
																		</Col>
																	)
															  )
															: null}
														{servicesQuestion.hasOtherAnswer && (
															<Col>
																<Radio className="item" value="other">
																	<div className="d-flex align-items-center checkbox-group createProject-group__other">
																		<span className="other-span me-2">
																			{t("other")}:
																		</span>
																		<Input
																			key={lang}
																			disabled={
																				questionsAnswersArr.find(
																					(item) =>
																						item.questionId ===
																						servicesQuestion.serviceQuestionId
																				)?.answerKey !== "other"
																			}
																			bordered={false}
																			className="other-input"
																			placeholder={t("pleaseSpecify")}
																			name={servicesQuestion.serviceQuestionId}
																			value={
																				questionsAnswersArr.find(
																					(item) =>
																						item.questionId ===
																						servicesQuestion.serviceQuestionId
																				)?.theOtherAnswer
																			}
																			onChange={(e) => {
																				let questionsAnswersArrVar = [
																					...questionsAnswersArr,
																				];
																				questionsAnswersArr.find(
																					(item) =>
																						item.questionId ===
																						servicesQuestion.serviceQuestionId
																				).theOtherAnswer = e.target.value;
																				updateQuestionsAnswersArr(
																					questionsAnswersArrVar
																				);
																			}}
																		/>
																	</div>
																</Radio>
															</Col>
														)}
													</Row>
												</Radio.Group>
											</div>
										</div>
									);
								})}
							</div>
						) : null}

						{/* ProjectArea && ProjectAdress && ProjectLocation */}
						<Form className="project-inputs space-item">
							<Row gutter={[8, 8]} key={lang}>
								<Col xs={24} md={8}>
									<Form.Item
										className=" d-flex flex-column"
										style={{
											flexDirection: "column",
										}}
										label={
											<>
												<>{t("exactProjectArea")}</>
												<sup
													style={{
														color: "red",
														marginInlineStart: ".5rem",
													}}
												>
													*
												</sup>
											</>
										}
										colon={false}
										requiredMark
										rules={[
											{
												required: true,
												message: t("thisFieldIsRequired"),
											},
										]}
									>
										<div className="d-flex align-items-center mx-2">
											<Input
												type={"number"}
												key={lang}
												placeholder={t("projectArea")}
												className="form-underline"
												bordered={false}
												value={projectArea}
												min="1"
												onBlur={() => {
													if (!Number(projectArea)) setProjectArea(null);
													if (Number(projectArea) && projectArea <= 0)
														setProjectArea(1);
												}}
												onChange={(e) => {
													// if (!Number(e.target.value)) return;
													if (e.target.value !== "-") {
														setProjectArea(e.target.value);
													}
												}}
											/>
											<span className="main-color text-bold">
												{t("m")}
												<sup>2</sup>
											</span>
										</div>
									</Form.Item>
								</Col>
								<Col xs={24} md={8} />
								<Col xs={24} md={8}>
									<Select
										key={lang || isTakeActionOnService}
										className="createProject__select-box"
										placeholder={t("projectLocation")}
										allowClear
										value={selectedLocation}
										onChange={(value) => {
											setSelectedLocation(value);
											handleChangeQuestionAnswer(
												locationList.serviceQuestionId,
												value
											);
										}}
										listHeight={200}
										virtual={false}
									>
										{locationList &&
											locationList.servicesAnswers.map((location, _idx) => {
												return (
													<Option value={location.answerId} key={_idx}>
														{location.answer}
													</Option>
												);
											})}
									</Select>
								</Col>
							</Row>
						</Form>

						{/* Paragraphs Questions */}
						<div className="createProject__paragraphs">
							<Row gutter={[32, 16]}>
								{paragraphQuestions.length
									? paragraphQuestions.map((item, _idx) => (
											<Col xs={24} sm={12} md={8} key={_idx}>
												<div className="paragraph__container">
													<label htmlFor={item._id}>
														{item.paragraphQuestion}
														<sup
															style={{
																color: "red",
															}}
														>
															{item.isRequired && "*"}
														</sup>
													</label>
													<Input
														key={lang}
														id={item._id}
														bordered={false}
														maxLength={item.textMaxSize}
														value={paragraphAnswers[item._id]?.answer}
														onChange={(e) =>
															onChangeParagraphItems(e, item._id)
														}
														placeholder={t("pleaseSpecify")}
													/>
													<small>
														{t("maxNumberOfCharactersIs")}
														{item.textMaxSize}
													</small>
												</div>
											</Col>
									  ))
									: null}
							</Row>
						</div>

						{/* Handover Requiremets */}
						{handoverReqOptions.length > 0 && (
							<div className="space-item">
								<h5 className="createProject-group__header mb-3">
									{t("handoverRequirements")}{" "}
									<span
										style={{
											color: "red",
										}}
										className={errorState ? "mx-1 text-danger" : "mx-1"}
									>
										*
									</span>
								</h5>
								<Checkbox.Group
									key={lang}
									value={handOverCheckedVals}
									onChange={onHandOverChange}
								>
									<Row gutter={[32, 8]}>
										{handoverReqOptions.map((option, _idx) => {
											return (
												<Col xs={24} sm={12} md={6} key={_idx}>
													<Checkbox key={lang} value={option.value}>
														{option.label}
													</Checkbox>
												</Col>
											);
										})}
										<Col xs={24} md={false}>
											<Checkbox key={lang} value="other">
												<div className="d-flex align-items-center checkbox-group createProject-group__other">
													<span>{t("other")}: </span>
													<Input
														key={lang}
														className="other-input"
														placeholder={t("pleaseSpecify")}
														value={handOverOtherAnswer}
														onChange={(e) =>
															setHandOverOtherAnswer(e.target.value)
														}
														disabled={
															!(handOverCheckedVals.indexOf("other") > -1)
														}
													/>
												</div>
											</Checkbox>
										</Col>
									</Row>
								</Checkbox.Group>
							</div>
						)}

						{/* Project Status, Payment Preferences && Upload Drawings */}
						<Row gutter={[18, 18]} className="space-item">
							<Col xs={24} sm={12} md={8}>
								<div>
									<Select
										key={lang || isTakeActionOnService}
										placeholder={t("projetStatus")}
										value={selectedProjectStatus}
										className="createProject__select-box"
										allowClear
										onChange={(value) => {
											setSelectedProjectStatus(value);
											handleChangeProjectStatus(value);
										}}
									>
										{projectStatusList?.map((status, _idx) => {
											return (
												<Select.Option key={_idx} value={status._id}>
													{status.projectStatus}
												</Select.Option>
											);
										})}
									</Select>
								</div>
							</Col>
							<Col xs={24} sm={12} md={8}>
								<div>
									<Select
										key={lang || isTakeActionOnService}
										value={selectedPaymentPreferances}
										placeholder={t("paymentPreferences")}
										className="createProject__select-box"
										allowClear
										onChange={(value, e) => {
											setSelectedPaymentPreferances(value);
											handleChangeQuestionAnswer(
												paymentList.serviceQuestionId,
												value
											);
										}}
									>
										{paymentList &&
											paymentList.servicesAnswers.map((payment, _) => {
												return (
													<Select.Option value={payment.answerId} key={_}>
														{payment.answer}
													</Select.Option>
												);
											})}
									</Select>
								</div>
							</Col>
							<Col xs={24} sm={24} md={8}>
								<div className="createProject__upload-drawings">
									<Button
										className="btn"
										type="primary"
										loading={uploadDrawingsLoading}
										disabled={uploadedDrawingsName}
									>
										<input
											type="file"
											className="d-none"
											id="drawingDocuments"
											onInput={handleUploadDoc}
											onClick={(event) => {
												event.target.value = null;
											}}
										/>
										<label
											htmlFor="drawingDocuments"
											className="createProject__upload-label"
										>
											{t("uploadDrawings")}
											<img
												className="mx-2"
												src={FileUploadImg}
												alt="upload img"
											/>
										</label>
									</Button>

									<div
										className="filename"
										style={{
											opacity: uploadedDrawingsName ? 1 : 0,
										}}
									>
										<OverlayTrigger
											placement="bottom"
											overlay={
												<Tooltip id="button-tooltip-2">
													{uploadedDrawingsName}
												</Tooltip>
											}
										>
											<div className="text" data-title={uploadedDrawingsName}>
												{uploadedDrawingsName}
											</div>
										</OverlayTrigger>

										<div
											role={"button"}
											className="btn main-color filename__remove"
											onClick={() => {
												setUploadedDrawingsName(null);
												updateUploadedDrawings([]);
											}}
										>
											x
										</div>
									</div>
								</div>
							</Col>
						</Row>

						{/* disclaimer  */}

						<div className="disclamer space-item">
							<p>{t("disclaimer")}:</p>
							{isArray(Disclamer) && Disclamer?.length ? (
								Disclamer?.map((item, _idx) =>
									item.length ? (
										<p key={_idx}>
											{"- "}
											{item}
										</p>
									) : (
										""
									)
								)
							) : (
								<p>{Disclamer}</p>
							)}
						</div>

						{/* extra-comment  */}
						<div className="extra-comment space-item">
							<label htmlFor="extra-comment">
								{t("extraComment")}
								{": "}
							</label>
							<Input.TextArea
								key={lang}
								id="extra-comment"
								className="mt-2"
								placeholder={t("typeYourExtraCommentHere")}
								style={{
									resize: "none",
								}}
								value={extraComment}
								onChange={(e) => {
									updateExtraComment(e.target.value);
								}}
							/>
						</div>

						{/* optional answers  */}
						<div className="optional my-3 space-item">
							<h2 className="title d-flex align-items-center py-2">
								<span className="title-desc">
									<span className="mx-1 main-color">
										{t("optionalAddionalConsultationServicesProvidedBy")}
									</span>
									<Link to="/">
										<img src={Logo} alt="Bidster" />
									</Link>
								</span>
							</h2>
							{optionalServices.length ? (
								<Checkbox.Group
									key={lang}
									value={selecctedOptionalServices}
									onChange={(data) => {
										setSelecctedOptionalServices(data);
									}}
								>
									{optionalServices.map((item, _) => (
										<div key={_}>
											<Checkbox value={item._id}>
												<h6 className="ms-2">{item.optionalService}</h6>
											</Checkbox>
											<br />
										</div>
									))}
								</Checkbox.Group>
							) : null}
						</div>
					</div>

					<div className="createProject__btn-group my-4">
						{ !isGuest &&
            <Button
							type="primary"
							style={{
								width: "131px",
								height: "42px",
							}}
							className="draft-btn"
							// loading={disableSubmit}
							disabled={isDisabled}
							onClick={createProjectDraft}
						>
							{t("saveAsDraft")}
						</Button>
          }
						<Button
							type="primary"
							style={{
								width: "131px",
								height: "42px",
							}}
							loading={disableSubmit}
							disabled={isDisabled}
							onClick={handleSubmit}
						>
							{t("submit")}
						</Button>
					</div>
				</>
			)}

			{confirmModalState && isGuest ? 
        <AuthModal guestProject={submittedData} setOpenModal={toggleConfirmModalState}/> 
      : (
				<ModalComp
					visible={confirmModalState}
					onCancel={() => {
						toggleConfirmModalState(false);
					}}
					title={t("confirmation")}
					desc={t("areYouSureYouWantToSubmit")}
					subDesc={t("ifYouSubmitThisProject")}
					onConfirm={createClientProject}
					confirmBtn={t("submit")}
					cancelBtn={t("cancel")}
					disableSubmit={disableSubmit}
				/>
			)}
		</section>
	);
};

export default CreateNewProject;
