import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
import cameraIcon from "assets/icons/cameraIcon.svg";
import { useTranslation } from "react-i18next";

const ImageUploader = ({ handelUploadImg, imgSrc }) => {
  const { t } = useTranslation();
  return (
    <ImgCrop
      modalCancel={t("cancel")}
      modalTitle={t("changeImage")}
      modalOk={t("submit")}
      aspect={1}
      shape="round"
      grid
    >
      <Upload onChange={handelUploadImg} showUploadList={false}>
        <img src={imgSrc} alt="userAvatar" style={{ position: "absolute", top: "0", left: "0" }} />
        <img src={cameraIcon} alt="upload-img" className="camera-icon-upload" />
      </Upload>
    </ImgCrop>
  );
};

export default ImageUploader;
