import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
// import HeroBanner from "assets/videos/hero-banner.mp4";
import { getBanner } from "modules/Home/network";
import { useTranslation } from "react-i18next";
import { baseUrl } from "services";
import "./Hero.scss";

const Hero = ({ id, isMobile }) => {
	const { t, i18n } = useTranslation();
	const [sectionData, updateSectionData] = useState(null);
	const [titleArr, updateTitleArr] = useState(null);
	const [textArr, updateTextArr] = useState(null);
	const [media, updateMedia] = useState(null);
	const [isPlaying, setIsPlaying] = useState(true);
	useEffect(() => {
		// unmount video
		return () => {
			setIsPlaying(false);
		};
	}, []);
	console.log("isMobile", isMobile);
	useEffect(() => {
		getBanner(
			(success) => {
				updateSectionData(success.data[0]);
			},
			(fail) => {}
		);
	}, []);

	useEffect(() => {
		if (sectionData) {
			updateTitleArr(sectionData?.title[i18n.language].split("***"));
			updateTextArr(sectionData?.text[i18n.language]);
			updateMedia(sectionData?.image[i18n.language]);
		}
	}, [i18n.language, sectionData]);

	const urlReplacement = (oldURL) => encodeURI(oldURL)?.replace(/%5C/g, "/");

	const backgroundBanner = {
		backgroundImage: `url(${urlReplacement(baseUrl + media)})`,
		backgroundSize: "cover",
		backgroundPosition: "center",
	};

	return (
		isPlaying && (
			<section id={id} className="home__hero">
				<div className="container home__hero--container">
					<div className="home__hero--content">
						<header className="home__hero--header">
							<h2>
								{titleArr?.map((title, index) => {
									const modifiedTitle = title.replace("*", "\t").split("/n");
									if (modifiedTitle.length > 1) {
										return (
											<span
												key={index}
												style={{ whiteSpace: "pre-wrap" }}
												className={`${index % 2 && "main-color"}`}>
												{modifiedTitle.map((modTitle, modIndex) => {
													return (
														<>
															<br />
															{modTitle
																.replaceAll("*", "     ")
																.replace("***", "")}
														</>
													);
												})}
											</span>
										);
									} else {
										return (
											<span
												style={{ whiteSpace: "pre-wrap" }}
												className={`${index % 2 && "main-color"}`}
												key={index}>
												{title.replaceAll("*", "     ").replace("***", "")}
											</span>
										);
									}
								})}
							</h2>
						</header>
						<p>{textArr}</p>
						<div className="primary-bid-btn d-flex justify-content-start">
							<Button type="primary" className="mt-3">
								<Link
									to={`${"/profile/create-project?role=Property owner"}`}
									className="streched-link">
									{t("kickOffProject")}
								</Link>
							</Button>
						</div>
					</div>

					{media?.includes("-image-") ? (
						<div className="home__hero--video" style={backgroundBanner}></div>
					) : (
						<video
							className="home__hero--video"
							autoPlay={!isMobile}
							muted
							loop
							key={media}>
							<source src={urlReplacement(baseUrl + media) + "#t=0.001"} />
						</video>
					)}
				</div>
			</section>
		)
	);
};

export default Hero;
