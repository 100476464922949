import { useState } from "react";
import { Form, Input, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changeUserData } from "store/authorization";
import ImageUploader from "modules/Profiles/components/Content/components/Shared/ImageUploader/ImageUploader";
import "./EditProfile.scss";
import { useEffect } from "react";

const EditProfile = ({
	accountType,
	sumbitEditProfileDetails,
	userData,
	setUserData,
	handelUploadImg,
	imgSrc,
	isLoading,
}) => {
	const [save, setSave] = useState(false);
	const { userName, bio, website, phone, email, address } = userData;
	const { setUserName, setBio, setWebsite, setPhone, setEmail, setAddress } =
		setUserData;
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		if (save) {
			sumbitEditProfileDetails();
			setSave(false);
		}
	}, [save]);

	function onFinish(value) {
		const { name, phone, website, address, bio, email } = value;
		setUserName(name);
		if (accountType === "pro") {
			setBio(bio);
			setWebsite(website);
			setAddress(address);
			setPhone(phone);
			setEmail(email);
		}

		setSave(true);

		let [firstName, lastName] = userName.split(" ");
		dispatch(
			changeUserData({
				firstName,
				lastName,
				name: firstName + " " + lastName,
				website,
				phone,
				email,
				address,
				bio,
			})
		);
	}

	return (
		<Form
			onFinish={onFinish}
			initialValues={{
				name: userName,
				bio,
				website,
				phone,
				email,
				address,
			}}
		>
			<div className="edit-profile">
				<figure className="sidebar__figure edit-profile__figure">
					<ImageUploader
						handelUploadImg={handelUploadImg}
						imgSrc={imgSrc}
						shape="round"
					/>
				</figure>
				<div className="edit-profile__form">
					<Form.Item
						className="form__item w-100"
						name="name"
						rules={[
							{
								required: true,
								message: t("pleaseProvideYourName"),
							},
							{
								min: 3,
								message: t("provideValidName"),
							},
							// {
							//   pattern: new RegExp(/\w+\s\w+/),
							//   message: t("pleaseProvideLastName"),
							// },
						]}
					>
						<Input
							placeholder={
								accountType === "pro" ? t("companyProName") : t("yourName")
							}
							className="form-underline"
							bordered={false}
						/>
					</Form.Item>
					{accountType === "pro" && (
						<>
							<Form.Item className="form__item w-100" name="bio">
								<Input
									id="bio"
									placeholder={t("writeYourBio")}
									style={{
										direction: "ltr",
									}}
									className="form-underline"
									bordered={false}
								/>
							</Form.Item>
							<Form.Item
								className="form__item w-100"
								name="website"
								colon={false}
							>
								<Input
									id="website"
									placeholder={t("writeYourWebsite")}
									style={{
										direction: "ltr",
									}}
									className="form-underline"
									bordered={false}
								/>
							</Form.Item>
							<Form.Item
								className="form__item w-100"
								name="phone"
								rules={[
									{
										required: true,
										message: t("providePhoneNumber"),
									},
								]}
							>
								<Input
									id="phone"
									placeholder="+2 0100 0000 000"
									style={{
										direction: "ltr",
									}}
									className="form-underline"
									bordered={false}
									type="number"
								/>
							</Form.Item>
							<Form.Item
								className="form__item w-100"
								name="email"
								rules={[
									{
										required: true,
										message: t("provideYourEmail"),
									},
									{
										type: "email",
										message: t("provideValidEmail"),
									},
								]}
							>
								<Input
									placeholder="Name@email.com"
									style={{
										direction: "ltr",
									}}
									className="form-underline"
									bordered={false}
								/>
							</Form.Item>
							<Form.Item className="form__item w-100" name="address">
								<Input
									placeholder={t("yourAddress")}
									className="form-underline"
									bordered={false}
								/>
							</Form.Item>
						</>
					)}

					<div className="primary-bid-btn edit-profile__btn">
						<Form.Item>
							<Button
								loading={isLoading}
								type="primary"
								className="btn"
								htmlType="submit"
							>
								{t("saveChanges")}
							</Button>
						</Form.Item>
					</div>
				</div>
			</div>
		</Form>
	);
};

export default EditProfile;
