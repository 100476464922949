// import { Rating } from "react-simple-star-rating";
// import StarIcon from "assets/icons/jsx-icons/star-icon.jsx";
import { StarOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Rate } from "antd";
import "./SharedRating.scss";

const SharedRating = ({ id, readOnly, value, onChange, className }) => {
	// eslint-disable-next-line
	const [ratingVal, setRatingVal] = useState(value);

	useEffect(() => {
		setRatingVal(value);
	}, [value]);

	function handleRating(rate) {
		if (!readOnly) {
			setRatingVal(rate);
			onChange(id, rate);
		}
	}

	return (
		<>
			{/* <Rating
				rtl={lang === "ar"}
				initialValue={value}
				onClick={handleRating}
				readonly={readOnly}
				// customIcons={customIcons}
				// emptyClassName="empty_icon"
				className={className}
				fillColor="#066"
				size={fontSize}
				allowHover
				transition
			/> */}
			{/* value, onClick, readOnly, allowHover, transition */}
			<Rate
				style={{
					color: "#12819c",
					border: "none",
				}}
				value={ratingVal}
				disabled={readOnly}
				character={
					<StarOutlined
						style={{
							transform: "scale(0.95)",
						}}
					/>
				}
				onChange={handleRating}
				className={className}
				allowHalf
			/>
		</>
	);
};

export default SharedRating;
