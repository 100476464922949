import { useState, useEffect } from "react";
import FAQ from "./components/FAQ/FAQ";
import Hero from "./components/Hero/Hero";
import InTouch from "./components/InTouch/InTouch";
import HowItWorks from "./components/HowItWorks/HowItWorks";
import OurFeatures from "./components/OurFeatures/OurFeatures";
import HomeServices from "./components/HomeServices/HomeServices";
import TermsToUse from "./components/GestTermsAndConditionsModal/TermsToUse";
import { useTranslation } from "react-i18next";
import "./Home.scss";

import { navigators } from "./home-navigator";

const Home = () => {
	const { t } = useTranslation();
	const [
		isGuestTermsAndConditionsVisible,
		setIsGuestTermsAndConditionsVisible,
	] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isPlaying, setIsPlaying] = useState(true);
	const [isMobile, setIsMobile] = useState(false);

	const handleResize = () => {
		if (window.innerWidth < 720) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	useEffect(() => {
		handleResize();
		window.addEventListener("load", () => setIsLoading(false));
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("load", () => setIsLoading(false));
			window.removeEventListener("resize", handleResize);
			setIsPlaying(false);
		};
	}, []);

	return (
		isPlaying && (
			<>
				<main>
					<Hero id={navigators.main} isMobile={isMobile} />
					<OurFeatures id={navigators.ourFeatures} />
					<HomeServices id={navigators.homeSerivce} />
					<HowItWorks id={navigators.howItWorks} isMobile={isMobile} />
					<InTouch id={navigators.contactUs} />
					<FAQ id={navigators.faq} />
					<footer className="home__footer">
						<div className="container footer-container">
							<p
								role="button"
								onClick={() => setIsGuestTermsAndConditionsVisible(true)}>
								{t("termsOfUse")}
							</p>
							<p>All Rights Reserved &copy; Bidster.me</p>
						</div>
					</footer>
				</main>
				{isGuestTermsAndConditionsVisible && (
					<TermsToUse
						visible={isGuestTermsAndConditionsVisible}
						onCancel={() => setIsGuestTermsAndConditionsVisible(false)}
					/>
				)}
			</>
		)
	);
};

export default Home;
