import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Checkbox, Spin } from "antd";
import { toastError, toastSuccess } from "helpers/toasterFun";
import { useTranslation } from "react-i18next";
import {
	deleteNotifyProject,
	numberOfProjectsPerStatus,
	getProjectBasedForStatus,
} from "modules/Profiles/network";
import { chageNumberOfNewProjects } from "store/authorization";

import { isArray } from "lodash";
import ModalComp from "modules/Shared/Modal/ModalComp";
import ProjectsRoomCard from "../../ProjectsRoomCard/ProjectsRoomCard";
import ViewProjectsRoomCard from "../../ProjectsRoomCard/ProjectsRoomCard";

import "./ProProjectsRoom.scss";

const ProProjectsRoom = () => {
	const { t } = useTranslation();
	const [checkboxes, setCheckboxes] = useState([
		{
			status: 1,
			name: "newProjects",
		},
		{
			status: 2,
			name: "bids",
		},
		{
			status: 3,
			name: "shortlisted",
		},
		{
			status: 4,
			name: "quotationSubmitted",
		},
	]);

	const [modalVisibility, setModalVisibility] = useState(false);
	const [projectNotifyId, setProjectNotifyId] = useState(null);
	const [projects, setProjects] = useState(null);
	const [projectToView, setProjectToView] = useState(null);
	const [checkedList, setCheckedList] = useState([1, 2, 3, 4]);
	const [isDataLoading, setIsDataLoading] = useState(true);
	const { lang } = useSelector((state) => state.lang);
	const {
		authorization: { numberOfNewProjects },
	} = useSelector((state) => state.authorization);
	const dispatch = useDispatch();

	useEffect(() => {
		setIsDataLoading(true);
		changeSelectedCheckbox([1, 2, 3, 4]);
		getProjectsCount();
		// eslint-disable-next-line
	}, [lang]);

	function getProjectsCount() {
		numberOfProjectsPerStatus(
			lang,
			(success) => {
				setIsDataLoading(false);
				if (success.data?.length) {
					success.data.forEach(({ status, count }) => {
						if (status < 5) {
							setCheckboxes((prev) => {
								prev[status - 1].count = count;
								return [...prev];
							});
						}
					});
				}
			},
			(fail) => {
				setIsDataLoading(false);
				if (fail?.data) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	}

	function handleDeleteProject(projectId) {
		setProjectNotifyId(projectId);
		setModalVisibility(true);
	}

	function confirmDeleteProject() {
		deleteNotifyProject(
			projectNotifyId,
			(success) => {
				setProjects((prevState) =>
					prevState.filter((project) => project.notifyId !== projectNotifyId)
				);
				setModalVisibility(false);
				setProjectNotifyId(null);
				toastSuccess(t("projectDeletedSuccessfully"));
				dispatch(chageNumberOfNewProjects(numberOfNewProjects - 1));
				getProjectsCount();
			},
			(fail) => {
				if (fail.data) {
					toastError(fail?.data?.error);
				} else {
					toastError(fail.message);
				}
			}
		);

		setModalVisibility(false);
		setProjectNotifyId(null);
	}

	function changeSelectedCheckbox(checked) {
		setCheckedList(checked);
		if (checked.length) {
			getProjectBasedForStatus(
				lang,
				checked,
				(success) => {
					const { isSuccess, data } = success;
					if (isSuccess) setProjects(data);
					else toastError(success.error);
				},
				(fail) => {
					if (fail.data) {
						toastError(fail?.data?.error);
					} else {
						toastError();
					}
				}
			);
		} else {
			setProjects([]);
		}
	}
	return (
		<section className="projects-room  projects__room main-section with__spinner">
			{isDataLoading ? (
				<div className="spinner__container">
					<Spin className="spinner" />
				</div>
			) : (
				<>
					{!projectToView ? (
						<>
							<Checkbox.Group
								defaultValue={checkedList}
								onChange={changeSelectedCheckbox}
								className="w-100 mb-3"
							>
								<Row className="new-project__service--type w-100">
									{checkboxes.map((projectType, _idx) => (
										<Col className="mb-2" xs={24} md={6} key={_idx}>
											<span>
												<Checkbox value={projectType?.status}>
													<div className="new-project__service--checkbox">
														{t(projectType?.name)}
														<sup
															className="sup-count mx-1"
															style={{
																color: "red",
																fontSize: "0.8rem",
																fontWeight: "bold",
															}}
														>
															{projectType?.count}
														</sup>
													</div>
												</Checkbox>
											</span>
										</Col>
									))}
								</Row>
							</Checkbox.Group>

							{projects?.length ? (
								<>
									{projects.map((project, i) => (
										<ProjectsRoomCard
											project={project}
											handleDeleteProject={handleDeleteProject}
											key={i}
										/>
									))}
								</>
							) : null}

							{isArray(projects) && !projects.length ? (
								<div
									style={{
										height: "100%",
									}}
									className="d-flex justify-content-center align-items-center text-empty flex-1"
								>
									{t("thereAreNoProjects")}
								</div>
							) : null}
						</>
					) : (
						<ViewProjectsRoomCard
							projectToView={projectToView}
							setProjectToView={setProjectToView}
						/>
					)}
				</>
			)}

			{modalVisibility && (
				<ModalComp
					visible={modalVisibility}
					onCancel={() => setModalVisibility(false)}
					onConfirm={confirmDeleteProject}
					title={t("deleteProject")}
					desc={t("areYouSureYouWantToDeleteProject")}
					confirmBtn={t("delete")}
					cancelBtn={t("cancel")}
				/>
			)}
		</section>
	);
};

export default ProProjectsRoom;
