import { Tabs } from "antd";
import RatingAndReviews from "modules/Profiles/components/Content/components/InnerContent/components/RatingAndReviews/RatingAndReviews";
import { getDownloadedOfferPath } from "modules/Profiles/network";
import { baseUrl } from "services";
import { downloadZip } from "client-zip";
import { Spin, Button, Tooltip } from "antd";
import "./clientViewConent.scss";
import ClientShowProProjects from "modules/Profiles/components/Content/components/InnerContent/components/ShowProProjects/ClientShowProProjects";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const { TabPane } = Tabs;

const CientViewInnerContent = ({
	professionalProjects,
	projectId,
	proId,
	clientStatus,
	isPortofolioLoading,
}) => {
	const [offerPath, setOfferPath] = useState([]);
	const [isRatingLoading, setIsRatingLoading] = useState(false);
	const { t } = useTranslation();

	useEffect(() => {
		getDownloadedOfferPath(
			{ proId, projectId },
			(success) => {
				if (success.data && success.data?.length) {
					setOfferPath([success.data]);
				} else {
					setOfferPath([success.data]);
				}
			},
			(fail) => {}
		);
	}, [projectId, proId]);

	async function handleDownloadDrawings() {
		async function getAllPaths() {
			const arr = [];
			await Promise.all(
				offerPath.map(async (imgPath) => {
					let data = await fetch(baseUrl + imgPath);
					arr.push(data);
					return arr;
				})
			);
			return arr;
		}
		const blob = await downloadZip([...(await getAllPaths())]).blob();
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		link.download = "offer.zip";
		link.click();
		link.remove();
	}

	return (
		<div className="clientView client-view">
			<section className="content h-100">
				<div className="navigation">
					<div className="wrapper">
						<div
							className="tabs-container "
							style={{
								position: "relative",
							}}
						>
							<Tabs
								defaultActiveKey="portfolio"
								className="nav-tab flex-1"
								type="card"
								style={{
									marginInline: "0.35rem",
								}}
							>
								<TabPane
									tab={t("portofolio")}
									key="portfolio"
									className="with__spinner"
								>
									{isPortofolioLoading ? (
										<div className="spinner__container">
											<Spin className="spinner" />
										</div>
									) : professionalProjects?.length ? (
										<ClientShowProProjects selectedProId={proId} />
									) : (
										<div
											style={{
												height: "100%",
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
										>
											<div className="text-empty">
												{t("proDontHaveProjectsYet")}
											</div>
										</div>
									)}
								</TabPane>
								<TabPane
									tab={t("ratingAndReviews")}
									key="rating-reviews"
									className="with__spinner"
								>
									{isRatingLoading ? (
										<div className="spinner__container">
											<Spin className="spinner"></Spin>
										</div>
									) : (
										<RatingAndReviews
											setIsRatingLoading={setIsRatingLoading}
											proId={proId}
											mode={"clientView"}
										/>
									)}
								</TabPane>
							</Tabs>
							<div className="download-project-images">
								{offerPath?.length && !offerPath[0].length ? (
									<Tooltip title={t("proIsPreparingQuotaion")}>
										<Button
											type="primary"
											onClick={handleDownloadDrawings}
											disabled={offerPath?.length && !offerPath[0].length}
											style={{
												height: "40px",
											}}
										>
											{t("downloadQuotation")}
										</Button>
									</Tooltip>
								) : (
									<Button
										type="primary"
										onClick={handleDownloadDrawings}
										disabled={offerPath?.length && !offerPath[0].length}
										style={{
											height: "40px",
										}}
									>
										{t("downloadQuotation")}
									</Button>
								)}
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
};

export default CientViewInnerContent;
