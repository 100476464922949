import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ViewServiceTypePreferances from "./ViewServiceTypePreferances/ViewServiceTypePreferances";
import EditServiceTypePreferances from "./EditServiceTypePreferances/EditServiceTypePreferances";

const ServiceTypePreferances = ({ setSerivceName }) => {
  const [hasServiceType, setHasServiceType] = useState([]);
  const [servicesToEdit, setServicesToEdit] = useState(null);
  const { authorization } = useSelector((state) => state.authorization);

  useEffect(() => {
    setHasServiceType(authorization.hasServices?.length);
  }, [authorization.hasServices.length]);

  return (
    <>
      {hasServiceType ? (
        <ViewServiceTypePreferances
          setHasServiceType={setHasServiceType}
          setServicesToEdit={setServicesToEdit}
        />
      ) : (
        <EditServiceTypePreferances
          setHasServiceType={setHasServiceType}
          servicesToEdit={servicesToEdit}
          serviceTypePreferances={servicesToEdit}
          setServiceName={setSerivceName}
        />
      )}
    </>
  );
};

export default ServiceTypePreferances;
