import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "modules/Profiles/components/Sidebar/Sidebar";
import { Col, Row } from "antd";
import { useSearchParams } from "react-router-dom";
import {
	getClientProfileDetails,
	getProffesionalProjects,
	// getProAvRate,
} from "modules/Profiles/network";
import ClientViewInnerContent from "./components/CientViewInnerContent";

export default function ClientViewProfile() {
	// eslint-disable-next-line
	const [searchParams, updateSearchParams] = useSearchParams();
	const proId = searchParams.get("ProId");
	const projectId = searchParams.get("ProjectId");
	const [clientProfileDetails, setClientProfileDetails] = useState(null);
	const [professionalProjects, setProfessionalProjects] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const location = useLocation();

	useEffect(() => {
		if (proId) {
			setIsLoading(true);
			let data = { proId };
			getClientProfileDetails(
				data,
				(success) => {
					setClientProfileDetails(success.data);
					setIsLoading(false);
				},
				(fail) => {}
			);

			getProffesionalProjects(
				data,
				(success) => {
					setProfessionalProjects(success.data);
				},
				(fail) => {}
			);
		}
	}, [proId, projectId]);

	return (
		<div className="clientViewProfil" gutter={[24, 16]}>
			<Row className="mt-4">
				<Col xs={24} lg={7} className="px-4">
					<Sidebar
						mode={"clientView"}
						clientProfileDetails={clientProfileDetails}
						projectId={projectId}
						proId={proId}
						clientStatus={location?.state?.data?.status}
					/>
				</Col>
				<Col xs={24} lg={15}>
					<ClientViewInnerContent
						mode={"clientView"}
						projectId={projectId}
						professionalProjects={professionalProjects}
						isPortofolioLoading={isLoading}
						proId={proId}
						clientStatus={location?.state?.data?.status}
					/>
				</Col>
			</Row>
		</div>
	);
}
