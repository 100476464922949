import { getResource } from "services";

export function getBlogs(onSuccess, onFail) {
	const path = "api/blog";
	getResource(path, onSuccess, onFail);
}

export function getBlogCategories(onSuccess, onFail) {
	const path = "api/blogCategories";
	getResource(path, onSuccess, onFail);
}
export function getArticles(data, onSuccess, onFail) {
	const { categoryId } = data;
	const path = `api/articles/getArticle?categoryId=${categoryId}`;
	getResource(path, onSuccess, onFail);
}
export function getArticleById(data, onSuccess, onFail) {
	const { articleId } = data;
	const path = `api/articles/getArticleById?articleId=${articleId}`;
	getResource(path, onSuccess, onFail);
}
