import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import {
	changeAuthCoins,
	changeAuthImg,
	changeUserData,
} from "store/authorization";
import { useSearchParams } from "react-router-dom";
import { baseUrl } from "services";
import {
	editUserProfileData,
	getUserProfileData,
	updateProjectStatus,
	uploadProfileImage,
} from "../../network";
// images
import userAvatar from "assets/images/Group 2354.svg";
import EditProfile from "./Components/EditProfile/EditProfile";
import ViewProfile from "./Components/ViewProfile/ViewProfile";
import { toastError, toastSuccess } from "helpers/toasterFun";
import BuyTokenModal from "./../Content/components/InnerContent/components/ViewProjectsRoomCard/components/BuyTokenModal/BuyTokenModal";
import SharedRating from "modules/Shared/SharedRating/SharedRating";
import "./Sidebar.scss";

const Sidebar = ({
	mode,
	clientProfileDetails,
	handleNavigateToCreate,
	projectId,
	proId,
	clientStatus,
	service,
	isChangeToken,
}) => {
	const [showEdit, setShowEdit] = useState(false);
	const [rating, setRating] = useState(null);
	const [imgSrc, setImgSrc] = useState(userAvatar);
	const [userName, setUserName] = useState("");
	const [bio, setBio] = useState(null);
	const [coins, setCoins] = useState(null);
	const [website, setWebsite] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [address, setAddress] = useState("");
	const [serviceName, setServiceName] = useState(null);
	const [buyTokenModalVisibility, setBuyTokenModalVisibility] = useState(false);
	const [websiteToView, setWebsiteToView] = useState("");
	const [phoneToView, setPhoneToView] = useState("");
	const [userNameToView, setUserNameToView] = useState("");
	const [emailToView, setEmailToView] = useState("");
	const [addressToView, setAddressToView] = useState("");
	const [serviceTypeToView, setServiceTypeToView] = useState("");
	const [ratingToView, setRatingToView] = useState(null);
	const [imgSrcToView, setImgSrcToView] = useState(userAvatar);
	const [bioToView, setBioToView] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const { lang } = useSelector((state) => state.lang);
	const { t } = useTranslation();
	const [params] = useSearchParams();
	const { authorization } = useSelector((state) => state.authorization);
	const userRole = authorization.role;
	const dispatch = useDispatch();

	const userData = {
		userName,
		bio,
		website,
		phone,
		email,
		address,
		coins,
		serviceName,
		rating,
	};

	const setUserData = {
		setUserName,
		setBio,
		setWebsite,
		setPhone,
		setEmail,
		setAddress,
		setCoins,
	};

	useEffect(() => {
		console.log("change token hi there ");
		if (params.get("ProId") && params.get("ProjectId")) {
			if (mode === "clientView" && clientProfileDetails) {
				setRatingToView(clientProfileDetails.averageRating);
				setImgSrcToView(
					clientProfileDetails.imgPath
						? baseUrl + clientProfileDetails.imgPath
						: userAvatar
				);
				setUserNameToView(clientProfileDetails.name);
				setBioToView(clientProfileDetails.bio);
				setWebsiteToView(clientProfileDetails.website);
				setPhoneToView(clientProfileDetails.phone);
				setEmailToView(clientProfileDetails.email);
				setAddressToView(clientProfileDetails.address);
				setCoins(coins);
				setServiceTypeToView(clientProfileDetails.serviceName);
			}
		} else {
			getUserProfileData(
				lang,
				(success) => {
					const { isSuccess, data } = success;
					if (isSuccess) {
						const {
							name,
							email,
							phone,
							imgPath,
							address,
							bio,
							coins,
							serviceName,
							averageRating,
							website,
						} = data;
						setUserName(name);
						setPhone(phone);
						setEmail(email);
						setImgSrc(imgPath ? baseUrl + imgPath : userAvatar);
						setAddress(address);
						setBio(bio);
						setWebsite(website);
						setRating(averageRating);
						setCoins(coins);
						setServiceName(serviceName);
						dispatch(changeAuthImg(imgPath));
						dispatch(changeAuthCoins(coins));
						dispatch(
							changeUserData({
								name,
								firstName: name.split(" ")[0],
								lastName: name.split(" ")[1],
								phone,
								address,
								email,
								bio,
								rating: averageRating,
								coins,
								serviceName,
							})
						);
					} else {
						toastError(success.error);
					}
				},
				(fail) => {
					if (fail.data) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		}
	}, [
		mode,
		clientProfileDetails,
		dispatch,
		params,
		coins,
		lang,
		rating,
		isChangeToken,
	]);

	const hirePro = () => {
		let HiredList = [{ proId, status: 5 }];
		updateProjectStatus(
			projectId,
			HiredList,
			(success) => {
				toastSuccess(success.message);
			},
			(fail) => {
				toastError(fail.message);
			}
		);
	};

	function handelUploadImg({ file }) {
		if (file.status === "error") {
			const { originFileObj } = file;
			const imageDoc = new FormData();
			imageDoc.append("imgPath", originFileObj);
			uploadProfileImage(
				imageDoc,
				(success) => {
					const { isSuccess, data } = success;
					if (isSuccess) {
						setImgSrc(baseUrl + data.path);
					}
				},
				(fail) => {
					if (fail.data) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		}
	}

	function sumbitEditProfileDetails() {
		const [firstName, lastName] = userName.split(" ");
		setIsLoading(true);
		let data = {
			name: userName,
			firstName,
			lastName,
			imgPath: imgSrc.replace(baseUrl, ""),
		};

		if (userRole !== "Property owner") {
			data = {
				email,
				phone,
				address,
				bio,
				webSite: website,
				imgPath: imgSrc.replace(baseUrl, ""),
				name: userName,
				firstName,
				lastName,
			};
		}

		editUserProfileData(
			data,
			(success) => {
				setIsLoading(false);
				const { isSuccess } = success;
				if (isSuccess) {
					setUserName(userName);
					setShowEdit(false);
					dispatch(changeAuthImg(imgSrc.replace(baseUrl, "")));
					toastSuccess(success.message);
				}
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data) {
					toastError(fail?.data?.error);
				} else {
					toastError();
				}
			}
		);
	}

	return (
		<section className="profile__sidebar mb-4 w-100">
			<>
				{mode === "clientView" ? (
					<div className="clientView">
						<figure className="sidebar__figure">
							<img
								src={imgSrcToView}
								onError={(e) => {
									e.target.src = userAvatar;
									e.target.alt = "Image failed to load";
								}}
								alt="PRO avatar"
							/>
						</figure>
						<h2>{userNameToView}</h2>
						{serviceTypeToView && (
							<div className="serviceType">{serviceTypeToView}</div>
						)}
						{bioToView && <div className="bio">{bioToView}</div>}

						<div>
							{websiteToView && <div className="website">{websiteToView}</div>}
							{phoneToView && <div className="phone">{phoneToView}</div>}
							{emailToView && <div className="email">{emailToView}</div>}
							{addressToView && <div className="address">{addressToView}</div>}
							<div className="overallRatingBox my-2">
								<div className="title mb-2">{t("Overall Rating")}</div>
								<SharedRating readOnly={true} value={ratingToView} />
								<div className="primary-bid-btn my-3">
									<Button
										type="primary"
										disabled={clientStatus !== 4}
										className="btn"
										onClick={hirePro}>
										{clientStatus === 5 ? (
											<> {t("hired")}</>
										) : (
											<>{t("hirePro")}</>
										)}
									</Button>
								</div>
							</div>
						</div>
					</div>
				) : userRole === "Property owner" ? (
					<>
						{/* client && edit */}
						{showEdit ? (
							<EditProfile
								accountType={"client"}
								handelUploadImg={handelUploadImg}
								sumbitEditProfileDetails={sumbitEditProfileDetails}
								imgSrc={imgSrc}
								userData={{ userName: userData.userName }}
								setUserData={{
									setUserName: setUserData.setUserName,
								}}
								isLoading={isLoading}
							/>
						) : (
							<ViewProfile
								accountType="client"
								imgSrc={imgSrc}
								userData={{ userName: userData.userName }}
								setShowEdit={setShowEdit}
								handleNavigateToCreate={handleNavigateToCreate}
							/>
						)}
					</>
				) : (
					<>
						{showEdit ? (
							<EditProfile
								accountType={"pro"}
								handelUploadImg={handelUploadImg}
								sumbitEditProfileDetails={sumbitEditProfileDetails}
								imgSrc={imgSrc}
								userData={userData}
								setUserData={setUserData}
								isLoading={isLoading}
							/>
						) : (
							<ViewProfile
								accountType="pro"
								imgSrc={imgSrc}
								setShowEdit={setShowEdit}
								userData={userData}
								setBuyTokenModalVisibility={setBuyTokenModalVisibility}
								service={service}
							/>
						)}
					</>
				)}
			</>
			{buyTokenModalVisibility && (
				<BuyTokenModal
					visible={buyTokenModalVisibility}
					onCancel={() => setBuyTokenModalVisibility(false)}
					onConfirm={() => setBuyTokenModalVisibility(false)}
				/>
			)}
		</section>
	);
};

export default Sidebar;
