import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { getHomeServiceBanner } from "modules/Home/network";
import { Carousel } from "antd";
import { useTranslation } from "react-i18next";
import { baseUrl } from "services";
import { Button } from "antd";
import "./OurFeatures.scss";

const OurFeatures = ({ id }) => {
	const { lang } = useSelector((state) => state.lang);
	const { authorization } = useSelector((state) => state.authorization);
	const [isMobile, setIsMobile] = useState(false);
	const [banners, updateBanners] = useState([]);
	const [isPlaying, setIsPlaying] = useState(true);
	useEffect(() => {
		return () => {
			setIsPlaying(false);
		};
	}, []);

	useEffect(() => {
		getHomeServiceBanner(
			(success) => {
				updateBanners(success.data);
			},
			(fail) => {}
		);
	}, []);

	//choose the screen size
	const handleResize = () => {
		if (window.innerWidth < 720) {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}
	};

	useEffect(() => {
		if (window.innerWidth < 720) {
			setIsMobile(true);
		}
	}, []);

	// create an event listener
	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const urlReplacement = (oldURL) => encodeURI(oldURL)?.replace(/%5C/g, "/");

	return (
		isPlaying && (
			<section id={id} className="features home__section">
				<div className="container p-0">
					<Carousel className="features__carousel" autoplay rtl={lang === "ar"}>
						{banners.map((banner, idx) => {
							return (
								<div className="features__item" key={lang}>
									{!banner.isVideo ? (
										<img
											src={baseUrl + banner.image[lang]}
											key={lang}
											alt="bannerImage"
										/>
									) : (
										<video
											className="home__hero--video"
											autoPlay={!isMobile}
											muted
											loop
											key={lang}
											// key={idx}
										>
											<source
												src={
													urlReplacement(baseUrl + banner.image[lang]) +
													"#t=0.001"
												}
												key={lang}
											/>
										</video>
									)}
									<div className="features__item--content">
										<h4 className="main-color mainTitle">
											{banner.title[lang]}
										</h4>
										<p className={"p-text"}>
											{isMobile
												? banner.mobileText[lang]
												: banner.websiteText[lang]}
										</p>
										<div className="primary-bid-btn">
											<Button type="primary" className="btn">
												<Link
													to={`${
														authorization?.token &&
														authorization?.role === "Property owner"
															? "/profile/create-project"
															: "/signup"
													}`}
													className="streched-link">
													{banner.button[lang]}
												</Link>
											</Button>
										</div>
									</div>
								</div>
							);
						})}
					</Carousel>
				</div>
			</section>
		)
	);
};

export default OurFeatures;
