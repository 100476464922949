import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import userAvatar from "assets/images/Group 2354.svg";
import { Row, Col, Select, Input, Button } from "antd";
import { getRateQuestions, clientRatePro } from "modules/Profiles/network";
import SharedRating from "modules/Shared/SharedRating/SharedRating";
import { toastError, toastSuccess } from "helpers/toasterFun";
import { baseUrl } from "services";

const { Option } = Select;
const RatingCard = ({ projectToRate, setProjectsToRate }) => {
	const [rateQuestions, setRateQuestions] = useState([]);
	const [disabledSubmit, setDisabledSubmit] = useState(true);
	const [selectedPro, setSelectedPro] = useState(null);
	const [ratingVal, setRatingVal] = useState(0);
	const [ratedQuestions, setRatedQuestions] = useState([]);
	const [comment, setComment] = useState(null);
	const [change, setChange] = useState(false);
	const { lang } = useSelector((state) => state.lang);
	const { t } = useTranslation();

	function resetValues() {
		setComment(null);
		setSelectedPro(null);
		setRatedQuestions([]);
		setRatingVal(false);
		setChange((prev) => !prev);
	}

	useEffect(() => {
		getRateQuestions(
			lang,
			(success) => {
				const { isSuccess, data } = success;
				if (isSuccess) {
					setRateQuestions(data);
				}
			},
			(fail) => {}
		);
	}, [lang]);

	function handelSelectedUser(proId) {
		if (proId) {
			let selectedPro = projectToRate?.data.filter(
				(project) => project.proId === proId
			);
			setSelectedPro(...selectedPro);
		} else {
			setSelectedPro(null);
		}
	}

	function onChange(rateQId, rate) {
		setRatedQuestions((prevState) => {
			if (prevState.find((item) => item.rateQuestionId === rateQId)) {
				prevState.map((item) => {
					if (item.rateQuestionId === rateQId) {
						item.rate = rate;
					}
					return item;
				});
				return [...prevState];
			} else {
				return [...prevState, { rateQuestionId: rateQId, rate }];
			}
		});
	}

	function submitProRate() {
		if (!disabledSubmit) {
			let data = {
				proId: selectedPro.proId,
				projectId: projectToRate.projectId,
				rates: ratedQuestions,
				comment,
			};

			clientRatePro(
				data,
				(success) => {
					const { isSuccess } = success;
					if (isSuccess) {
						resetValues();
						toastSuccess(t("proIsRatedSuccessfully"));
						setProjectsToRate((prevState) => {
							return prevState.filter(
								(project) => project.projectId !== data.projectId
							);
						});
					}
				},
				(fail) => {
					toastError(fail.data.error);
				}
			);
		}
	}

	useEffect(() => {
		if (
			ratedQuestions.length === rateQuestions.length &&
			comment?.length &&
			selectedPro
		) {
			setDisabledSubmit(false);
		} else {
			setDisabledSubmit(true);
		}
	}, [ratedQuestions.length, rateQuestions.length, selectedPro, comment]);

	return (
		<li className="item">
			<img src={require("assets/images/user-avatar.png")} alt="" />
			<Row gutter={16} className="w-100">
				<Col xs={24} sm={8} md={4}>
					<figure className="ratepro__img">
						<img
							src={
								selectedPro && selectedPro.proImg
									? baseUrl + selectedPro.proImg
									: userAvatar
							}
							onError={(e) => {
								e.target.src = userAvatar;
								e.target.alt = "Image failed to load";
							}}
							alt="pro-avatar"
						/>
					</figure>
				</Col>
				<Col xs={24} sm={16} md={9}>
					<div className="ratepro__dropdown-wrapper">
						<div className="ratepro__project">{projectToRate.projectName}</div>
						<Select
							key={change}
							placeholder={t("selectAPerson")}
							allowClear
							className="ratepro__selectbox w-100"
							onChange={handelSelectedUser}
						>
							{projectToRate.data.length &&
								projectToRate.data.map((person) => (
									<Option value={person.proId} key={person.proId}>
										{person.proName}
									</Option>
								))}
						</Select>
						<div className="ratepro__comment">
							<label htmlFor={projectToRate.porojectId}>
								{t("extraComment")}
							</label>
							<Input.TextArea
								value={comment}
								onChange={(e) => setComment(e.target.value)}
								rows={4}
								id={projectToRate.porojectId}
							/>
						</div>
					</div>
				</Col>
				{/* Start Rating */}
				<Col sm={24} md={11}>
					<div className="d-flex flex-column justify-content-end flex-1">
						<Row gutter={[32, 14]} className="ratepro__rating-wrapper">
							{rateQuestions?.length
								? rateQuestions.map((rateQ, i) => (
										<Col xs={24} sm={12} xl={12}>
											<h6>{rateQ.rateQuestion}</h6>
											<SharedRating
												key={change}
												id={rateQ._id}
												value={ratingVal}
												setRatingVal={setRatingVal}
												onChange={onChange}
											/>
										</Col>
								  ))
								: null}
							<div className="primary-bid-btn mx-auto">
								<Button
									className="btn"
									type="primary"
									disabled={disabledSubmit}
									onClick={submitProRate}
								>
									{t("submit")}
								</Button>
							</div>
						</Row>
					</div>
				</Col>
				{/* End Rating */}
			</Row>
		</li>
	);
};

export default RatingCard;
