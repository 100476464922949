import { useEffect, useState } from "react";
import { Tabs } from "antd";
import { getArticles, getBlogCategories, getBlogs } from "./network";
import CategoryBlog from "./CategoryBlog/CategoryBlog";
import { baseUrl } from "services";
import { toastError } from "helpers/toasterFun";
import { orderBy } from "lodash";
import "./Blog.scss";

export const Blog = () => {
	const { TabPane } = Tabs;
	const [blog, setBlog] = useState(null);
	const [bannerCategories, updateBannerCategories] = useState([]);
	const [articles, updateArticles] = useState([]);
	const [categoryId, updateCategoryId] = useState(null);
	const [imgPath, setImgPath] = useState("");
	const [isLoading, setIsLoading] = useState(false);

	const urlReplacement = (oldURL) => encodeURI(oldURL)?.replace(/%5C/g, "/");

	useEffect(() => {
		setIsLoading(true);
		getBlogs(
			(success) => {
				setIsLoading(false);
				const { data } = success;
				setImgPath(urlReplacement(baseUrl + data.imgPath));
				setBlog(data);
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
		getBlogCategories(
			(success) => {
				updateBannerCategories(success.data);
				updateCategoryId(success.data[0]._id);
			},
			(fail) => {}
		);
	}, []);

	console.log(articles);

	useEffect(() => {
		if (categoryId) {
			setIsLoading(true);
			const data = { categoryId };

			getArticles(
				data,
				(success) => {
					setIsLoading(false);
					updateArticles(orderBy(success.data, ["index"], ["asc"]));
				},
				(fail) => {
					setIsLoading(false);
				}
			);
		}
	}, [categoryId]);

	return (
		<main className="blog-page">
			{blog && (
				<div
					className="blog__hero"
					style={{
						background: `url(${imgPath})`,
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
					}}
				>
					<header className="blog__header">
						<div className="container">
							{blog && (
								<>
									<h2>{blog.title}</h2>
									<p>{blog.description}</p>
								</>
							)}
						</div>
					</header>
				</div>
			)}
			<div className="blog__tabs">
				<Tabs
					onChange={(e) => {
						updateCategoryId(e);
					}}
					defaultActiveKey="1"
				>
					{bannerCategories.map((category) => (
						<TabPane tab={category.category} key={category._id}>
							<CategoryBlog articles={articles} isLoading={isLoading} />
						</TabPane>
					))}
				</Tabs>
			</div>
		</main>
	);
};
