import { postResource } from "services";

export function signIn(data, lang, onSuccess, onFail) {
	const path = "api/v1/login?lang=" + lang;
	postResource(path, data, onSuccess, onFail, false);
}

export function signUp(data, lang, onSuccess, onFail) {
	const path = "api/v1/register?lang=" + lang;
	postResource(path, data, onSuccess, onFail, false);
}

export function getAllAccountTypes(lang, onSuccess, onFail) {
  if (lang === 'en')
    onSuccess({
      "data": [
        {
          "_id": "625be6db995eb93c8f7bfdbf",
          "accountType": "Property owner"
        },
        {
          "_id": "625be6e5995eb93c8f7bfdc1",
          "accountType": "Pro"
        }
      ]
    });
  else
    onSuccess({
      "data": [
        {
          "_id": "625be6db995eb93c8f7bfdbf",
          "accountType": "مالك العقار"
        },
        {
          "_id": "625be6e5995eb93c8f7bfdc1",
          "accountType": "مختص"
        }
      ]
    });
}
