import Masonry from "react-masonry-css";
import { useTranslation } from "react-i18next";
import { baseUrl } from "services";
// styling
import "./CategoryBlog.scss";
import moment from "moment";
import { Link } from "react-router-dom";
import { Spin } from "antd";

const breakpointColumnsObj = {
	default: 3,
	1100: 2,
	500: 1,
};

const CategoryBlog = ({ articles, isLoading }) => {
	const { t } = useTranslation();
	return (
		<section className="category-blog with__spinner" style={{ minHeight: 450 }}>
			{isLoading && (
				<div className="spinner__container">
					<Spin className="spinner" />
				</div>
			)}
			{!articles?.length ? (
				<div
					className="empty-text"
					style={{
						width: "100%",
						height: 450,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<div
						style={{
							color: "#c3c3c3",
							fontSize: "1rem",
							fontWeight: "bold",
						}}
					>
						{t("noArticlesYet")}
					</div>
				</div>
			) : (
				<>
					<div className="container">
						<Masonry
							breakpointCols={breakpointColumnsObj}
							className="my-masonry-grid"
							columnClassName="my-masonry-grid_column"
						>
							{articles.map((item) => (
								<div className="item blog-item" key={item._id}>
									<figure className="blog-item__fig">
										<Link
											to={{
												pathname: "/article-details",
												search: `articleId=${item._id}`,
											}}
											style={{
												color: "#707070",
											}}
										>
											<img src={baseUrl + item.image} alt={item.title} />
										</Link>
									</figure>
									<div className="blog-item__sub-header">
										{item.CategoryName} | {moment(item.date).format("L")}
									</div>
									<header className="blog-item__header">
										<h4>
											<Link
												to={{
													pathname: "/article-details",
													search: `articleId=${item._id}`,
												}}
												style={{
													color: "#707070",
												}}
											>
												<span>{item.title}</span>
											</Link>
										</h4>
									</header>
									<div className="blog-item__desc" style={{ cursor: "auto" }}>
										<span>
											<Link
												to={{
													pathname: "/article-details",
													search: `articleId=${item._id}`,
												}}
												style={{
													color: "#707070",
												}}
											>
												{item.description}
											</Link>
										</span>
									</div>
								</div>
							))}
						</Masonry>
					</div>
				</>
			)}
		</section>
	);
};

export default CategoryBlog;
