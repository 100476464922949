import { Button, Modal } from "antd";
import "./ModalComp.scss";

const ModalComp = ({
	visible,
	onCancel,
	title,
	desc,
	subDesc,
	confirmation,
	onConfirm,
	confirmBtn,
	cancelBtn,
	disableSubmit,
}) => {
	function confirm() {
		onConfirm();
		onCancel();
	}

	return (
		<Modal
			className="modal-comp"
			centered
			visible={visible}
			footer={null}
			onCancel={onCancel}
			bodyStyle={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
			}}
		>
			<h2 className="modal-title">{title}</h2>
			{desc && (
				<p className="text-center text-dark fs-5 fw-normal py-3 desc">{desc}</p>
			)}
			{subDesc && (
				<p className="text-center text-dark  fw-normal subDesc">{subDesc}</p>
			)}
			<span className="text-center text-dark fs-5 fw-normal py-3 confirmation">
				{confirmation}
			</span>
			<div className="d-flex justify-content-end align-items-center py-2 buttons">
				<Button
					style={{
						width: 141,
					}}
					className="cancel"
					onClick={onCancel}
				>
					{cancelBtn}
				</Button>
				<Button
					style={{
						width: 141,
					}}
					type="primary"
					className="confirm"
					onClick={confirm}
					disabled={disableSubmit}
				>
					{confirmBtn}
				</Button>
			</div>
		</Modal>
	);
};

export default ModalComp;
