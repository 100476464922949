// useState,
import { useEffect } from "react";
import { ConfigProvider as AntdConfigProvider } from "antd";
import { ThemeProvider as ReactBootstrapThemeProvider } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import Navbar from "modules/Shared/Navbar/Navbar";
import { useSelector } from "react-redux";
import { getToken } from "services";
import { Helmet } from "react-helmet";
import Routes from "routes";
import i18n from "./assets/localization/i18n";
import "react-slideshow-image/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
// import logoImg from "assets/images/logo.svg";
AntdConfigProvider.config({
	theme: {
		primaryColor: "#12819c",
	},
});
function App() {
	const { authorization } = useSelector((state) => state.authorization);
	const { lang, dir } = useSelector((state) => state.lang);
	// const [dataLoaded, setDataLoaded] = useState(false);
	// useEffect(() => {
	// 	setDataLoaded(false);
	// 	window.addEventListener("load", (data) => {
	// 		console.log("dataLoaded", data);
	// 		setDataLoaded(true);
	// 	});
	// }, []);

	useEffect(() => {
		i18n.init({
			lng: lang,
			fallbackLng: lang,
		});
	}, [lang, dir]);

	useEffect(() => {
		let token = authorization?.token;
		if (token) {
			getToken(token);
		}
	}, [authorization]);

	return (
		<>
			<Helmet>
				<html lang={lang} dir={dir} className={lang} />
			</Helmet>
			<AntdConfigProvider direction={dir}>
				<ReactBootstrapThemeProvider dir={"rtl"}>
					<ToastContainer
						position={dir === "rtl" ? "bottom-left" : "bottom-right"}
						rtl={dir === "rtl"}
					/>
					<>
						<Navbar />
						<Routes />
					</>
				</ReactBootstrapThemeProvider>
			</AntdConfigProvider>
		</>
	);
}

export default App;
