import { useTranslation } from "react-i18next";
import { Modal } from "antd";
import Masonry from "react-masonry-css";
import { baseUrl } from "services";
import moment from "moment";
import "./ShowProjectModal.scss";

const ShowProjectModal = ({ project, visible, onCancel, isClient }) => {
	const { t } = useTranslation();
	const breakpointColumnsObj = {
		default: 2,
		1100: 2,
		500: 1,
		420: 1,
	};
	return (
		<Modal
			className="project-modal"
			centered
			visible={visible}
			footer={null}
			onCancel={onCancel}
			width={"70vw"}
		>
			<div className="project__el">{project.projectName}</div>
			<div className="project__el">
				{moment(project.createdAt).format("DD/MM/YYYY")}
			</div>
			<div className="service">{t("serviceType")}</div>
			<div className="project__el">{project.service}</div>
			<Masonry
				breakpointCols={breakpointColumnsObj}
				className="my-masonry-grid project__images"
				columnClassName="my-masonry-grid_column"
			>
				{project.imageDetails.map(
					(image, _idx) =>
						!image.isDisabled && (
							<div className="item" key={_idx}>
								{image.isRejected ? (
									<div className="pending-text">
										<div>{t("rejected")}</div>
									</div>
								) : !image?.isImgApproved ? (
									<div className="pending-text">
										<div>{t("pendingApproval")}</div>
									</div>
								) : null}

								<figure className="item__figure">
									<img src={baseUrl + image.imagePath} alt="project-images" />
								</figure>
							</div>
						)
				)}
			</Masonry>
		</Modal>
	);
};

export default ShowProjectModal;
