import { getResource, patchResource } from "services";

export function getUserProfileDate(onSuccess, onFail) {
	const path = "api/users";
	getResource(path, onSuccess, onFail);
}

export function changePassword(data, onSuccess, onFail) {
	const path = `api/users/changePassword`;
	patchResource(path, data, onSuccess, onFail);
}
