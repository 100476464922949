import { Link } from "react-router-dom";
import notFound from "assets/images/page_not_found.svg";
import "./NotFound.scss";

function NotFound() {
  return (
    <main className="text-center not-found">
      <div className="container">
        <div className="not-found__container d-flex justify-content-center align-items-center flex-column">
          <figure className="not-found__figure mb-4">
            <img src={notFound} alt="Page not found" />
          </figure>
          <div className="text-container mb-5">
            <p>
              Ooops! <br /> Page Not Found
            </p>
          </div>
          <Link className="not-found__back text-uppercase" to="/">
            back to home
          </Link>
        </div>
      </div>
    </main>
  );
}

export default NotFound;
