import { useEffect, useState } from "react";
import { getQAndA } from "modules/Home/network";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./FAQ.scss";
import { useSearchParams } from "react-router-dom";

const FAQ = ({ id }) => {
	const [qAndAs, updateQandAs] = useState([]);
	const [activeKey, setActiveKey] = useState(null);
	const { i18n, t } = useTranslation();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const activeLink = searchParams.get("question");
		setActiveKey(activeLink);
	}, [searchParams]);

	useEffect(() => {
		getQAndA(
			(success) => {
				updateQandAs(success.data);
			},
			(fail) => {}
		);
	}, []);

	function handleOnSelectActiveKey(value) {
		setActiveKey(value);
	}
	return (
		<section id={id} className="home__section home__FAQ">
			<div className="container">
				<header className="home__header">
					<h2 className="text-center text-uppercase">{t("faqs")}</h2>
				</header>
				<Accordion activeKey={activeKey} onSelect={handleOnSelectActiveKey}>
					{qAndAs.map((QAndA, index) => {
						return (
							<Accordion.Item key={QAndA._id} eventKey={QAndA._id}>
								<Accordion.Header>
									{QAndA.question[i18n.language]}
								</Accordion.Header>
								<Accordion.Body>{QAndA.answer[i18n.language]}</Accordion.Body>
							</Accordion.Item>
						);
					})}
				</Accordion>
			</div>
		</section>
	);
};

export default FAQ;
