import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
	getAllRatesForPro,
	getDifferntRateAverage,
	getDifferntRateAverageByProId,
	getprofessionalProjectsAllRates,
} from "modules/Profiles/network";
import { Dropdown, Menu, Row, Col, Spin } from "antd";
import { DownOutlined } from "@ant-design/icons";
import SharedRating from "modules/Shared/SharedRating/SharedRating";
import BidsterLogo from "assets/images/logo.svg";
import StarIcon from "assets/icons/jsx-icons/star.jsx";
import { toastError } from "helpers/toasterFun";
import "./RatingAndReviews.scss";

const RatingAndReviews = ({ proId, mode, setIsRatingLoading }) => {
	const [ratingItems, setRatingItems] = useState([]);
	const [clientsReview, setClientsReview] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const { authorization } = useSelector((state) => state.authorization);
	const { t } = useTranslation();
	const { lang } = useSelector((state) => state.lang);

	useEffect(() => {
		setIsLoading(true);
		if (proId) {
			getDifferntRateAverageByProId(
				proId,
				lang,
				(success) => {
					setRatingItems(success.data);
					setIsLoading(false);
				},
				(fail) => {
					setIsLoading(false);
				}
			);
			getprofessionalProjectsAllRates(
				proId,
				lang,
				(success) => {
					setClientsReview(success.data);
					setIsLoading(false);
				},
				(fail) => {
					setIsLoading(false);
				}
			);
		} else {
			getAllRatesForPro(
				lang,
				(success) => {
					if (success.isSuccess) {
						setClientsReview(success.data);
						setIsLoading(false);
					} else {
						toastError(success.error);
					}
				},
				(fail) => {
					setIsLoading(false);
					if (fail.data) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);

			getDifferntRateAverage(
				lang,
				(success) => {
					if (success.isSuccess) {
						setRatingItems(success.data);
					} else {
						toastError(success.error);
					}
				},
				(fail) => {
					if (fail.data) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		}
	}, [lang, proId]);

	const menu = (rates) => {
		return (
			<Menu className>
				{ratingItems?.length &&
					rates.map(({ rate, rateQuestionId }, index) => (
						<Menu.Item
							className="item"
							key={index}
							style={{
								textAlign: "center",
							}}>
							<p
								className="dropdown__p"
								style={{
									color: "#707070",
									fontSize: "0.75rem",
								}}>
								{rateQuestionId.rateQuestion}
							</p>
							<SharedRating value={rate} readOnly />
						</Menu.Item>
					))}
			</Menu>
		);
	};

	return (
		<section
			className={`rating-review with__spinner main-section ${
				mode === "clientView" ? "" : ""
			}`}>
			{isLoading ? (
				<div className="spinner__container">
					<Spin className="spinner" />
				</div>
			) : (
				<Row gutter={16} className="rating-review__row w-100">
					<Col xs={24} md={6}>
						<div className="main-reviews">
							<ul className="main-reviews__list text-center">
								{mode === "clientView" ? (
									<>
										{ratingItems?.map((item, i) => (
											<li className="item mb-3" key={i}>
												<h6 className="mb-3">{item.rateQuestion}</h6>
												<SharedRating
													readOnly
													value={item.average}
													className="ratingModal"
												/>
											</li>
										))}
									</>
								) : (
									<>
										{ratingItems?.map((item, i) => (
											<li className="item mb-3" key={i}>
												<h6 className="mb-3">{item.name}</h6>
												<SharedRating
													readOnly
													value={item.average}
													className="ratingModal"
												/>
											</li>
										))}
									</>
								)}
							</ul>
							<div>
								<p className="main-reviews__p">
									{t("thisProfileHasBeenAwarded")}
									<strong>
										{" "}
										{clientsReview ? clientsReview.length : "0"}{" "}
									</strong>
									{clientsReview?.length >= 3 && clientsReview?.length <= 10
										? t("projectsThrough")
										: t("projectThrough")}
								</p>
								<figure className="main-reviews__figure">
									<img src={BidsterLogo} alt="bidster-logo" />
								</figure>
							</div>
						</div>
					</Col>
					<Col
						xs={24}
						md={18}
						className={`clients-reviews__wrapper ${
							clientsReview?.length ? "" : "no-list"
						}`}>
						<div className="clients-reviews">
							<ul className={`clients-reviews__list`}>
								{clientsReview?.length ? (
									clientsReview.map((item, i) => (
										<div className="item" key={i}>
											<div className="clients-reviews__rating">
												<SharedRating readOnly value={item.average} />
												<div className="d-flex ailgn-items-center justify-content-end clients-reviews__rating-dropdown-wrapper">
													<p className="clients-reviews__date">
														{item.createdAt}
													</p>
													<Dropdown
														className="clients-reviews__rating-dropdown"
														overlay={() => menu(item.rates)}
														placement={
															lang === "ar" ? "bottomLeft" : "bottomRight"
														}>
														<div className="clients-reviews__rating-dropdown__title">
															<StarIcon width={"1.5rem"} height={"1.5rem"} />
															<DownOutlined
																width={24}
																style={{
																	fontSize: "0.75rem",
																	marginInlineStart: "0.25rem",
																}}
															/>
														</div>
													</Dropdown>
												</div>
											</div>
											<header className="clients-reviews__header">
												<h4>{item.projectName}</h4>
											</header>
											<p className="clients-reviews__desc">{item.comment}</p>
											<footer className="clients-reviews__footer">
												<p>
													{t("by")}: {item.clientName}
												</p>
											</footer>
										</div>
									))
								) : (
									<div className="text-empty">
										{authorization?.role?.includes("Property")
											? t("theresNoRatingForYou")
											: t("youDontHaveRating")}
									</div>
								)}
							</ul>
						</div>
					</Col>
				</Row>
			)}
		</section>
	);
};

export default RatingAndReviews;
