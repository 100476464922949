import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toastError } from "helpers/toasterFun";
import { Tabs, Spin } from "antd";
import { useTranslation } from "react-i18next";
import {
	getAllProProjects,
	getAllClientProjects,
	getProjectDependOnStatusById,
	getShortListingData,
} from "modules/Profiles/network";
import SubmittedBrief from "../SubmittedBrief/SubmittedBrief";
import ShortListing from "../ShortListing/ShortListing";
import ProfileAndQuotations from "modules/Profiles/components/Content/components/InnerContent/components/Profile/ProfileAndQoutItem";
import "./ProjectsRoom.scss";

const { TabPane } = Tabs;

const ProjectsRoom = () => {
	const [projects, setProjects] = useState([]);
	const [allProjects, updateAllProjects] = useState([]);
	const [activeProject, setActiveProject] = useState(null);
	const [submittedBriefData, updateSubmittedBriefData] = useState(null);
	const [shortListingArr, updateShortListingArr] = useState([]);
	const [activeProjectRoomProjectTab, setActiveProjectRoomProjectTab] =
		useState(null);
	const [isAllDataLoading, setIsAllDataLoading] = useState(true);
	const [isDataLodaing, setIsDataLoading] = useState(false);
	const [selectedProjectStatus, setSelectedProjectStatus] = useState(null);
	const [isMakeReshortlistingChange, setIsMakeReshortlistingChange] =
		useState(false);
	const { lang } = useSelector((state) => state.lang);
	const { t } = useTranslation();
	const location = useLocation();

	useEffect(() => {
		getAllProProjects(
			lang,
			(success) => {
				const { isSuccess } = success;
				if (isSuccess) {
					setProjects(success.data);
				}
			},
			(fail) => {
				if (fail.data) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}, [lang, isMakeReshortlistingChange]);

	useEffect(() => {
		setIsAllDataLoading(true);
		getAllClientProjects(
			lang,
			(success) => {
				setIsAllDataLoading(false);
				updateAllProjects(success.data);
				if (!activeProject) {
					if (location?.state?.porjectId) {
						setActiveProject(location.state.porjectId);
					} else {
						setActiveProject(success.data[0]?._id);
					}
				}
			},
			(fail) => {
				setIsAllDataLoading(false);
				setIsDataLoading(false);
			}
		);
		// eslint-disable-next-line
	}, [location, lang, isMakeReshortlistingChange]);

	useEffect(() => {
		if (activeProject) {
			setIsDataLoading(true);
			let data = {
				projectId: activeProject,
				lang,
			};
			getProjectDependOnStatusById(
				data,
				(success) => {
					setIsDataLoading(false);
					updateSubmittedBriefData(success.data);
					const getProjectStatus = allProjects.filter(
						(project) => project._id === activeProject
					)[0].statusOfTheProject;
					setSelectedProjectStatus(getProjectStatus);
					if (getProjectStatus === 1) {
						setActiveProjectRoomProjectTab("submitedBrief");
					} else if (getProjectStatus === 2) {
						setActiveProjectRoomProjectTab("shortlisting");
					} else {
						setActiveProjectRoomProjectTab("profilesAndQuotations");
					}
				},
				(fail) => {
					setIsDataLoading(false);
				}
			);

			getShortListingData(
				data,
				(success) => {
					updateShortListingArr(success.data);
				},
				(fail) => {
					if (fail.data) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		}
	}, [activeProject, allProjects, lang, isMakeReshortlistingChange]);

	function onChangeTab(key) {
		// check if the project is not removed from database
		getAllClientProjects(
			lang,
			(success) => {
				let hasProject = success.data.filter(
					(item) => item._id === activeProject
				).length;
				if (hasProject === 0) {
					setIsMakeReshortlistingChange((prev) => !prev);
					setActiveProject(null);
					setActiveProjectRoomProjectTab(null);
				} else {
					setActiveProject(key);
					updateSubmittedBriefData(null);
					updateShortListingArr([]);
					setActiveProjectRoomProjectTab("submitedBrief");
				}
			},
			(fail) => {
				if (fail.data) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	return (
		<div className="tabs-container main-section projects-room-component with__spinner">
			{isAllDataLoading ? (
				<div className="spinner__container">
					<Spin className="spinner" />
				</div>
			) : (
				<>
					{allProjects?.length === 0 ? (
						<div className="d-flex justify-content-center text-center align-items-center EmptyDataText">
							{t("thereAreNoPorjects")}
						</div>
					) : (
						<div>
							<Tabs
								activeKey={activeProject || allProjects[0]?._id}
								type="card"
								className="projects-name"
								onChange={(key) => {
									onChangeTab(key);
								}}
							>
								{allProjects.map((project) => (
									<TabPane
										className="projects-name"
										tab={project.name}
										key={project._id}
									></TabPane>
								))}
							</Tabs>
							<Tabs
								defaultActiveKey="submitedBrief"
								activeKey={activeProjectRoomProjectTab}
								type="card"
								style={{
									borderTop: "1px solid #A2A2A2",
									paddingBlock: "1rem",
									minHeight: 450,
								}}
								// className="internal-tabs client-internal-tab with__spinner"
								onChange={(key) => {
									setActiveProjectRoomProjectTab(key);
								}}
							>
								{isDataLodaing ? (
									<div className="spinner__container">
										<Spin className="spinner" />
									</div>
								) : (
									<>
										<TabPane tab={t("submittedBrief")} key="submitedBrief">
											{submittedBriefData ? (
												<SubmittedBrief
													submittedBriefData={submittedBriefData}
												/>
											) : null}
										</TabPane>
										<TabPane
											forceRender={activeProjectRoomProjectTab}
											tab={t("shortlisting")}
											key="shortlisting"
										>
											<ShortListing
												projects={projects}
												ShortListingArr={shortListingArr}
												activeProject={activeProject}
												makeReshortlistingChange={setIsMakeReshortlistingChange}
												setProjects={() => {}}
												inProfileAndQuotations={
													allProjects.find((item) => item._id === activeProject)
														?.statusOfTheProject > 2
												}
												setActiveProjectRoomProjectTab={
													setActiveProjectRoomProjectTab
												}
												setRerenderList={setIsMakeReshortlistingChange}
											/>
										</TabPane>
										<TabPane
											tab={t("profileAndQuotations")}
											key="profilesAndQuotations"
											forceRender={activeProjectRoomProjectTab}
										>
											<ProfileAndQuotations
												activeProject={activeProject}
												projectStatus={selectedProjectStatus}
												setActiveProjectRoomProjectTab={
													setActiveProjectRoomProjectTab
												}
												makeReshortlistingChange={() =>
													setIsMakeReshortlistingChange((prev) => !prev)
												}
												setActiveProject={setActiveProject}
											/>
										</TabPane>
									</>
								)}
							</Tabs>
						</div>
					)}
				</>
			)}
		</div>
	);
};

export default ProjectsRoom;
