import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import Carousel from "react-multi-carousel";
// import { useSearchParams } from "react-router-dom";
import { getUtlsAndMore } from "../../network";
import { useTranslation } from "react-i18next";
import { baseUrl } from "services";
import UtilitiesModal from "../UtilitiesModal/UtilitiesModal";
import "react-multi-carousel/lib/styles.css";
import "./HomeServices.scss";

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 992 },
		items: 5,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: { max: 992, min: 464 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
	},
};
const HomeServices = ({ id }) => {
	const [servicesList, updateServicesList] = useState([]);
	const [isModalVis, updateIsModalVis] = useState(false);
	const { i18n, t } = useTranslation();
	const { lang } = useSelector((state) => state.lang);
	// const [searchParams] = useSearchParams();
	useEffect(() => {
		getUtlsAndMore(
			(success) => {
				updateServicesList(success.data);
			},
			(fail) => {}
		);
	}, []);

	// useEffect(() => {
	// 	let modalId = searchParams.get("model");
	// 	if (modalId?.length) {
	// 		updateIsModalVis(modalId);
	// 	}
	// }, [searchParams]);

	return (
		<div className="home-services" id={id}>
			<div className="container">
				<div className="text-center home-services__intro mb-4">
					<h2 className="text-uppercase home-services__title">
						{t("utilitiesAndMore")}
					</h2>
					<p className="desc text-secondary mx-4">
						{t("haveAnyThingNeedFix")}
						<br />
						{t("keepYourPlace")}
					</p>
				</div>
				<Carousel
					responsive={responsive}
					rtl={lang === "ar"}
					arrows={false}
					keyBoardControl
					autoPlay
					autoPlaySpeed={1500}
					showDots
					infinite
				>
					{servicesList.map((service, _idx) => (
						<Card
							key={_idx}
							onClick={() => {
								updateIsModalVis(service._id);
							}}
							className="border-0 p-2 mx-2 home-services__service-card cursor-pointer"
						>
							<div className="home-services__service-img-icon-container">
								<Card.Img
									className="home-services__service-img"
									variant="top"
									src={baseUrl + service.image}
									alt={service.title[lang] + "-img"}
									draggable="false"
								/>
								<div className="home-services__service-icon">
									<img
										src={baseUrl + service.icon}
										alt={service.title[lang] + "-icon"}
									/>
								</div>
							</div>
							<Card.Body>
								<Card.Title className="text-secondary text-center home-services__service-title">
									{service.title[i18n.language]}
								</Card.Title>
							</Card.Body>
						</Card>
					))}
				</Carousel>
			</div>

			{isModalVis && (
				<UtilitiesModal
					visible={isModalVis}
					onCancel={() => {
						updateIsModalVis(false);
					}}
					utilId={isModalVis}
				/>
			)}
		</div>
	);
};

export default HomeServices;
