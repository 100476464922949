import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Modal,
	Row,
	Space,
	Col,
	Form,
	Input,
	Switch,
	Button,
	Spin,
} from "antd";
import { changePassword } from "network";
import {
	editUserProfileData,
	getUserProfileData,
} from "modules/Profiles/network";
import { changeUserData } from "store/authorization";
import { useTranslation } from "react-i18next";
import { toastError, toastSuccess } from "helpers/toasterFun";
// styling
import "./ProfileSettingsModal.scss";

const ProfileSettingsModal = ({ modalVisiblity, onCancel }) => {
	const { authorization } = useSelector((state) => state.authorization);
	const [dataLoading, setDataLoading] = useState(false);
	const [initialValues, setInitialValues] = useState(null);
	const [isResetPass, setResetPass] = useState(false);
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();
	const [newForm] = Form.useForm();
	const { t } = useTranslation();
	const { lang } = useSelector((state) => state.lang);

	const dispatch = useDispatch();

	useEffect(() => {
		form.resetFields();
	}, [form, initialValues]);

	useEffect(() => {
		setDataLoading(true);
		getUserProfileData(
			lang,
			(success) => {
				setDataLoading(false);
				setInitialValues(success.data);
			},
			(fail) => {
				setDataLoading(false);
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}, [lang]);

	function onFinish(values) {
		setLoading(true);
		const { firstName, lastName, email, phone, notificationPermitted } = values;
		let payload = {
			firstName,
			lastName,
			name: firstName + " " + lastName,
			email,
			phone,
			notificationPermitted,
		};
		editUserProfileData(
			payload,
			(success) => {
				if (success.isSuccess) {
					setLoading(false);
					toastSuccess(t("userDataChangedSuccessfully"));
					dispatch(changeUserData(payload));
					onCancel();
				}
			},
			(fail) => {
				setLoading(false);
				toastError();
			}
		);
	}

	function onChangePassword(values) {
		const { password, newPassword, confirmPassword } = values;
		setLoading(true);
		let payload = {
			oldPassword: password,
			newPassword,
			confirmPassword,
		};
		changePassword(
			payload,
			(success) => {
				setLoading(false);
				toastSuccess(t("passwordChangedSuccessfully"));
				onCancel();
			},
			(fail) => {
				setLoading(false);
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else toastError();
			}
		);
	}

	return (
		<Modal
			visible={modalVisiblity}
			footer={null}
			onCancel={() => {
				setResetPass(false);
				onCancel();
			}}
			wrapClassName="profile-settings-modal has-spinner"
			centered>
			{dataLoading && (
				<div className="spinner__container">
					<Spin className="spinner" />
				</div>
			)}

			{!isResetPass ? (
				<>
					<h3>{t("profileSettings")}</h3>
					<Form
						className="settings-form"
						onFinish={onFinish}
						form={form}
						initialValues={{ ...initialValues }}>
						<Space size="small" direction="vertical">
							<Row gutter={[{ md: 32 }]}>
								<Col xs={24} md={12}>
									<Form.Item
										className="form__item"
										label={
											authorization.role === "Pro"
												? t("firstNameCompany")
												: t("firstName")
										}
										name="firstName"
										colon={false}
										rules={[
											{
												required: true,
												message: t("provideFirstName"),
											},
											{
												min: 3,
												message: t("provideValidName"),
											},
										]}>
										<Input
											id="name"
											placeholder={t("firstName")}
											type="text"
											bordered={false}
										/>
									</Form.Item>
								</Col>
								<Col xs={24} md={12}>
									<Form.Item
										className="form__item"
										label={t("lastName")}
										name="lastName"
										colon={false}
										requiredMark
										rules={[
											{
												min: 3,
												message: t("provideValidName"),
											},
										]}>
										<Input
											id="lastName"
											placeholder={t("lastName")}
											className="form-underline"
											bordered={false}
										/>
									</Form.Item>
								</Col>
								<Col xs={24} md={12}>
									<Form.Item
										className="form__item"
										label={t("email")}
										name="email"
										colon={false}
										requiredMark
										rules={[
											{
												required: true,
												message: t("provideYourEmail"),
											},
											{
												type: "email",
												message: t("provideValidEmail"),
											},
										]}>
										<Input
											id="email"
											placeholder="pro@mail.com"
											bordered={false}
											style={{
												direction: "ltr",
											}}
										/>
									</Form.Item>
								</Col>
								<Col xs={24} md={12}>
									<Form.Item
										className="form__item"
										label={t("password")}
										name="password"
										colon={false}>
										<Input.Password
											id="password"
											placeholder="******"
											disabled={true}
											iconRender={(visible) => <></>}
											bordered={false}
										/>
									</Form.Item>
									<span
										className="change-password"
										onClick={() => setResetPass(!isResetPass)}>
										{t("changePassword")}
									</span>
								</Col>
								<Col xs={24} md={12}>
									<Form.Item
										label={t("phoneNumber")}
										className="form__item"
										name="phone"
										colon={false}
										rules={[
											{
												required: true,
												message: t("providePhoneNumber"),
											},
										]}>
										<Input
											type="number"
											id="phone"
											placeholder="+020 123 456 7893"
											bordered={false}
										/>
									</Form.Item>
									<div className="d-flex justify-content-between align-items-center">
										<span>{t("allowNotifications")}</span>
										<span>
											<Form.Item
												name={"notificationPermitted"}
												className="mb-0"
												valuePropName="checked">
												<Switch size="small" className="switch" />
											</Form.Item>
										</span>
									</div>
								</Col>
							</Row>
							<div className="d-flex justify-content-center py-4 buttons w-auto">
								<Form.Item>
									<Button type="primary" loading={loading} htmlType="submit">
										{t("saveChanges")}
									</Button>
								</Form.Item>
							</div>
						</Space>
					</Form>
				</>
			) : (
				<>
					<h3>{t("changePassword")}</h3>
					<Form
						className="settings-form"
						form={newForm}
						onFinish={onChangePassword}>
						<Space size="small" direction="vertical">
							<Row>
								<Col xs={24}>
									<Form.Item
										className="form__item"
										label={t("oldPassword")}
										name="password"
										colon={false}
										rules={[
											{
												required: true,
												message: t("provideOldPassword"),
											},
										]}>
										<Input.Password
											id="oldPassword"
											placeholder="******"
											className="form-underline"
											bordered={false}
										/>
									</Form.Item>
								</Col>
								<Col xs={24}>
									<Form.Item
										className="form__item"
										label={t("newPassword")}
										name="newPassword"
										colon={false}
										rules={[
											{
												required: true,
												message: t("provideNewPassword"),
											},
											{
												min: 6,
												message: t("provideAtLeast6chars"),
											},
										]}>
										<Input.Password
											id="newPassword"
											placeholder="******"
											className="form-underline"
											bordered={false}
										/>
									</Form.Item>
								</Col>
								<Col xs={24}>
									<Form.Item
										className="form__item mb-0"
										label={t("confirmNewPassword")}
										name="confirmPassword"
										colon={false}
										requiredMark={false}
										dependencies={["newPassword"]}
										rules={[
											{
												required: true,
												message: t("pleaseConfirmPassword"),
											},
											({ getFieldValue }) => ({
												validator(rule, value) {
													if (
														!value ||
														getFieldValue("newPassword") === value
													) {
														return Promise.resolve();
													}
													return Promise.reject(t("passwordDoesNotMatch"));
												},
											}),
										]}>
										<Input.Password
											id="confirmPassword"
											placeholder="******"
											className="form-underline"
											bordered={false}
										/>
									</Form.Item>
								</Col>
							</Row>
							<small className="m-0 p-0">{t("bothPasswordsMustMatch")}</small>
						</Space>
						<div className="d-flex justify-content-center py-4 buttons mt-3 w-auto">
							<Form.Item
								style={{
									display: "flex",
									justifyContent: "center",
								}}>
								<Button type="primary" loading={loading} htmlType="submit">
									{t("resetPassword")}
								</Button>
							</Form.Item>
						</div>
					</Form>
				</>
			)}
		</Modal>
	);
};

export default ProfileSettingsModal;
