import { NavHashLink } from "react-router-hash-link";
import { useState, useEffect } from "react";

const Navigator = ({ title, category, _id, route, onCancel }) => {
	const [to, setTo] = useState(null);

	useEffect(() => {
		let navigatorRoute;
		if (category === "Blog") {
			navigatorRoute = `/${route}?articleId=${_id}`;
		} else if (category === "FAQs") {
			navigatorRoute = `/?question=${_id}${route}`;
		} else {
			navigatorRoute = `/?model=${_id}${route}`;
		}
		setTo(navigatorRoute);
	}, [_id, category, route]);

	return (
		<>
			{to?.length ? (
				<NavHashLink to={to} onClick={onCancel}>
					<h6 className="result__title">{title}</h6>
				</NavHashLink>
			) : null}
		</>
	);
};

export default Navigator;
