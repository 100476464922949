import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { baseUrl } from "services";
import { Carousel } from "antd";
import { getArticleById } from "../network";
import moment from "moment";
import "./ArticleDetails.scss";

function ArticleDetails(props) {
	const [articleDetails, updateArticleDetails] = useState({});
	const [date, updateDate] = useState(null);
	const [category, setCategory] = useState(null);

	let data = useLocation();
	const articleId = data.search.split("=")[1];

	useEffect(() => {
		if (articleId) {
			let data = { articleId };
			getArticleById(
				data,
				(success) => {
					updateArticleDetails(success.data.article);
					setCategory(success.data?.categoryId);
					updateDate(moment(success.data.updatedAt).format("DD-MM-YYYY"));
				},
				(fail) => {}
			);
		}
	}, [articleId]);

	const urlReplacement = (oldURL) => encodeURI(oldURL)?.replace(/%5C/g, "/");

	const onChange = (currentSlide) => {};

	return (
		<div className="article-details">
			<Carousel autoplay afterChange={onChange} className="article-details">
				{articleDetails?.imageDetails?.map((detail, idx) => {
					return (
						<div>
							<img
								style={{
									height: "515px",
									width: "100%",
									objectFit: "cover",
									marginBottom: "1rem",
								}}
								src={urlReplacement(baseUrl + detail.imagePath)}
								alt="articleDetailImage"
							/>
						</div>
					);
				})}
			</Carousel>

			<div className="text-center px-4">
				<div className="main-color my-4  f-13 fw-700 ">
					<span>{category?.category}</span>
					<span className="mx-2">|</span>
					<span>{date}</span>
				</div>
				<div className="f-24 fw-700 my-2">{articleDetails?.title}</div>
				<div style={{ textAlign: "start", whiteSpace: "pre-wrap" }}>
					{articleDetails?.description}
				</div>
			</div>
		</div>
	);
}

export default ArticleDetails;
