import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { resetPassword } from "../network";
import { useSearchParams } from "react-router-dom";
import "./ResetPassword.scss";
import { toastError, toastSuccess } from "helpers/toasterFun";

const ResetPassword = ({ visible, onCancel }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [userData, setUserData] = useState({});
	const { lang } = useSelector((state) => state.lang);
	const { t } = useTranslation();
	const [params] = useSearchParams();
	const navigate = useNavigate();
	useEffect(() => {
		for (const name of params.keys()) {
			setUserData((prev) => ({ ...prev, [name]: params.get(name) }));
		}
	}, [params]);

	function onFinish(value) {
		const { password } = value;

		setIsLoading(true);
		resetPassword(
			{
				...userData,
				OTP: userData.code,
				forgetPasswordOTP: userData.code,
				password,
			},
			(success) => {
				setIsLoading(false);
				toastSuccess(success.message);
				navigate("/signin");
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}

	return (
		<div className="forget">
			<div className="forget__modal box-shadow">
				<header className="forget__header">
					<h2>{t("resetPassword")}</h2>
				</header>
				<Form onFinish={onFinish} key={lang}>
					<Form.Item
						name="password"
						label={t("password")}
						className="input-item"
						rules={[
							{
								required: true,
								message: t("pleaseProvideStrongPassword"),
							},
							{
								min: 6,
								message: t("pleaseProvideAtLeast6Characters"),
							},
						]}
					>
						<Input.Password
							type="password"
							placeholder={"******"}
							bordered={false}
						/>
					</Form.Item>
					<Form.Item
						name="confirmPassword"
						className="input-item"
						dependencies={["password"]}
						label={t("confirmPassword")}
						rules={[
							{
								required: true,
								message: t("pleaseConfirmPassword"),
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (!value || getFieldValue("password") === value) {
										return Promise.resolve();
									}
									return Promise.reject(new Error(t("pleaseConfirmPassword")));
								},
							}),
						]}
					>
						<Input.Password
							type="password"
							placeholder={"******"}
							bordered={false}
						/>
					</Form.Item>
					<Form.Item className="forget__btn-contianer">
						<Button
							type="primary"
							style={{
								width: 131,
								height: 45,
							}}
							loading={isLoading}
							htmlType="submit"
							className="forget__btn"
						>
							{t("submit")}
						</Button>
					</Form.Item>
				</Form>
			</div>
		</div>
	);
};

export default ResetPassword;
