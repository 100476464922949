import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Radio, Row, Col, Spin, Button } from "antd";
import { Modal } from "antd";
//import fawryIcon from "assets/images/fawry.png";
import logoImg from "assets/images/logo.svg";
import coinsIcon from "assets/icons/smallcoins.svg";
import { getTokens, getTokenConversion, payToken } from "./network";
import { toastError } from "helpers/toasterFun";
import "./BuyTokenModal.scss";

const BuyTokenModal = ({ visible, onCancel, onConfirm }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [packages, setPackages] = useState([]);
	const [oneToken, setOneToken] = useState(null);
	const [tokens, setTokens] = useState(1);
	const [tokenPrice, setTokenPrice] = useState(null);
	const [selectedPackage, updateSelectedPackage] = useState();
	const [selectedPackagePrice, updateSelectedPackagePrice] = useState();
	const [paymentMethod, updatePaymentMethod] = useState(1);
	const [isBtnLoading, updateIsBtnLoading] = useState(false);
  const [paymentURl, setPaymentURl] = useState("");
	const { lang } = useSelector((state) => state.lang);
	const { t } = useTranslation();
	useEffect(() => {
		setIsLoading(true);
		getTokens(
			lang,
			(success) => {
				setIsLoading(false);
				setPackages(success.data);
			},
			(fail) => {
				setIsLoading(false);
				if (fail?.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
		getTokenConversion(
			(success) => {
				setOneToken(success.data.cost);
			},
			(fail) => {
				if (fail.data?.error) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}, [lang]);

	useEffect(() => {
		setTokenPrice(tokens * oneToken);

		if (selectedPackage === "custom") {
			updateSelectedPackagePrice(tokens * oneToken);
		}
	}, [selectedPackage, tokens, oneToken]);

	function incDecTokens(add = false) {
		if (add) {
			setTokens((prev) => prev + 1);
		} else {
			if (tokens !== 1) {
				setTokens((prev) => prev - 1);
			}
		}
	}

	const handlePayment = () => {
		updateIsBtnLoading(true);
		var data = {};
		if (selectedPackage === "custom") {
			data.numberOfTokens = tokens;
		} else {
			data.packageId = selectedPackage;
		}
		payToken(
			data,
			(success) => {
				// onCancel();
				// window.location.href = success.data;
        setPaymentURl(success.data);
				// updateIsBtnLoading(false);
			},
			(fail) => {}
		);
	};
	return (
		<Modal
			className="buy-modal with__spinner"
			centered
			visible={visible}
			footer={null}
			onCancel={onCancel}
			bodyStyle={{
				minHeight: 400,
			}}>
			{isLoading ? (
				<div className="spinner__container">
					<Spin className="spinner" />
				</div>
			) : paymentURl? (<>
        <iframe width={"100%"} height={"500"} title="payment" src={paymentURl}></iframe>
      </>)
      : (
				<>
					<header className="buy-modal__header">
						<figure>
							<img src={logoImg} alt="logo" />
						</figure>
						<p>{t("selectPackage")}</p>
					</header>

					<Row gutter={[24, 24]} className="buy-modal__packages">
						<Col xs={24} sm={12} md={8}>
							<div className="package__coins">
								<div className="coins d-flex align-items-baseline">
									<div className="coins-number">{tokens}</div>
									<figure className="coins-figure d-flex">
										<img src={coinsIcon} alt="coins-icon" />
										<div>
											<div
												style={{
													fontSize: "2rem",
													fontWeight: "bold",
													marginInlineStart: "1rem",
													lineHeight: 0.5,
													padding: "0.25rem",
												}}
												role="button"
												onClick={() => incDecTokens(true)}>
												+
											</div>
											<div
												style={{
													fontSize: "2rem",
													fontWeight: "bold",
													marginInlineStart: "1rem",
													lineHeight: 0.5,
													padding: "0.25rem",
												}}
												role="button"
												onClick={() => incDecTokens()}>
												-
											</div>
										</div>
									</figure>
								</div>
								<div className="money">
									<span>{tokenPrice}</span> <span>{t("pound")}</span>
								</div>
								<Radio
									onClick={() => {
										updateSelectedPackage("custom");
									}}
									checked={selectedPackage === "custom"}></Radio>
							</div>
						</Col>
						{packages?.map(({ _id, tokens, price }) => (
							<Col xs={24} sm={12} md={8} key={_id}>
								<div className="package__coins">
									<div className="coins d-flex align-items-baseline">
										<div className="coins-number">{tokens}</div>
										<figure className="coins-figure">
											<img src={coinsIcon} alt="coins-icon" />
										</figure>
									</div>
									<div className="money">
										<span>{price}</span> <span>{t("pound")}</span>
									</div>
									<Radio
										onClick={() => {
											updateSelectedPackage(_id);
											updateSelectedPackagePrice(price);
										}}
										checked={selectedPackage === _id}></Radio>
								</div>
							</Col>
						))}
					</Row>
					<div className="buy-modal__payment-method">
						<p
							className="buy-modal__payment-method-p"
							style={{
								marginTop: "2rem",
							}}>
							{t("selectPaymentMethod")}
						</p>
						<div>
							<Radio.Group
								onChange={(e) => {
									updatePaymentMethod(e.target.value);
								}}
								value={paymentMethod}>
								<Row gutter={32} className="align-items-center">
									<Col>
										<Radio value={1}>{t("cridet_depateCard")}</Radio>
									</Col>
								</Row>
							</Radio.Group>
						</div>
					</div>
					<div className="d-flex justify-content-center align-items-center py-2 buttons d-flex align-items-center">
						<Button
							className="btn btn-main h-100"
							onClick={handlePayment}
							disabled={!selectedPackagePrice}
							loading={isBtnLoading}>
							{t("proceedWithPayment")}
							{selectedPackage && (
								<div>
									{selectedPackagePrice} {t("pound")}
								</div>
							)}
						</Button>
					</div>
				</>
			)}
		</Modal>
	);
};

export default BuyTokenModal;
