import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { I18nextProvider } from "react-i18next";
import i18n from "./assets/localization/i18n";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
// component
import App from "./App";
// styling
import "antd/dist/antd.variable.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../src/assets/localization/i18n";
import "./index.css";

const persistor = persistStore(store);
const queryClient = new QueryClient();

ReactDOM.render(
	<I18nextProvider i18n={i18n}>
		<BrowserRouter>
			<QueryClientProvider client={queryClient}>
				<Provider store={store}>
					<PersistGate loading={null} persistor={persistor}>
						<App />
					</PersistGate>
				</Provider>
			</QueryClientProvider>
		</BrowserRouter>
	</I18nextProvider>,
	document.getElementById("root")
);
