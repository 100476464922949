import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import {
	getProRatesAndPortoflio,
	updateProjectStatus,
} from "../../../../../../network";
import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { toastError, toastSuccess } from "helpers/toasterFun";
import CheckboxGroup from "react-checkbox-group";
import SharedRating from "./../../../../../../../Shared/SharedRating/SharedRating";
import ClientShowProProjects from "../ShowProProjects/ClientShowProProjects";
import "./ShortListing.scss";

const ShortListing = ({
	projects,
	setProjects,
	handleAddProject,
	ShortListingArr,
	activeProject,
	inProfileAndQuotations,
	setActiveProjectRoomProjectTab,
	setRerenderList,
	makeReshortlistingChange,
}) => {
	const [selectedCandidate, updateSelectedCandidate] = useState(null);
	const [selectedCandidatesList, updateSelectedCandidatesList] = useState([]);
	const [allCandidates, updateAllCandidates] = useState([]);
	const [remainingTime, updateRemainingTime] = useState(null);
	const [rateQuestions, updateRateQuestions] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const { t } = useTranslation();
	const { authorization } = useSelector((state) => state.authorization);
	const userRole = authorization.role;

	useEffect(() => {
		if (ShortListingArr && authorization.role.includes("Pro")) {
			updateSelectedCandidate(ShortListingArr[0]?.candidates[0]?.candidateId);
		}
		if (ShortListingArr && authorization.role.includes("Property")) {
			updateSelectedCandidate(null);
			updateRemainingTime(ShortListingArr[0]?.remainingTime);
			updateRateQuestions([]);
			updateSelectedCandidatesList([]);
			updateAllCandidates(ShortListingArr[0]?.candidates);
		}
	}, [ShortListingArr, authorization.role]);

	useEffect(() => {
		if (activeProject && selectedCandidate) {
			let data = {
				proId: selectedCandidate,
				projectId: activeProject,
			};

			getProRatesAndPortoflio(
				data,
				(success) => {
					if (success?.data?.length) {
						updateRateQuestions(success.data[1]?.rates);
						// updateAllProjects(success.data[2]?.proPortfolio);
						updateAllCandidates(success.data[3].candidates);
					}
				},
				(fail) => {
					if (fail.data) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		}
	}, [selectedCandidate, activeProject, authorization]);

	const handleSubmit = () => {
		let candidatesList = [];
		selectedCandidatesList.forEach((candidate) => {
			candidatesList.push({
				proId: candidate,
				status: 3,
			});
		});
		updateProjectStatus(
			activeProject,
			candidatesList,
			(success) => {
				if (success.isSuccess) {
					setRerenderList((prev) => !prev);
					toastSuccess(success.message);
					updateRemainingTime(null);
					updateRateQuestions([]);
					// updateAllProjects([]);
					updateAllCandidates([]);
					setActiveProjectRoomProjectTab("profilesAndQuotations");
					makeReshortlistingChange((prev) => !prev);
				}
			},
			(fail) => {
				if (fail.data) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	};

	if (inProfileAndQuotations)
		return (
			<div
				style={{
					minHeight: 400,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}>
				<div className="text-empty">
					<div className="text-center">{t("yourDoneWithShortlisting")}</div>
					<div className="text-center">{t("yourChosenCandiatesAreNow")}</div>
				</div>
			</div>
		);
	return (
		<>
			{!isLoading && allCandidates?.length ? (
				<div className="shortlisting">
					<Form>
						<div className="portofolio">
							{userRole.includes("Property") && (
								<>
									<div className="d-flex cadidates">
										<CheckboxGroup
											value={selectedCandidatesList}
											onChange={(checked) =>
												updateSelectedCandidatesList(checked)
											}>
											{(CheckboxItem) => (
												<Row gutter={24}>
													{allCandidates?.map((candidate, index) => (
														<Col key={index}>
															<div className="custom-checkbox">
																<label>
																	<CheckboxItem
																		className="mx-1"
																		value={candidate.candidateId}
																	/>
																	<label
																		id={candidate.candidateId}
																		className={`ms-2 ${
																			candidate?.candidateId ===
																			selectedCandidate
																				? "candidate-checked"
																				: ""
																		}`}
																		style={{ cursor: "pointer" }}
																		onClick={(e) => {
																			updateSelectedCandidate(e.target.id);
																		}}>
																		{t("candidate")} {index + 1}
																	</label>
																</label>
															</div>
														</Col>
													))}
												</Row>
											)}
										</CheckboxGroup>
									</div>
									<div className="rating">
										<ul className="d-flex justify-content-between flex-wrap rating-list">
											{rateQuestions?.map((item, _) => {
												return (
													<li className="my-3" key={_}>
														<h6>{item.rateQuestion}</h6>
														<div className="stars">
															<SharedRating readOnly value={item.average} />
														</div>
													</li>
												);
											})}
										</ul>
									</div>
								</>
							)}

							{/* start show to client pro projects */}
							{selectedCandidate && (
								<ClientShowProProjects selectedProId={selectedCandidate} />
							)}
							{/*end show to client pro projects */}
							{/* Start for Shorlist message */}
							<div
								className="submit d-flex flex-column align-items-center justify-content-center"
								style={{
									height: selectedCandidate ? null : "320px",
								}}>
								{remainingTime ? (
									<span className="fs-1 mt-4 mb-2">
										<span className="number mx-1 edit">{remainingTime}</span>
										<i className="fa-regular fa-calendar"></i>
									</span>
								) : null}

								{inProfileAndQuotations ? (
									<span className="desc mt-4">
										{t("shortlistedSuccessfully")}
									</span>
								) : (
									<>
										{allCandidates?.length > 0 && (
											<>
												<span className="desc mt-4">
													{t("youNeedToShortlisted3Candidates")}
												</span>
												<div className="main-btn">
													<Button
														type="submit"
														disabled={selectedCandidatesList.length !== 3}
														onClick={handleSubmit}
														className=" btn_submit my-4"
														style={{
															width: "150px",
															height: "40px",
														}}>
														{t("submit")}
													</Button>
												</div>
											</>
										)}
									</>
								)}
							</div>
							{/* End for Shorlist message */}
						</div>
					</Form>
				</div>
			) : null}

			{isLoading ? (
				<></>
			) : !isLoading && !allCandidates?.length ? (
				<div
					style={{
						minHeight: 400,
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}>
					<div className="text-empty ">{t("thereAreNoBiddersYet")}</div>
				</div>
			) : null}
		</>
	);
};

export default ShortListing;
