import SignIn from "modules/Authentication/Signin/Signin";
import Signup from "modules/Authentication/Signup/Signup";
import { Modal } from "antd";
import { useState } from "react";
export default function AuthModal({ guestProject, setOpenModal }) {
  const [currentView, setCurrentView] = useState("signin");
  return (
    <Modal
      className="guest__project__auth__modal"
      width={currentView !== "signin" ? "720px" : "auto"}
      style={{width: currentView !== "signin" ? "720px" : "500px", minWidth: "320px"}}
      centered
      closable={false}
      visible={true}
      footer={null}
      bodyStyle={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {currentView === "signin" ?
        <SignIn setCurrentView={setCurrentView} guestProject={guestProject} setOpenModal={setOpenModal}/>
        :
        <Signup setCurrentView={setCurrentView} guestProject={guestProject} setOpenModal={setOpenModal}/>
      }
    </Modal>
  )
}