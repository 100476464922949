import { getResource, postResource } from "services";

export function getTokens(lang, onSuccess, onFail) {
	const path = `api/tokenPackages/getTokenPackages?lang=${lang}`;
	getResource(path, onSuccess, onFail);
}

export function getTokenConversion(onSuccess, onFail) {
	const path = `api/tokenConversion/getTokenConversion/user`;
	getResource(path, onSuccess, onFail);
}

export function payToken(data, onSuccess, onFail) {
	const path = `api/payment/pay`;
	postResource(path, data, onSuccess, onFail);
}
