import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { find } from "lodash";
import { changeAuthServiceType } from "store/authorization";
import { useDispatch } from "react-redux";
import { Radio, Input, Row, Col, Button, Spin } from "antd";
import CheckboxGroup from "react-checkbox-group";
import { toastError, toastSuccess } from "helpers/toasterFun";
import {
	getAllServices,
	getAllServiceTypes,
	getAllServicesQuestion,
	createProServiceTypePreferances,
	updateProServiceTypePreferances,
} from "modules/Profiles/network";
import "./EditServiceTypePreferances.scss";

const EditServiceTypePreferances = ({
	serviceTypePreferances,
	setHasServiceType,
	setServiceName,
}) => {
	const [services, setServices] = useState(null);
	// const [disclaimer, setDisclaimer] = useState(null);
	const [selectedServiceID, setselectedServiceID] = useState(null);
	const [serviceTypes, setServiceTypes] = useState(null);
	const [selectedServiceTypeId, setSelectedServiceTypeId] = useState(null);
	const [serviceTypeItems, setServiceTypeItems] = useState(null);
	const [selectedServiceTypeItems, setSelectedServiceTypeItems] = useState([]);
	const [servicesQuestions, setServicesQuestions] = useState(null);
	const [selectedServiceQuestionId, setSelectedServiceQuestionId] = useState(
		[]
	);
	useState([]);
	const [serviceTypeItemInput, setServiceTypeItemInput] = useState("");
	const [otherInputs, setOtherInputs] = useState({});
	const [dataToEdit, setDataToEdit] = useState(serviceTypePreferances);
	const [isDataLoading, setIsDataLoading] = useState(false);
	const [requiredQ, setRequiredQ] = useState([]);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const {
		lang: { lang },
	} = useSelector((state) => state);

	useEffect(() => {
		setIsDataLoading(true);
	}, []);

	// useEffect(() => {
	// 	if (services && selectedServiceID) {
	// 		setDisclaimer(
	// 			(prev) =>
	// 				services.filter((item) => item._id === selectedServiceID)[0]
	// 					.disclaimer
	// 		);
	// 	}
	// }, [selectedServiceID, services]);

	// get All Service
	useEffect(() => {
		getAllServices(
			lang,
			(success) => {
				setServices(success.data);
				if (!selectedServiceID) {
					setselectedServiceID(dataToEdit?.serviceID || success.data[0]._id);
				}
			},
			(fail) => {
				if (fail.data) {
					toastError(fail.data.error);
				} else {
					toastError();
				}
			}
		);
	}, [dataToEdit, lang, selectedServiceID]);

	useEffect(() => {
		if (!selectedServiceTypeId) {
			getAllServiceTypes(
				lang,
				(success) => {
					setServiceTypes(success.data);
					if (dataToEdit?.serviceTypeID && selectedServiceTypeId === null) {
						setSelectedServiceTypeId(dataToEdit.serviceTypeID);
						setServiceTypeItems(
							success?.data.filter(
								(item) => item.serviceTypeId === dataToEdit.serviceTypeID
							)[0].serviceTypeItems
						);
						setSelectedServiceTypeItems(
							dataToEdit.serviceTypeOther
								? [...dataToEdit.serviceTypeItemsID, "other"]
								: dataToEdit.serviceTypeItemsID
						);
						setServiceTypeItemInput(dataToEdit.serviceTypeOther);
					} else {
						setSelectedServiceTypeId(success?.data[0].serviceTypeId);
						setServiceTypeItems(() => {
							return [...success?.data[0]?.serviceTypeItems];
						});
					}
				},
				(fail) => {
					if (fail.data) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		}
	}, [dataToEdit, lang, selectedServiceTypeId]);

	// getAllQuestions
	useEffect(() => {
		if (selectedServiceID) {
			setServicesQuestions([]);
			getAllServicesQuestion(
				selectedServiceID,
				lang,
				(success) => {
					setIsDataLoading(false);
					setServicesQuestions(success.data);
					let serviceQA = [];
					let questions = JSON.parse(JSON.stringify(success.data))
						.filter((item) => {
							if (item.isRequired) return item;
							return null;
						})
						.map((item) => item.serviceQuestionId);
					setRequiredQ(questions);

					if (dataToEdit?.serviceQA) {
						success.data.forEach((question) => {
							let addedQuestion = dataToEdit.serviceQA.find(
								(item) => item.questionId === question.serviceQuestionId
							);
							if (addedQuestion) {
								serviceQA.push({
									questionId: addedQuestion.questionId,
									answerId: addedQuestion.theOtherAnswer
										? [...addedQuestion.answerId, "other"]
										: addedQuestion.answerId,
								});
								setOtherInputs((prev) => {
									const serviceQuestionId = addedQuestion.questionId;
									let otherInputId = {
										[serviceQuestionId]: addedQuestion.theOtherAnswer,
									};
									return { ...prev, ...otherInputId };
								});
							} else {
								serviceQA.push({
									questionId: question.serviceQuestionId,
									answerId: [],
								});

								setOtherInputs((prevState) => {
									const serviceQuestionId = question.serviceQuestionId;
									let otherInputId = {
										[serviceQuestionId]: null,
									};
									return { ...prevState, ...otherInputId };
								});
							}
							// else{

							// }
							// serviceQA.push({
							//                 questionId: item.questionId,
							//                 answerId: item.theOtherAnswer
							//                   ? [...item.answerId, "other"]
							//                   : item.answerId,
							//               });
						});

						// dataToEdit.serviceQA.forEach((item) => {
						//   serviceQA.push({
						//     questionId: item.questionId,
						//     answerId: item.theOtherAnswer
						//       ? [...item.answerId, "other"]
						//       : item.answerId,
						//   });
						//   setOtherInputs((prevState) => {
						//     const serviceQuestionId = item.questionId;
						//     let otherInputId = {
						//       [serviceQuestionId]: item.theOtherAnswer,
						//     };
						//     return { ...prevState, ...otherInputId };
						//   });
						// });
						setSelectedServiceQuestionId(serviceQA);
					} else {
						success.data.forEach((item) => {
							serviceQA.push({
								questionId: item.serviceQuestionId,
								answerId: [],
							});

							setOtherInputs((prevState) => {
								const serviceQuestionId = item.serviceQuestionId;
								let otherInputId = {
									[serviceQuestionId]: null,
								};
								return { ...prevState, ...otherInputId };
							});
						});
						setSelectedServiceQuestionId(serviceQA);
					}
				},
				(fail) => {
					setIsDataLoading(false);
					if (fail.data) {
						toastError(fail.data.error);
					} else {
						toastError();
					}
				}
			);
		}
	}, [selectedServiceID, dataToEdit, lang]);

	function changeServiceType(e) {
		const selected = e.target.value;
		setSelectedServiceTypeId(e.target.value);
		setSelectedServiceTypeItems([]);
		let newServiceTypeItems = serviceTypes.filter(
			(item) => item.serviceTypeId === selected
		)[0].serviceTypeItems;
		setServiceTypeItems([...newServiceTypeItems]);
		setServiceTypeItemInput(null);
	}

	function changeSelectedServiceId(e) {
		setselectedServiceID(e.target.value);
		// for the previous service answers make a copy
		// reset selected services answers
		setSelectedServiceQuestionId((prev) => {
			const selectedServiceQuestionId = prev.map((item) => {
				item.answerId = [];
				return item;
			});
			return [...selectedServiceQuestionId];
		});
		if (dataToEdit?.serviceQA) {
			setDataToEdit((prev) => {
				delete prev.serviceQA;
				return { ...prev };
			});
		}
	}

	function changeServiceQuestionAnswer(checkedQuestions, questionId) {
		setSelectedServiceQuestionId((prev) => {
			prev.filter((item) => {
				if (item.questionId === questionId) {
					item.answerId = checkedQuestions;
				}
				return item;
			});
			return [...prev];
		});
	}

	function changeServiceQuestionInput(e, questionId) {
		const { value } = e.target;
		setOtherInputs((prevState) => {
			return { ...prevState, [questionId]: value };
		});
	}

	function submit() {
		let payload = {
			serviceID: selectedServiceID,
			serviceTypeID: selectedServiceTypeId,
		};

		let submitQA = [];
		let selectedQuestion = JSON.parse(
			JSON.stringify(selectedServiceQuestionId)
		);
		selectedQuestion.forEach((question) => {
			if (question.answerId?.findIndex((ele) => ele === "other") > -1) {
				question.theOtherAnswer = otherInputs[question.questionId];
				question.answerId = question.answerId?.filter((ele) => ele !== "other");
			} else {
				delete question.theOtherAnswer;
			}

			if (!question.answerId?.length) {
				delete question.answerId;
			}
			submitQA.push(question);
		});

		if (
			submitQA.filter(
				(item) => item.answerId?.length > 0 || item.theOtherAnswer
			).length
		) {
			payload.serviceQA = submitQA;
		}

		if (selectedServiceTypeItems.filter((item) => item === "other").length) {
			payload.serviceTypeOther = serviceTypeItemInput;
		}

		if (selectedServiceTypeItems.length) {
			payload.serviceTypeItemsID = selectedServiceTypeItems.filter(
				(item) => item !== "other"
			);
		}

		// required fields must be answered
		// first if all required service question is Answered with [answerId.length || theOtherAnswer]
		let totalRequiredSolved = 0;
		requiredQ.forEach((requriedQuestion) => {
			let data = payload?.serviceQA?.find(
				(item) => item.questionId === requriedQuestion
			);
			if (data?.answerId?.length || data?.theOtherAnswer) {
				totalRequiredSolved += 1;
			}
		});

		let serviceQA_validation = requiredQ.length === totalRequiredSolved;
		let serviceType_validation =
			payload?.serviceTypeItemsID?.length || payload?.serviceTypeOther;

		if (serviceQA_validation && serviceType_validation) {
			setIsSubmitLoading(true);
			if (serviceTypePreferances) {
				updateProServiceTypePreferances(
					dataToEdit._id,
					payload,
					(success) => {
						let service = services.filter(
							(service) => service._id === dataToEdit.serviceID
						);
						dispatch(changeAuthServiceType([service[0].name]));
						setHasServiceType((prev) => !prev);
						toastSuccess(success.message);
						setServiceName(find(services, { _id: selectedServiceID }).name);
						setIsSubmitLoading(false);
					},
					(fail) => {
						if (fail.data) {
							toastError(fail.data.error);
						} else {
							toastError();
						}
					}
				);
			} else {
				createProServiceTypePreferances(
					payload,
					(success) => {
						setIsSubmitLoading(false);
						setServiceName(find(services, { _id: selectedServiceID }).name);
						dispatch(
							changeAuthServiceType([
								find(services, { _id: selectedServiceID }).name,
							])
						);
						toastSuccess(success.message);
						setHasServiceType((prev) => !prev);
					},
					(fail) => {
						if (fail.data) {
							toastError(fail.data.error);
						} else {
							toastError();
						}
					}
				);
			}
		} else {
			toastError(t("pleaseFillAllRequiredFields"));
		}
	}

	return (
		<section className="serviceType-page main-section with__spinner">
			{isDataLoading ? (
				<div className="spinner__container">
					<Spin className="spinner" />
				</div>
			) : (
				<>
					<div className="serviceType__specialization">
						{services && selectedServiceID && (
							<Radio.Group
								// defaultValue={selectedServiceID}
								value={selectedServiceID}
								onChange={(e) => {
									changeSelectedServiceId(e);
								}}
							>
								{services.map((service) => (
									<Radio className="item" value={service._id} key={service._id}>
										{service.name}
									</Radio>
								))}
							</Radio.Group>
						)}
					</div>

					<div className="serviceType__type">
						{serviceTypes && serviceTypes.length ? (
							<Radio.Group
								name="radiogroup"
								value={selectedServiceTypeId}
								onChange={changeServiceType}
							>
								{serviceTypes.map((item) => (
									<Radio value={item.serviceTypeId} key={item.serviceTypeId}>
										{item.serviceType}
									</Radio>
								))}
							</Radio.Group>
						) : null}
					</div>

					{/* Start Service Type Group */}
					<div className="serviceType-group">
						<div className="serviceType-group__checkbox-group">
							{serviceTypeItems?.length ? (
								<CheckboxGroup
									className="mb-3 serviceType-group__checkbox-type-group"
									value={selectedServiceTypeItems}
									onChange={setSelectedServiceTypeItems}
								>
									{(Checkbox) => (
										<Row gutter={[32, 8]}>
											{serviceTypeItems.map((item, i) => {
												return (
													<Col xs={24} md={8} key={i}>
														<div className="custom-checkbox">
															<label htmlFor={item.serviceTypeItemId}>
																<Checkbox
																	value={item.serviceTypeItemId}
																	id={item.serviceTypeItemId}
																/>
																{item.serviceTypeItem}
															</label>
														</div>
													</Col>
												);
											})}
											<Col>
												<div className="d-flex align-items-center checkbox-group serviceType-group__other">
													<label className="d-flex align-items-center custom-checkbox">
														<Checkbox className="item other" value={"other"} />
														<span className="other-span me-2">
															{t("other")}:
														</span>
													</label>
													<Input
														className="other-input"
														placeholder={t("pleaseSpecify")}
														name="serviceTypeItems"
														value={serviceTypeItemInput}
														onChange={(e) =>
															setServiceTypeItemInput(e.target.value)
														}
													/>
												</div>
											</Col>
										</Row>
									)}
								</CheckboxGroup>
							) : null}
						</div>
					</div>
					{/* End Service Type Group */}

					{/* Start Service Type QA */}
					{servicesQuestions?.length ? (
						<>
							{servicesQuestions.map((servicesQuestion, i) => (
								<div className="serviceType-group" key={i}>
									<header className="serviceType-group__header">
										<h3>
											{servicesQuestion.serviceQuestion}
											{servicesQuestion.isRequired && (
												<span
													style={{
														color: "red",
														marginBottom: "1.5rem",
													}}
												>
													*
												</span>
											)}
										</h3>
									</header>
									<div className="serviceType-group__checkbox-group">
										<CheckboxGroup
											className="mb-3"
											value={
												selectedServiceQuestionId?.filter(
													(item) =>
														item.questionId ===
														servicesQuestion.serviceQuestionId
												)[0]?.answerId
											}
											onChange={(answerIds) =>
												changeServiceQuestionAnswer(
													answerIds,
													servicesQuestion.serviceQuestionId
												)
											}
										>
											{(Checkbox) => (
												<Row gutter={[36, 12]}>
													{servicesQuestion?.servicesAnswers?.length
														? servicesQuestion?.servicesAnswers.map(
																(question, i) => (
																	<Col key={i} className={"custom-checkbox"}>
																		<label htmlFor={question?.answerId}>
																			<Checkbox
																				className="item"
																				value={question?.answerId}
																				id={question?.answerId}
																			/>
																			{question.answer}
																		</label>
																	</Col>
																)
														  )
														: null}
													{servicesQuestion.hasOtherAnswer ? (
														<>
															<Col md={24}>
																<div className="custom-checkbox-wrap-other w-50">
																	<label className="custom-checkbox">
																		<Checkbox
																			className="item other"
																			value={"other"}
																		/>
																		<span className="other-span me-2">
																			{t("other")}:
																		</span>
																	</label>
																	<Input
																		className="other-input"
																		placeholder={t("pleaseSpecify")}
																		name={servicesQuestion.serviceQuestionId}
																		value={
																			otherInputs &&
																			otherInputs[
																				servicesQuestion?.serviceQuestionId
																			]
																		}
																		onChange={(e) =>
																			changeServiceQuestionInput(
																				e,
																				servicesQuestion.serviceQuestionId
																			)
																		}
																	/>
																</div>
															</Col>
														</>
													) : null}
												</Row>
											)}
										</CheckboxGroup>
									</div>
								</div>
							))}
						</>
					) : null}

					{/* End Service Type QA */}
					<div className="primary-bid-btn">
						<Button
							type="primary"
							loading={isSubmitLoading}
							className="btn"
							onClick={submit}
						>
							{t("submit")}
						</Button>
					</div>
				</>
			)}
		</section>
	);
};

export default EditServiceTypePreferances;
